import * as React from "react";
import { Paper, Button, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PathDisplay from '../DetailPanel/PathDisplay';
import DetailTitle from '../DetailPanel/DetailTitle';
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store";
//import { deleteStructureData, saveStructureData, editStructureData, addStructureData, editStructureRestrictionData } from "../store/hierarchy/actions";
import { Select, MenuItem, TextField, Grid, FormControl, Chip } from '@mui/material';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@mui/material';
import EditButton from "./EditButton";
import ViewButton from "./ViewButton";
import { EditPolymerDialog } from "./EditPolymerDialog";
//import ConfirmBoxDialog from "./ConfirmBoxDialog";
import { useCallback } from 'react';
import { showEditPolymer } from "../store/polymer/actions";
import { editVariantDialog, viewVariantDialog, addStructureRestrictionsData, editStructureRestrictionsData } from '../store/variant/actions';
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { getVariantRestrictions } from '../store/variant/actions';
import LockIcon from "@mui/icons-material/Lock";
import { customSpacing } from "../theme/SIRtheme";


interface Props {
  MRVchanged?: (mrv: string) => void;
  mrv?: string | null;
  smilesfromsearch?: string;
  searchIndex?: string;
  getImage?: any | null;

}
const useStyles = makeStyles((theme : any) => ({
  cell: {
    fontSize: '12px !important',
    display: "flex !important",
    alignContent: "center !important",
    minWidth: '80px !important'
  },
  formControl: {
    margin: customSpacing(1, false) + ' !important',
    minWidth: '120px !important',
  },
  selectEmpty: {
    marginTop: "12px !important",
    width: '250px !important',
    padding: "0px !important"
  },
  selectEmpty1: {
    marginTop: "2px !important",
    width: '175px !important',
  },
  mainDiv: {
    flexDirection: "column",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    display: "flex !important",
    borderStyle: "solid !important",
    // height: '150px !important',
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    overflow:"auto !important"
  },
  mainDiv2: {
    //flexDirection: "column",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    display: "flex !important",
    borderStyle: "solid !important",
    //height: 150,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  pad: {
    paddingTop: "25px !important",
    paddingLeft: "15px !important",
    //paddingRight: "20px",
    //paddingBottom: "15px",
    fontSize: '16px !important',
    fontWeight: 500 + '!important',
    color: 'gray !important'
  },
  padForMol: {
    paddingTop: "15px !important",
    paddingLeft: "15px !important",
    paddingRight: "20px !important",
    paddingBottom: "15px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  padTop: {
    paddingTop: "10px !important"
  },
  padEmail: {
    paddingLeft: "15px !important",
    //paddingTop: "25px",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  flex: {
    display: "flex !important"
  },
  textStrike: {
    // marginTop: "10px",
    width: '600px !important',
    paddingLeft: "15px !important"
  },
  para: {
    margin: '0px !important',
    color: 'red !important',
    paddingLeft: "15px !important"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '2px !important',
    height: '26px !important'
  },
  marLeft: {
    marginBottom: "10px !important",
  },
  paper: {
    padding: "5px !important",
    height: "100% !important"
  },
  textarea: {
    width: "81% !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    borderStyle: "solid !important",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  error: {
    color: "red !important",
    marginTop: "0px !important",
    marginBottom: "0px !important",
  },
  parent: {
    display: "flex !important",
  },
  child1: {
    position: "relative",
    top: "10px !important",
    bottom: "10px !important" 
  },
  saveBtn:{
    marginBottom: "7px !important",
    marginTop: "9px !important",
    marginLeft: "15px !important"
  },
  audit: {
    paddingBottom: "5px !important",
  },
  parentheight: {
    height: "1040px !important",
    overflow: "auto",
    overflowX: "hidden"
  },
  hideSection: {
    display: "none !important",
  },
  showSection: {
    display: "block !important",
  },
  iframe: {
    minHeight: '454px !important'

  },
  container: {
    padding: '8px !important',
    textAlign: 'left' as const,
    color:"gray !important"
  },
  matTab: {
    overflow: "auto !important"
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
  lockIconColumn: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: customSpacing(2, false) + ' !important',
  },
  tooltipText: {
    fontSize: '14px !important',
    padding: '5px !important',
    margin: '5px !important',
    backgroundColor: "white !important",
    border: "1px solid black !important",
    color: "black !important",
  },
  domainLock: {
    fontSize: '24 !important',
    color: "red !important",
    fontWeight: "bold !important",
    marginTop: '5px !important',
    marginRight: "5% !important",
  },

  smallLock: {
    fontSize: '18 !important',
    color: "red !important",
    fontWeight: "bold !important",
    marginTop: '4 !important',
    justifyContent: "center !important",
    alignItems: "center !important",
    width: '16px !important',
    height: '16px !important',
  }
}));
interface MarvinInstance {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: (() => void) | null) => void;
  exportStructure: (format: string, settings?: any) => Promise<string>;
  importStructure: (format: string, data: any) => void;
}
export default function PolymerStructureComponent(props: Props) {


  const classes = useStyles();
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const node = detailData.selectedNode;

  // console.log('node ==', node, detailData);

  const itemDetail = detailData.itemDetail;
  const structureDataList = useSelector((state: AppState) => state.hierarchy.getStructureList);
  const parentPolymerDetails = useSelector((state: AppState) => state.polymersData.ParentPolymerDetails);
  const variantDetails = useSelector((state: AppState) => (state.polymersData.ParentPolymerDetails != null ? state.polymersData.ParentPolymerDetails.variantDetails : null));

  const isRestricted = (parentPolymerDetails ? parentPolymerDetails.isRestricted : true);
  const variantFlag = useSelector((state: AppState) => state.variant.variantFlag);
  const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);

  const variantscreen = useSelector((state: AppState) => state.variant.variantStrcutureInfo);

  const isVariantRestricted = (variantscreen && variantscreen.isVariantRestricted != null ? variantscreen.isVariantRestricted : false);

  const isLockIconRestrictionParentPolymer = (parentPolymerDetails && parentPolymerDetails.isLockIconRestriction) ? parentPolymerDetails.isLockIconRestriction : null;
  // const variantArray = (variantDetails && variantscreen)? variantDetails.filter(obj => obj.variantId== variantscreen.variantId) : null;
  const isLockIconVariantRestrictedVariantPage = variantscreen ? variantscreen.isLockIconVariant : false;
  // console.log('parentPolymerDetails == test', parentPolymerDetails)
  //console.log('variantDetails ==', variantDetails)
  //console.log('variantscreen ==', variantscreen);

  const getRestrictionData = useSelector((state: AppState) => state.editItem.structurerestrictionData);
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  const user = useSelector((state: AppState) => state.user);
  const [disableHistory, setDisableHistory] = React.useState(true);

  const [Domains, setDomains] = React.useState(getRestrictionData && getRestrictionData.data.groupName.length > 0 ? getRestrictionData.data.groupName : []);
  const [DisableRestrictPanel, setDisableRestrictPanel] = React.useState(false);

  const [ifEmailEntered, setIfEmailEntered] = React.useState(false);
  const [ifDropDownSelected, setIfDropDownSelected] = React.useState(false);
  const [restrictedToError, setRestrictedToError] = React.useState(false);

  var [emailEdit, setEmailEdit] = React.useState((getRestrictionData && getRestrictionData.data.email) ? getRestrictionData.data.email.join(';') : null);
  const [molFormula, setMolFormula] = React.useState(structureDataList ? structureDataList.data.molecularFormula : '');
  const [verificationStatus, setVerificationStatus] = React.useState(structureDataList && structureDataList.data.verification !== null ? structureDataList.data.verification.toUpperCase() : '');
  const [auditDescription, setAuditDescription] = React.useState("");

  const dispatch = useDispatch();
  const { editPolymer } = useSelector((state: AppState) => state.polymersData);
  const open = (editPolymer != null);
  const { confirm } = useSelector((state: AppState) => state.polymersData);
  const confirmBoxOpen = (confirm != null);

  const onEditPolymerItem = useCallback(() => {
    dispatch(showEditPolymer({ action: 'Edit' }));
  }, [dispatch, { action: 'Edit' }]);

  /*const onEditItem = useCallback(() => {
    dispatch(showEditVariantItem({ action: 'Edit' }));
  }, [dispatch, { action: 'Edit' }]);*/

  const onViewParentItem = useCallback(() => {
    dispatch(showEditPolymer({ action: 'View' }));
  }, [dispatch, { action: 'View' }]);


  const onEditVariant = useCallback((variantName: string) => {
    console.log('Edit variant here =======');
    if (node != null) {
      dispatch(editVariantDialog({ parentEntity: { name: node.name, type: node.type, subType: node.subType, status: node.status }, targetType: "Variant", variantName: variantName }));
      dispatch<any>(getVariantRestrictions(variantName));
    }
    //dispatch(editVariantDialog({ variantName }));

  }, [dispatch, variantDetails]);

  const onViewVariant = useCallback((variantName: string) => {
    console.log('View variant here =======');
    //dispatch(viewVariantDialog({ variantName }));
    if (node != null) {
      dispatch(viewVariantDialog({ parentEntity: { name: node.name, type: node.type, subType: node.subType, status: node.status }, targetType: "Variant", variantName: variantName }));
      dispatch<any>(getVariantRestrictions(variantName));
    }

  }, [dispatch, variantDetails]);

  // onSaveRestricted
  const onAddRestrictions = () => {

    var arrEmailEdit = emailEdit.split(";");
    var jsonEmailEdit = JSON.parse(JSON.stringify(arrEmailEdit));

    var obj: any = {
      structureName: itemDetail.backboneIdentity,
      domains: Domains,
      mailIds: jsonEmailEdit,
    }

    console.log('Saving Structure Restrictions =======' + JSON.stringify(obj));
    dispatch(addStructureRestrictionsData(obj));
  };

  // onEditRestricted
  const onEditRestrictions = () => {

    var arrEmailEdit = emailEdit.split(";");
    var jsonEmailEdit = JSON.parse(JSON.stringify(arrEmailEdit));

    var obj: any = {
      structureName: itemDetail.backboneIdentity,
      domains: Domains,
      mailIds: jsonEmailEdit,
    }

    console.log('Editing Structure Restrictions =======' + JSON.stringify(obj));
    dispatch(editStructureRestrictionsData(obj));
  };


  const handleChange = (event: any) => {
    setDomains(event.target.value);
    //setDomains('Regulatory');
    if (event.target.value.length > 0) {
      setIfDropDownSelected(true);
    } else {
      setIfDropDownSelected(false);
    }
    localStorage.setItem('structurerestricteddomains', JSON.stringify(event.target.value));

  };

  const handleRestrictedToChange = (event: any) => {
    if (event.target.value === '') {
      setIfEmailEntered(false);
      setRestrictedToError(false);
    } else {
      setIfEmailEntered(true);
      setRestrictedToError(true);
    }
    var emails = event.target.value.split(";");
    let invalidEmails = [];
    for (let i = 0; i < emails.length; i++) {
      if (!validateMultipleEmails(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      }
    }
    if (invalidEmails.length > 0 && event.target.value !== '') {
      setRestrictedToError(true);
    } else {
      localStorage.setItem('structurerestrictedemails', JSON.stringify(emails));
      setRestrictedToError(false);
    }
  }

  const validateMultipleEmails = (e: string) => {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(e);
  };

  const DomainList = [
    'BW',
    'HC',
    'PC',
    'PLM',
    'Regulatory',
    'Safety',
  ];
  const verificationStatusList = [
    'VERIFIED',
    'UNVERIFIED'
  ]

  /*const handleVerificationStatus = (event: any) => {
    setVerificationStatus(event.target.value);
  }
  const handleAudit = (val: any) => {
    setAuditDescription(val.target.value);
  }*/

  const convertArrayToString = (item: []) => {
    if (item != null && item.length) {
      return item.join(', ')
    } else {
      return ""
    }
  }
  return (
    <Paper className={props.searchIndex !== "true" ? classes.parentheight : ""}>
      {node && props.searchIndex !== "true" && <DetailTitle name={itemDetail.backboneIdentity} type={detailData.type} subType={node.subType} status={node.status} isNameFlag={isNameFlag} disableHistory={disableHistory} tabName={'structuretab'} isVariantRestricted={isVariantRestricted} isPolymerRestricted={isRestricted} />}
      {itemDetail &&
        itemDetail.classificationPaths && props.searchIndex !== "true" &&
        <React.Fragment>
          <PathDisplay paths={itemDetail.classificationPaths} />
        </React.Fragment>
      }
      {open &&
        <EditPolymerDialog MRVchanged={props.MRVchanged} />}
      {/*{confirmBoxOpen && <ConfirmBoxDialog/>}*/}
      {props.searchIndex !== "true" ? (<div className={!user.isAdmin || (variantFlag && polymerFlag) ? classes.hideSection : classes.showSection}>
        <Grid container className={classes.mainDiv} spacing={2}>
          <Grid item lg={12} className={classes.flex} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.pad} >
                Restricted Domains:
              </div>
              <FormControl className={classes.formControl} data-testid="domain-select">
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={Domains}
                  onBlur={handleChange}
                  onChange={handleChange}
                  className={classes.selectEmpty}
                  disabled={(emailEdit == null || emailEdit == "" ? ifEmailEntered : true) || DisableRestrictPanel || isRestricted} //#DisableRestrictPanel
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {DomainList.map((domain) => (
                    <MenuItem key={domain} value={domain} >
                      {domain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {isLockIconRestrictionParentPolymer ? (
              <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                <LockIcon className={classes.domainLock} />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item lg={12} >
            <div className={classes.padEmail} >
              Named list of users:
            </div>
            <div>
              <TextField
                type="text"
                placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                disabled={(Domains.length != 0 ? true : ifDropDownSelected) || DisableRestrictPanel || isRestricted}
                className={classes.textStrike}
                onBlur={e => handleRestrictedToChange(e)}
                onChange={e => { setEmailEdit(e.target.value) }}
                value={emailEdit}
              //label="Email Id" variant="outlined"
              />

            </div>
          </Grid>
        </Grid>
      </div>
      ) : null}
      {props.searchIndex !== "true" ? <Grid item lg={12} className={!user.isAdmin ? classes.hideSection : classes.showSection}>
        {console.log("Domains -- " + Domains.length + " -- emailEdit -- " + emailEdit + " -- user.isAdmin -- " + user.isAdmin + " -- polymerFlag -- " + polymerFlag + " -- variantFlag -- " + variantFlag + " -- isRestricted -- " + isRestricted)}

        {props.searchIndex !== "true" ?
          user.isAdmin && polymerFlag == true && variantFlag == false && !isRestricted ?

            (Domains.length == 0 && emailEdit == null ? <div><Button type="button" variant="contained" name="Save" onClick={onAddRestrictions} color="primary" className={classes.saveBtn}>
              {/*#onClick={saveStructure} disabled={enableSaveBtn || DisableRestrictPanel}*/}
              Save
            </Button><br />
            </div> : <div><Button type="button" variant="contained" name="Save" onClick={onEditRestrictions} color="primary" className={classes.saveBtn}>
              {/*#onClick={saveStructure} disabled={enableSaveBtn || DisableRestrictPanel}*/}
              Save
            </Button><br />
            </div>)

            : null : null}
      </Grid> : null}

      {props.searchIndex !== "true" ? <Paper className={classes.container}>
        PARENT DETAILS
      </Paper> : null}

      {props.searchIndex !== "true" ?
        //<div className={!user.isAdmin ? classes.hideSection : classes.showSection}>
        <Grid className={classes.mainDiv} >
          <Table stickyHeader aria-label="sticky table" className={classes.matTab} >
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>SRU_SMILES</TableCell>
                <TableCell>PRF_SMILES</TableCell>
                <TableCell>SOURCE SMILES</TableCell>
                <TableCell>SUBSTITUENT SMILES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="namelistBody">
              <TableRow>
                <TableCell>
                  <Typography className={classes.cell}>
                    {isLockIconRestrictionParentPolymer && parentPolymerDetails && (parentPolymerDetails.sruSmiles != null || parentPolymerDetails.prfSmiles != null || parentPolymerDetails.sourceSmiles != null || parentPolymerDetails.substituentSmiles != null) ? (
                      <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                        <LockIcon className={classes.smallLock} />
                      </Tooltip>
                    ) : (<span style={{ width: 20, height: 20 }}></span>)}
                    {polymerFlag == true && variantFlag == false && parentPolymerDetails !== null && !isRestricted && user.isAdmin && <span onClick={onEditPolymerItem}><EditButton /> </span>}

                    {!isRestricted && <span onClick={onViewParentItem}> <ViewButton /> </span>}
                  </Typography>
                </TableCell>
                <TableCell> {(parentPolymerDetails != null ? parentPolymerDetails.sruSmiles : "")} </TableCell>
                <TableCell> {(parentPolymerDetails != null ? parentPolymerDetails.prfSmiles : "")}</TableCell>
                <TableCell>{(parentPolymerDetails != null ? parentPolymerDetails.sourceSmiles : "")}</TableCell>
                <TableCell> {(parentPolymerDetails != null ? parentPolymerDetails.substituentSmiles : "")}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        : null}

      {props.searchIndex !== "true" ? <Paper className={classes.container}>
        VARIANT DETAILS
      </Paper> : null}

      {props.searchIndex !== "true" ? <div className={!user.isAdmin ? classes.hideSection : classes.showSection}>
        <Grid className={classes.mainDiv} >
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Source ID</TableCell>
                <TableCell>DS</TableCell>
                <TableCell>Mw</TableCell>
                <TableCell>Mn</TableCell>
                <TableCell>PDI</TableCell>
                <TableCell>DP</TableCell>
                <TableCell>PRF Composition</TableCell>
                <TableCell>Source Composition</TableCell>
                <TableCell>Topology</TableCell>
                <TableCell>Chemical Microstructures</TableCell>
                <TableCell>Substituent Composition</TableCell>
              </TableRow>
            </TableHead>
            {(polymerFlag == true && variantFlag == false) ? //Parent Polymer Screen
              <TableBody className="namelistBody">
                {variantDetails != null && variantDetails.length > 0 && variantDetails.map((item: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography className={classes.cell}>
                        {/*{user.isAdmin && <span onClick={() => onEditVariant(item.variantName)}><EditButton /></span>}*/}
                        {/*{user.isAdmin && <span onClick={() => onViewVariant(item.variantName)}><ViewButton /></span>}*/}

                        <span style={{ display: "flex", alignItems: "center" }}>
                          {item.isLockIconVariantRestricted ? (
                            <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                              <LockIcon className={classes.smallLock} />
                            </Tooltip>
                          ) : (<span style={{ width: 20, height: 20 }}></span>)}

                          {!item.isVariantRestricted && <span onClick={() => onEditVariant(item.variantName)}><EditButton /></span>}
                          {item.isVariantRestricted && <span>
                            <Button size="small" variant="text" title="Edit" disabled={true}>
                              <EditIcon className={classes.icon} />
                            </Button>
                          </span>}

                          {!item.isVariantRestricted && <span onClick={() => onViewVariant(item.variantName)}><ViewButton /></span>}
                          {item.isVariantRestricted && <span>
                            <Button size="small" variant="text" title="View" disabled={true}>
                              <RemoveRedEye className={classes.icon} />
                            </Button>
                          </span>}
                        </span>
                      </Typography>
                    </TableCell>
                    <TableCell> {item.variantName} </TableCell>
                    <TableCell> {item.sourceID} </TableCell>
                    <TableCell> {item.ds} </TableCell>
                    <TableCell> {item.weightAverageMolecularWeight}</TableCell>
                    <TableCell> {item.numberAvergeMolecularWeight}</TableCell>
                    <TableCell>{item.polydispersityIndex}</TableCell>
                    <TableCell>{item.averageDegreeOfPolymerisation}</TableCell>
                    <TableCell>{item.prfCompositionMoleFraction}</TableCell>
                    <TableCell>{item.sourceCompositionMoleFraction}</TableCell>
                    <TableCell>{convertArrayToString(item.topology)} </TableCell>
                    <TableCell>{convertArrayToString(item.chemicalMicroStructure)} </TableCell>
                    <TableCell> {item.substituentComposition} </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              : (polymerFlag == true && variantFlag == true && variantscreen != null) ? //Variant Screen
                <TableBody className="namelistBody">
                  <TableRow key={`99`}>
                    <TableCell>
                      <Typography className={classes.cell}>
                        {/*{user.isAdmin && <span onClick={() => onEditVariant(variantscreen.variantName)}><EditButton /></span>}*/}
                        {/*{user.isAdmin && <span onClick={() => onViewVariant(variantscreen.variantName)}><ViewButton /></span>} */}

                        {variantscreen && isLockIconVariantRestrictedVariantPage ? (
                          <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                            <LockIcon className={classes.smallLock} />
                          </Tooltip>
                        ) : (<span style={{ width: 20, height: 20 }}></span>)}

                        {!variantscreen.isVariantRestricted && <span onClick={() => onEditVariant(variantscreen.variantName)}><EditButton /></span>}
                        {variantscreen.isVariantRestricted && <span>
                          <Button size="small" variant="text" title="Edit" disabled={true}>
                            <EditIcon className={classes.icon} />
                          </Button>
                        </span>}

                        {!variantscreen.isVariantRestricted && <span onClick={() => onViewVariant(variantscreen.variantName)}><ViewButton /></span>}
                        {variantscreen.isVariantRestricted && <span>
                          <Button size="small" variant="text" title="View" disabled={true}>
                            <RemoveRedEye className={classes.icon} />
                          </Button>
                        </span>}

                      </Typography>
                    </TableCell>
                    <TableCell> {variantscreen.variantName} </TableCell>
                    <TableCell>{variantscreen.sourceID} </TableCell>
                    <TableCell> {variantscreen.ds} </TableCell>
                    <TableCell> {variantscreen.weightAverageMolecularWeight}</TableCell>
                    <TableCell> {variantscreen.numberAvergeMolecularWeight}</TableCell>
                    <TableCell>{variantscreen.polydispersityIndex}</TableCell>
                    <TableCell>{variantscreen.averageDegreeOfPolymerisation}</TableCell>
                    <TableCell>{variantscreen.prfCompositionMoleFraction}</TableCell>
                    <TableCell>{variantscreen.sourceCompositionMoleFraction}</TableCell>
                    <TableCell> {convertArrayToString(variantscreen.topology)}</TableCell>
                    <TableCell>{convertArrayToString(variantscreen.chemicalMicroStructure)}</TableCell>
                    <TableCell> {variantscreen.substituentComposition} </TableCell>
                  </TableRow>
                </TableBody> : null}</Table>
        </Grid>
      </div>
        : null}

    </Paper>
  );
}
