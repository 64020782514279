import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { closeFlag, disableFlag, enableFlag } from '../store/flag/actions';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { AppState } from '../store';
const useStyles = makeStyles({
  textarea: {
    width: "99% !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    borderStyle: "solid !important",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  titlePad: {
    paddingBottom:"0px !important"
  },
  content: {
    paddingTop: "0px !important",
  },
  flex: {
    display:"flex !important"
  },
  font: {
    fontSize: "1.25rem !important",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
fontWeight: 500 + ' !important',
lineHeight: 1.6 + ' !important',
letterSpacing: "0.0075em  !important"
  },
  text: {
    fontSize: "1.25rem !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontWeight: 200 + ' !important',
    lineHeight: 1.6 + ' !important',
    letterSpacing: "0.0075em !important"
  }
});
const FlagDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const itemDetail = detailData.itemDetail;
  const [description, setDescription] = React.useState('');
  const [disableBtn, SetDisableBtn] = React.useState(true);
  let isOpen = nameFlag.open;
  let type=detailData.type;
  
  //const itemHistory = useSelector((state: AppState) => {
  //  return state.itemHistory;
  //});
  const onclose = useCallback(
    () => { dispatch(closeFlag()); },
    [dispatch]);
  const handleChange = (e: any) => {
    setDescription(e.target.value);
    if (e.target.value === '') {
      SetDisableBtn(true);
    } else {
      SetDisableBtn(false);
    }
  }
  const disableFlagApiCall = useCallback(
    (name: string, description: string) => {
      setDescription('');
      SetDisableBtn(true);
      const Name = name;
      const Description = description;
      dispatch(disableFlag(Name, Description))
    },
    [dispatch]
  );
  const enableFlagApiCall = useCallback(
    (name: string, description: string) => {
      const Name = name;
      setDescription('');
      SetDisableBtn(true);
      const Description = description;
      dispatch(enableFlag(Name, Description))
    },  
    [dispatch]
  );
  const open = isOpen === "openDialog";
  return (
    <Dialog open={open} onClose={onclose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle className={classes.titlePad}>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            {isNameFlag && isNameFlag.flagStatus === "A" ?
              `Review the flagged substance ${itemDetail.backboneIdentity}` :
              `Flag ${itemDetail.backboneIdentity} to request changes`
            }
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent className={classes.content}>
        {isNameFlag && isNameFlag.flagStatus === "A" ?
          <div className={classes.flex}><div className={classes.font}>Flag Description:</div>&nbsp;&nbsp;<div className={classes.text}>{isNameFlag.flagDescription}</div></div> : null}
        <textarea name="message" id="comment" className={classes.textarea} value={description} rows={7} cols={89.5} placeholder="Type here..." onChange={handleChange} ></textarea>
        {isNameFlag && isNameFlag.flagStatus === "A" ?
          <Button type="button" variant="contained" name="user" color="primary" disabled={disableBtn} onClick={(e) => disableFlagApiCall(itemDetail.backboneIdentity, description)}>
         {type=="Classification"?'Unflag Classification':'Unflag Substance'}
           </Button>
        :
          <Button type="button" variant="contained" name="user" color="primary" disabled={disableBtn} onClick={(e) => enableFlagApiCall(itemDetail.backboneIdentity, description)}>
         {type=="Classification"?'Flag Classification':'Flag Substance'}
           </Button>}
      </DialogContent>
    </Dialog>
  );
}
export default FlagDialog;
