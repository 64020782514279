import React from 'react';
import { makeStyles } from "@mui/styles";
import { ErrorMessage } from 'formik';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  errorText: {
    padding: '5px !important',
    color: "Red !important"
  },
});

type Props = {
  name: string;
};

const ValidationError = (props: Props) => {
  const classes = useStyles();
  const {name} = props;
  return (
    <ErrorMessage name={name}>
      {msg =>
        <Typography variant="body1" className={classes.errorText}>{msg}</Typography>
      }
    </ErrorMessage>
    );
};

export default ValidationError;
