import React from 'react';
import { Paper, Button, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@mui/material';
import ItemIcon from '../CommonComponents/ItemIcon';
import { Suggestion } from "../store/search/types";
import LockIcon from "@mui/icons-material/Lock";

const useStyles = makeStyles({
  cell: {
    fontSize: '12px !important',
    display: "flex !important",
    alignContent: "center !important"
  },
  tableheight: {
    paddingTop: '2px !important',
    overflow: "auto !important",
    //minHeight: "70vh",
      maxHeight: "70vh !important",
  },
    tooltipText: {
        fontSize: '14px !important',
        padding: '5px !important',
        margin: '5px !important',
        backgroundColor: "white !important",
        border: "1px solid black !important",
        color: "black !important",
    },
    domainLock: {
        fontSize: '24 !important',
        color: "red !important",
        fontWeight: "bold !important",
        marginTop: '5px !important',
        marginRight: "5% !important",
    },

    smallLock: {
        fontSize: '18 !important',
        color: "red !important",
        fontWeight: "bold !important",
        marginTop: '4 !important',
        justifyContent: "center !important",
        alignItems: "center !important",
        width: '16px !important',
        height: '16px !important',
    }
});

interface NameDetailListProps {
  data: any;
  highligted?: Suggestion | null;
  type?: any;
}

const CompositionDetailList = (props: NameDetailListProps) => {
  const classes = useStyles();
  const [showLockIcon, setShowLockIcon] = React.useState(true);
  return (props.data && props.data.compositeNames &&
    (
      <div className={classes.tableheight}> <Table stickyHeader aria-label="sticky table" >
        <TableHead>
          <TableRow>
            {props.data.isLockIconRestricted ? (<TableCell></TableCell>) : null}
            <TableCell>Type</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Domains</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Min Level</TableCell>
            <TableCell>Avg Level</TableCell>
            <TableCell>Max Level</TableCell>
            {//<TableCell></TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody className="compositelistBody">
          {props.data.compositeNames.map((item: any) => (
            <TableRow key={item.name}
            // {...shouldHighlight && item.name === highlighted.name ? { ref: highlightedRef, selected: true } : {}}
            >
              {props.data.isLockIconRestricted ? (
                <TableCell>
                  <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                    <LockIcon className={classes.smallLock} />
                  </Tooltip>
                </TableCell>
              ) : null}
              <TableCell>
                <Typography className={classes.cell}>
                  <ItemIcon type="Substance" subType={item.nameType} />
                  {item.nameType}
                </Typography>
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.domains ? item.domains.join(',') : ''}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.min}</TableCell>
              <TableCell>{item.avg}</TableCell>
              <TableCell>{item.max}</TableCell>

              {//<TableCell>{item.nameType !== "SIR ID" && <EditButton itemData={substanceNameDetailToEditItem(item)} />}</TableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </div>
    )
  );
}

export default CompositionDetailList;
