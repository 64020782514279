import React, { useCallback } from 'react';
import { Formik, FormikProps, Form, Field, FormikActions, ErrorMessage } from 'formik';
import { TextField } from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import SearchField from '../SearchBox/SearchField';
import { MergeItemsFormState } from "../store/mergeItems/types";
import { validateMerge } from "./MergeItemsValidation";
import { RadioGroup } from '@mui/material';
import { FormControlLabel, Radio } from '@mui/material';


  const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  errorText: {
    padding: '5px !important',
    color: "Red !important"
  },
  searchFieldSpacer: {
    marginTop: "20px !important"
  },
  swapBtn: {
   
    margin: "20px 10px -30px auto !important",
    zIndex: 1 + ' !important'
  },
  mergeBtn: {
    marginTop: "20px !important"
  },
  group: {
    // flexDirection: "row !important",
    marginBottom: "10px !important",
    marginTop: "-18px !important"
},
});

const MergeItemsForm = (props: FormikProps<MergeItemsFormState>) => {
  const classes = useStyles();
  const { values, setValues } = props;

  const onSwap = useCallback(() => {
    setValues({
      ...values,
      source: values.target,
      target: values.source
    });
  }, [values, setValues]);

  const substanceFilter = "Substance";

  console.log('values.defaultSelected ==', values.defaultSelected)

  const defaultFilterValue = (values.defaultSelected != undefined ? (values.defaultSelected == 'Substance' ? 'RestrictVariantINsubstance' : values.defaultSelected) : "RestrictVariantINsubstance");

  console.log('defaultFilterValue ==', defaultFilterValue);

  const [filter, setFilter] = React.useState<any>(defaultFilterValue);

  const selectRadio = useCallback( (e: any) => {
    let nullSuggestion = {
      name: "",
      type: "",
      subType: "",
      status: " ",
      substanceId: null,
      auditDescription: null
      } 
    setValues({
      ...values,
      source: nullSuggestion,
      target: nullSuggestion,
      defaultSelected: e.target.value
    });
     if (e.target.value) {
      if (e.target.value == 'Substance') {
        setFilter("RestrictVariantINsubstance");
      } else if (e.target.value == 'Classification') {
         setFilter("classification");
      } else {
        console.log('error selection====');
      }
    }
  }, [values])

   return (
    <Form>

       <Field name="defaultSelected" defaultValue={values.defaultSelected} label="some text" component={RadioGroup} className={classes.group} sx={{flexDirection: "row"}}>
        <FormControlLabel
          value="Substance"
          control={<Radio />}
          label="Substance"
          onClick={selectRadio}
        />
        <FormControlLabel
          value="Classification"
          control={<Radio />}
          label="Classification"
          onClick={selectRadio}
        />
      </Field>

      <InputLabel>Source</InputLabel>
      <SearchField
         name="source"
         key={`source-${values.source?.name}`}
         filter={filter}
         removeForMerge='Inactive'
       />
      <ErrorMessage
        name="source.name"
        render={msg => <Typography className={classes.errorText} variant="body1">{msg}</Typography>}
      />
      <Tooltip className={classes.swapBtn} sx={{ float: "right !important",}} title="Swap" placement="left">
        <IconButton onClick={onSwap} tabIndex={-1}><SwapVertIcon /></IconButton>
      </Tooltip>
      <InputLabel className={classes.searchFieldSpacer}>Target</InputLabel>
      <SearchField
        name="target"
        key={`target-${values.target?.name}`}
        filter={filter}
        removeForMerge='Inactive'
      />
      <ErrorMessage
        name="target.name"
        render={msg => <Typography className={classes.errorText} variant="body1">{msg}</Typography>}
      />
      <InputLabel className={classes.searchFieldSpacer} htmlFor="audit-description-input">Audit Description</InputLabel>
      <Field name="auditDescription">
        {({ field, form }: any) => (
          <TextField
            {...field}
            id= 'audit-description-input'
            className={classes.field}
            margin="dense"
            fullWidth
          />
        )}
      </Field>
      <span>The Source identity will be removed and all of its classifications and names will be added to the Target identity.</span>
      <br />
      <span>Please note, this operation can't be reverted.</span>
      <br />
      <Button className={classes.mergeBtn} type="submit" color="primary">Merge</Button>
    </Form>
  )
}

interface FormProps {
  values: MergeItemsFormState;
  onSave: (values: MergeItemsFormState, formikActions: FormikActions<MergeItemsFormState>) => void;
}

const MergeItemsFormik = (props: FormProps) => {
  const { values, onSave } = props;

  return (
    <div>
      <Formik
        initialValues={values}
        onSubmit={onSave}
        validate={validateMerge}
        render={(fp) => (
          <MergeItemsForm {...fp} />
        )}
      />
    </div>
  );
};

export default MergeItemsFormik;
