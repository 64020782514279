import React from 'react';
import { makeStyles } from "@mui/styles";
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, TablePagination } from '@mui/material';
import ItemIcon from '../CommonComponents/ItemIcon';
import { Suggestion } from "../store/search/types";
import LockIcon from "@mui/icons-material/Lock";
import { Paper, Button, Tooltip } from "@mui/material";


const useStyles = makeStyles({
  cell: {
    fontSize: '12px !important',
    display: "flex !important",
    alignContent: "center !important"
  },
  tableheight: {
    paddingTop: '2px !important',
    overflow: "auto !important",
    //minHeight: "70vh",
    height: "857px !important",
  },
  flex: {
    display:"flex !important"
  },
    marTop: {
      marginTop: '0px !important',
      marginLeft: '4px !important',
      cursor: "pointer !important",
  },
  tooltipText: {
    fontSize: '14px !important',
    padding: '5px !important',
    margin: '5px !important',
    backgroundColor: "white !important",
    border: "1px solid black !important",
    color: "black !important",
  },
  domainLock: {
    fontSize: '24 !important',
    color: "red !important",
    fontWeight: "bold !important",
    marginTop: '5px !important',
    marginRight: "5% !important",
  },

  smallLock: {
    fontSize: '18 !important',
    color: "red !important",
    fontWeight: "bold !important",
    marginTop: '4 !important',
    justifyContent: "center !important",
    alignItems: "center !important",
    width: '16px !important',
    height: '16px !important',
  }
});

interface NameDetailListProps {
  data: any | undefined;
  highligted?: Suggestion | null;
  type?: any;
}

const CompositeTabContent = (props: NameDetailListProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();
  const [data, setData] = React.useState(props.data.compositeNames);
  const [showAvgDesc, setShowAvgDesc] = React.useState(true);
  const [showAvgAsc, setShowAvgAsc] = React.useState(false);
  const [showMinDesc, setShowMinDesc] = React.useState(true);
  const [showMinAsc, setShowMinAsc] = React.useState(false);
  const [showMaxDesc, setShowMaxDesc] = React.useState(true);
  const [showMaxAsc, setShowMaxAsc] = React.useState(false);
  //avg sort descending
  const avgSortDesc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+b.avg) - (+a.avg)
    })
    //setData(null);
    setData(obj);
    setShowAvgDesc(false);
    setShowAvgAsc(true);
  }
  //avg sort ascending
  const avgSortAsc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+a.avg) - (+b.avg)
    })
    //setData(null);
    setData(obj);
    setShowAvgDesc(true);
    setShowAvgAsc(false);
  }
  //min sort descending
  const minSortDesc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+b.min) - (+a.min)
    })
    //setData(null);
    setData(obj);
    setShowMinDesc(false);
    setShowMinAsc(true);
  }
  //min sort ascending
  const minSortAsc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+a.min) - (+b.min)
    })
    //setData(null);
    setData(obj);
    setShowMinDesc(true);
    setShowMinAsc(false);
  }
  //max sort descending
  const maxSortDesc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+b.max) - (+a.max)
    })
    //setData(null);
    setData(obj);
    setShowMaxDesc(false);
    setShowMaxAsc(true);
  }
  //max sort ascending
  const maxSortAsc = () => {
    var obj = [...data]
    obj.sort((a: any, b: any) => {
      return (+a.max) - (+b.max)
    })
    //setData(null);
    setData(obj);
    setShowMaxDesc(true);
    setShowMaxAsc(false);
  }
  return (
    (props.data as any) &&
    (
      //<div>d</div>
      <div className={classes.tableheight}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Domains</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>
                <div
                  className={classes.flex}>
                  <div>Min Level</div>
                  <div className={classes.marTop}>{showMinDesc ? <i data-testid="minSortDescButton" className="fa fa-sort-numeric-desc" title="click to sort high to low order" onClick={minSortDesc}></i> : null}
                    {showMinAsc ? <i data-testid="minSortAscButton" className="fa fa-sort-numeric-asc" title="click to sort low to high order" onClick={minSortAsc}></i> : null}</div>
                </div>
              </TableCell>
              <TableCell >
                <div className={classes.flex}>
                  <div>Avg Level</div>
                  <div className={classes.marTop}>{showAvgDesc ? <i data-testid="avgSortDescButton" className="fa fa-sort-numeric-desc" title="click to sort high to low order" onClick={avgSortDesc}></i> : null}
                    {showAvgAsc ? <i data-testid="avgSortAscButton" className="fa fa-sort-numeric-asc" title="click to sort low to high order" onClick={avgSortAsc}></i> : null}</div>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.flex}>
                  <div>Max Level</div>
                  <div className={classes.marTop}>{showMaxDesc ? <i className="fa fa-sort-numeric-desc" title="click to sort on descending order" onClick={maxSortDesc}></i> : null}
                    {showMaxAsc ? <i data-testid="maxSortAscButton" className="fa fa-sort-numeric-asc" title="click to sort low to high order" onClick={maxSortAsc}></i> : null}</div>
                </div>
              </TableCell>
              {//<TableCell></TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody className="compositetabcontentBody">
            {
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, i: any) => (
                <TableRow key={i}
                //{...shouldHighlight && item.name === highlighted.name ? { ref: highlightedRef, selected: true } : {}}
                >
                  <TableCell>
                    {/* Lock Icon With Toltip */}
                    { item.isLockIconComposite ? (
                      <Tooltip title="Restricted Substance" classes={{ tooltip: classes.tooltipText }}>
                        <LockIcon className={classes.smallLock} />
                      </Tooltip>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      <ItemIcon type="Substance" subType={item.nameType} />
                      {item.entityType}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.name}</TableCell>

                  <TableCell>{item.domains.join(",")}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.min}</TableCell>
                  <TableCell>{item.avg}</TableCell>
                  <TableCell>{item.max}</TableCell>

                  {//<TableCell>{item.nameType !== "SIR ID" && <EditButton itemData={substanceNameDetailToEditItem(item)} />}</TableCell>
                  }
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    )
  );
}

export default CompositeTabContent;
