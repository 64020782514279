import React, { useCallback } from 'react';
import { Tooltip, TextField, Paper, FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, Slider, Grid, Button, } from '@mui/material';
import InformationIcon from "@mui/icons-material/Info";
import { SearchInProgress } from '../SearchBox/SearchMessages';
import { useDispatch, useSelector } from 'react-redux';
import { requestStructureSearch, loading, requestSearchStructure } from '../store/searchStructure/actions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles1 from '../MainPage/Styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    //marginTop: "15px",
    display: "flex !important",
    flexDirection: "column",
    width: "100% !important",
  },
  root1: {
    marginTop: "6px !important",
    display: "flex !important",
    flexDirection: "column",
    width: "100% !important",
  },
  marBor: {
    marginBottom: '24px !important',
    borderWidth: "1px !important",
    borderStyle: "solid !important",
    borderColor: "lightgray !important"
  },
  marBottom: {
    //marginBottom: "185px"
  },
  tooltip: {
    fontSize: "0.85rem !important"
  },
  label: {
    color: "rgba(0, 0, 0, 0.54) !important",
    padding: '0px !important',
    fontSize: "12px !important",
    fontFamily: "Roboto , Helvetica, Arial, sans-serif !important",
    fontWeight: 400 + ' !important',
  },
  textStrike: {
    width: "100% !important",
    //right:"6px",
  },
  menuItem: {
    display: "flex !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: "0.9rem !important",
    minHeight: "10px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    backgroundColor: "ghostwhite !important",
    paddingLeft: "15px !important",
    border: "1px solid lightgray !important",
    borderRadius: "4px !important",
    '&:hover': {
      textDecoration: "none !important",
      backgroundColor: "gainsboro !important",
      cursor: "pointer !important"
    },
  },
  active: {
    textDecoration: "none !important",
    backgroundColor: "gainsboro !important",
    cursor: "pointer !important"
  },
  menuItemMsg: {
    //position: "absolute",
    zIndex: 999 + ' !important',
    //width: 535,
    fontSize: "0.9rem !important",
    minHeight: "10px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    backgroundColor: "ghostwhite !important",
    paddingLeft: "15px !important",
    border: "1px solid lightgray !important",
    borderRadius: "4px !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  menuContainer: {
    //position: "absolute",
    zIndex: 999 + ' !important',
    //width: 552,
    maxHeight: '300px !important',
    overflow: "auto !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    //border: "1px solid lightgray",
    borderRadius: "4px !important",
  },
  nameColumn: {
    width: "50% !important",
    overflow: "hidden !important",
    whiteSpace: "nowrap !important",
    textOverflow: "ellipsis !important"
  },
  typeColumn: {
    width: "50% !important",
    overflow: "hidden !important",
    whiteSpace: "nowrap !important",
    textOverflow: "ellipsis !important",
    //textAlign: "right"
  },
  //substanceColumn: {
  //  width: "40%",
  //  overflow: "hidden",
  //  whiteSpace: "nowrap",
  //  textOverflow: "ellipsis",
  //  textAlign: "right"
  //},
  padLeft: {
    paddingLeft: "15px !important",
    width: "90% !important",
  },
  Legend: {
    paddingTop: "10px !important",
  },
  sliderVal: {
    width: '42px !important',
    border: "1px solid lightgray !important",
    borderRadius: "4px !important",
    marginLeft: "15px !important",
    marginBottom: "5px !important"
  },
  flex: {
    display: "flex !important",
  },
  marSeven: {
    marginBottom: "4px !important"
  },
  flexRow: {
    display: "flex !important",
    flexDirection: "row",
    paddingTop: "8px !important",
  },
  name: {
    width: "80% !important",
    paddingTop: "10px !important",
    //fontWeight: "bold",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
  },
  padbottom: {
    paddingBottom: "10px !important",
  },
  btnPostn: {
    paddingTop: "24px !important",
    paddingBottom: "20px !important",
    display: "flex !important",
    justifyContent: "space-between !important"
  },
  note: {
    color: "#0E0E9A !important",
    //opacity: 0.7,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    paddingLeft: "10px !important"
  },
  none: {
    display: "none !important",
  },
  padLeft1: {
    paddingTop: "18px !important",
    width: "85% !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
  }
});
//const rowData: any = [
//  { 'smiles': 'CCCC(O)CO', 'name': '1,2 PENTANEDIOL', id:1 },
//  { 'smiles': 'CCCCCCCCCC(O)CO', 'name': 'BATYL ALCOHOL', id: 2 },
//  { 'smiles': 'CC(O)COCC(C)O', 'name': 'DIPROPYLINE', id: 3 },
//  { 'smiles': 'CC(O)COC(C)(C)C', 'name': 'PROPYLINE', id: 4},
//  { 'smiles': 'CCCCCC(O)CO', 'name': '1,2 OCTANEDIOL', id: 5 },
//  { 'smiles': 'CC(=O)CC(O)CC', 'name': 'GLYCERYL', id: 6 },
//  { 'smiles': 'CC(=C)C(=O)O.OCC(O)CO', 'name': 'CALCIUM GLYCEROPHOSPATE', id: 7 },
//  //{ 'smiles': 'CCCC(O)CO', 'name': '1,2 PENTANEDIOL', id: 8 },
//  //{ 'smiles': 'CCCCCCCCCC(O)CO', 'name': 'BATYL ALCOHOL', id: 9 },
//  //{ 'smiles': 'CC(O)COCC(C)O', 'name': 'DIPROPYLINE', id: 10 },
//  //{ 'smiles': 'CC(O)COC(C)(C)C', 'name': 'PROPYLINE', id: 11},
//  //{ 'smiles': 'CCCCCC(O)CO', 'name': '1,2 OCTANEDIOL', id: 12 },
//  //{ 'smiles': 'CC(=O)CC(O)CC', 'name': 'GLYCERYL', id: 13},
//  //{ 'smiles': 'CC(=C)C(=O)O.OCC(O)CO', 'name': 'CALCIUM GLYCEROPHOSPATE', id: 14 },
//]
const SearchLabel = (props: { tooltipStyle: string }) => {
  const { tooltipStyle } = props;
  return (
    <Tooltip classes={{ tooltip: tooltipStyle }}
      title="By default, we'll search for your search term anywhere within the name. You can also use your own wildcards, using 1 or more *s. For example *hydroxymethyl*pyran*"
    >
      <span>Search<InformationIcon fontSize="inherit" /></span>
    </Tooltip>
  );
};
const InitialMin = 0;
const InitialMax = 30;
const SearchStructureInput = (props: any) => {
  const classes = useStyles();
  const classes1 = useStyles1();
  // -----------search input field related changes----------//
  const [value, setValue] = React.useState('');
  const [showList, setShowList] = React.useState(false);
  const [showNote, setShowNote] = React.useState(true);
  const [activeclassId, setActiveclassId] = React.useState("");
  const [disable, setDisable] = React.useState(false);
  const structureSearchResult = useSelector((state: any) => state.structureSearchReducer);
  const Loading = structureSearchResult.loading;
  const rowData = structureSearchResult.data;
  const handleInputChange = (event: any) => {
    setValue(event.target.value);
    //setDisable(false);
    if (event.target.value === '') {
      setShowList(false);
      //setDisable(true);
    }
  };
  //const [minResults, setMin] = React.useState(InitialMin);
  //const [maxResults, setMax] = React.useState(InitialMax);
  const onSaveItem = (e: any, id: any) => {
    setShowList(true);
    setActiveclassId(id);
    setValue(e);
    // props.getAPIData(e);
    setTimeout(() => {
      props.selectedSMILES(e);
    }, 2000);

    //setDisable(true);
  }
  //---------search type radio button related changes-------//
  const [searchType, setSearchType] = React.useState('exact');

  const handleChangeRadio = (event: any) => {
    setSearchType(event.target.value);
  };
  //-------------slider changes-------------------//
  const [sliderValue, setSliderValue] = React.useState(80);

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };
  //---------- last two radio button fields change---------------//
  const [doubleBond, setDoubleBond] = React.useState(false);
  const [tetrahedralStereo, setTetrahedralStereo] = React.useState(false);
  const str2bool = (value: any) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  }
  const handleChangedoubleBond = (event: any) => {
    let result: boolean = str2bool(event.target.value);
    setDoubleBond(result);
  };
  const handleChangeStereo = (event: any) => {
    let result1: boolean = str2bool(event.target.value);
    setTetrahedralStereo(result1);
  };
  //---------------button action---------------------//
  const dispatch = useDispatch();
  const searchApiCall = (value: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, minResults: any, maxResults: any) => {
    //alert('clicked fullfragment' + value);
    setOpen(true);
    props.resetData('resetData');
    localStorage.setItem('show', 'true');
    localStorage.setItem('open', 'yes');
    if (rowData !== undefined) {
      setShowList(true);
      setShowNote(false);
      //setOpen(false);
    }
    props.selectedSMILES({
      value: props.inputValMrv,
      tetrahedralStereo: tetrahedralStereo,
      doubleBond: doubleBond,
      searchType: searchType,
      sliderValue: sliderValue,
      open: true,
      minResults: InitialMin,
      maxResults: InitialMax,
    })
    commonCallBack(props.inputValMrv, tetrahedralStereo, doubleBond, searchType, sliderValue, true, minResults, maxResults)
  };
  const commonCallBack = useCallback(
    (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: any, minResults: any, maxResults: any) => {
      const Molecule = molecule;
      const TetrahedralStereo = tetrahedralStereo;
      const DoubleBond = doubleBond;
      const SearchType = searchType;
      const SliderValue = sliderValue;
      const Open = open;
      dispatch(loading(true));
      dispatch(requestSearchStructure.request(Molecule));
      dispatch(requestStructureSearch(Molecule, TetrahedralStereo, DoubleBond, SearchType, SliderValue, Open, minResults, maxResults))
      if (rowData !== undefined) {
        setShowList(true);
        setShowNote(false);
        props.getAPIData(rowData);
        //setOpen(false);
      }
    },
    [dispatch]
  );
  const reset = () => {
    props.resetData('resetData');
    setValue('');
    setSearchType('exact');
    setSliderValue(80);
    setDoubleBond(false);
    setTetrahedralStereo(false);
    props.resetMarvin('reset');
    //props.selectedSMILES(null);
    setShowList(false);
    setDisable(true);
    setShowNote(true);
    props.resetAll('all');
    localStorage.removeItem('show');
    //props.disableBtn(true);
  };
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (rowData || (structureSearchResult.isLoading === false)) {
      setOpen(false);
      localStorage.removeItem('open');
    }
  })
  return (
    <div className={classes.root}>
      <Paper className={classes.marBor}>
        <FormControl component="fieldset" className={classes.padLeft}>
          <FormLabel component="legend" className={classes.Legend}>Match Type:</FormLabel>
          <RadioGroup aria-label="searchType" name="searchType" value={searchType} onChange={handleChangeRadio}>
            <FormControlLabel value="exact" control={<Radio />} label="Exact Match" />
            <FormControlLabel value="fullFragment" control={<Radio />} label="Full Fragment" />
            <div className={classes.flex}>
              <FormControlLabel value="similarity" control={<Radio />} label="Similarity" />
              <div className={classes.root1}>
                <Grid container spacing={2} alignItems="center" style={{width:'calc(100% + 16px)', margin:'-8px'}}>
                  <div className={classes.marSeven}>0</div>
                  <Grid item xs style={{padding:'8px'}}>
                    <Slider
                      value={typeof sliderValue === 'number' ? sliderValue : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      valueLabelDisplay="auto"
                      style={{height:'0.2px'}}
                    />
                  </Grid>
                  <div className={classes.marSeven}>100</div>
                  <Grid item className={classes.sliderVal} style={{padding:'8px'}}>
                    {sliderValue}
                  </Grid>
                </Grid>
              </div>
            </div>
            <FormControlLabel value="subStructure" control={<Radio />} label="Substructure" />
          </RadioGroup>
        </FormControl>
      </Paper>
      <Paper className={classes.marBor}>
        <div className={[classes.flex, classes.padLeft].join(" ")}>
          <div className={classes.padLeft1} > Double bond isomerism: </div>
          <FormControl component="fieldset" className={classes.padLeft}>
            <RadioGroup aria-label="doubleBond" className={classes.flexRow} style={{flexDirection:'row'}} name="doubleBond" value={doubleBond} onChange={handleChangedoubleBond}>
              <FormControlLabel value={false} disabled={(searchType === 'similarity' || searchType === 'exact') ? true : false} control={<Radio />} label="ON" labelPlacement="end" />
              < FormControlLabel value={true} disabled={(searchType === 'similarity' || searchType === 'exact') ? true : false} control={<Radio />} label="OFF" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={[classes.flex, classes.padLeft].join(" ")}>
        <div className={[classes.padLeft1, classes.padbottom].join(" ")}>Stereoisomerism (Tetrahedral): </div>
          <FormControl component="fieldset" className={classes.padLeft}>
            <RadioGroup aria-label="doubleBond" className={classes.flexRow} style={{flexDirection:'row'}} name="doubleBond" value={tetrahedralStereo} onChange={handleChangeStereo}>
              <FormControlLabel value={false} disabled={(searchType === 'similarity' || searchType === 'exact') ? true : false} control={<Radio />} label="ON" labelPlacement="end" />
              <FormControlLabel value={true} disabled={(searchType === 'similarity' || searchType === 'exact') ? true : false} control={<Radio />} label="OFF" labelPlacement="end" />
            </RadioGroup>
          </FormControl>
        </div>
      </Paper>
      <Paper className={[classes.marBottom, classes.none].join(" ")}>
        <label className={classes.label}><SearchLabel tooltipStyle={classes.tooltip} /></label>
        <TextField
          type="text"
          placeholder="Type any SMILES string, select from the search options and hit SEARCH"
          disabled={false}
          className={classes.textStrike}
          onChange={handleInputChange}
          value={value}
          variant="outlined"
        />
        {(showList && (rowData !== undefined)) ?
          rowData.length > 0 ?
            <div className={classes.menuContainer}>
              {
                (showList && (rowData !== undefined)) ? rowData.map((row: any) => (
                  <div className={activeclassId === row.backboneIdentity ? [classes.menuItem, classes.active].join(" ") : classes.menuItem}
                    style={{
                      fontWeight: 400,
                    }}
                    key={row.id}
                    onClick={() => onSaveItem(row.molecule, row.backboneIdentity)}
                  >
                    <div className={classes.nameColumn}>{row.molecule}</div><div className={classes.typeColumn}>{row.backboneIdentity}</div>
                  </div>
                )
                ) : null}
            </div> :
            <div className={classes.menuItemMsg}>No matches found...(search in CAPITAL in case of Full Fragment)</div> :
          Loading ? <div className={classes.menuItemMsg}><SearchInProgress /></div> :
            null}
        {showNote ? <div className={classes.note}>Please note that SMILES search is case-sensitive</div> : null}
      </Paper>
      <div className={classes.btnPostn}>
        <Button type="button" variant="contained" color="primary" disabled={props.disableBtn} onClick={(e) => searchApiCall(value, tetrahedralStereo, doubleBond, searchType, sliderValue, InitialMin, InitialMax)}>
          Search
          </Button>
        <Button type="button" variant="contained" color="primary" onClick={(e) => reset()}>
          Reset
          </Button>
      </div>
      <Backdrop className={classes1.backdrop} open={open}>
        <div>Searching...</div><CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
export default SearchStructureInput;
