//import { makeStyles } from "@material-ui/styles";
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  backdrop: {
    zIndex: 0 + ' !important',
    color: '#fff !important',
  },
    root: {
      flexGrow: 1 + ' !important',
      marginTop: '15px !important'
    },
    paper: {
      //padding: 5,
      //textAlign: 'center' as 'center'
    },
    paperSize: {
        marginTop: '10px !important',
        height: '400px !important',
    },
    input: {
      marginLeft: '5px !important',
      flex: 1 + ' !important',
    },
    paperRoot: {
        padding: "2px 4px !important",
        display: "flex !important",
        alignItems: "center !important"
    },
});

export const useButtonStyles = makeStyles({
    rightIcon: {
        marginLeft: '5px !important',
    },
    button: {
        margin: "5px !important",  // Shame to have to use "important" but when I removed all buttons from overview page (so they are only in child components), they ended up having their margin overridden by buttonbase. I don't understand why that happened, but this brings it back up.
    },
  });

  export default useStyles;
