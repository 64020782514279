import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select, Checkbox } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { AddNameFormValues, AddNameFormConfig } from '../store/entity/types';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  }
});

type Props = {
  config: AddNameFormConfig
};

export const AddNameFormInner = (props: Props & FormikProps<AddNameFormValues>) => {
  const classes = useStyles();
  const {
    nameLabel,
    typeLabel,
    typeMenuItems,
    statusLabel,
    statusMenuItems,
    backboneIdentityLabel,
    auditDescriptionLabel,
    domainNameLabel,
    isPrimaryForDomainLabel,
    domainNameMenuItems
  } = props.config;
  return (
  <Form>
    <div>
      <InputLabel>{nameLabel}</InputLabel>
      <EnhancedTextInputField
        className={classes.field}
        name="name"
        autoFocus
        margin="dense"
        fullWidth
        />
      <InputLabel htmlFor="classification-type-input">{ typeLabel }</InputLabel>
      <Field name="type">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            inputProps={{ id: 'classification-type-input' }}
        >
         { typeMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
      </Field>

      <InputLabel htmlFor="status-input">{ statusLabel }</InputLabel>
      <Field name="status">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            inputProps={{ id: 'status-input' }}
        >
       { statusMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
      </Field>

      <InputLabel htmlFor="backbone-identity-input">{backboneIdentityLabel}</InputLabel>
      <Field name="backboneIdentity">
          {({ field, form }: any) => (
            <TextField
              {...field}
              autoFocus
              id= 'backbone-identity-input'
              className={classes.field}
              margin="dense"
              fullWidth
              error={Boolean(
                form.errors.backboneIdentity && form.touched.backboneIdentity
              )}
              helperText={
                form.touched.backboneIdentity && form.errors.backboneIdentity
              }
            />
          )}
      </Field>
      <InputLabel htmlFor="domain-name-input">{ domainNameLabel }</InputLabel>
      <Field name="domainName">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            inputProps={{ id: 'domain-name-input' }}
        >
        { domainNameMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
      </Field>

      <InputLabel htmlFor="is-primary-for-domain-input">{ isPrimaryForDomainLabel }</InputLabel>
      <Field
        className={classes.field}
        name="isPrimaryForDomain"
        component={Checkbox}
        margin="dense"
        inputProps={{ id: 'is-primary-for-domain-input' }}>
      </Field>
      <InputLabel htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
     <Field name="auditDescription">
          {({ field, form }: any) => (
            <TextField
              {...field}
              id= 'audit-description-input'
              className={classes.field}
              margin="dense"
              fullWidth
              error={Boolean(
                form.errors.auditDescription && form.touched.auditDescription
              )}
              helperText={
                form.touched.auditDescription && form.errors.auditDescription
              }
            />
          )}
      </Field>
      <Button type="submit" color="primary">
        Add
      </Button>
    </div>
  </Form>
)};

const AddNameForm = withFormik<Props, AddNameFormValues>({
  handleSubmit: ( formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  mapPropsToValues: (props: Props) => ({
      name: "",
      type: "",
      status: "",
      backboneIdentity: props.config.defaultBackboneIdentity,
      domainName: "",
      isPrimaryForDomain: false,
      auditDescription: ""
  }),
  enableReinitialize: true,
})(AddNameFormInner);

export default AddNameForm;
