import React from 'react';
import { Select, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  hidetype: {
    display: "none !important"
  },
  red: {
    fontSize: "14px !important",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif !important",
    fontWeight: 500 + ' !important',
    color: "red !important",
    marginBottom: "15px !important",
    marginTop: "-15px !important"
  }
});

interface LookupProps {
  lookup: LookupState;
  data: ItemData;
}

const EditClassificationForm = (props: LookupProps) => {
  const classes = useStyles();
  const [inActiveMsg, setInActiveMsg] = React.useState(false);
  const change = (e: any) => {
    if (e === 'Inactive') {
      setInActiveMsg(true);
    } else {
      setInActiveMsg(false);
    }
  }
  const { lookup,data } = props;
  return (
   <Form>
      <InputLabel className={(data.type === 'Classification') ? classes.hidetype : ''}>Name</InputLabel>
      <EnhancedTextInputField name="name" fullWidth margin="dense" className={(data.type === 'Classification') ? [classes.hidetype, classes.field].join(" ") : classes.field} />

      <InputLabel className={(data.type === 'Classification') ? classes.hidetype : ''}
      >Type</InputLabel>
      <Field name="subType">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={(data.type === 'Classification') ? [classes.hidetype, classes.field].join(" ") : classes.field}
            margin="dense"
            fullWidth
        >
        { lookup.classificationType.map((item) =>
          <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
        ) }
        </Select>
        )}
      </Field>

      <InputLabel>Status</InputLabel>
      <Field name={"status"}>
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            value={form.values["status"]}
            onChange={(e) => {
              form.setFieldValue("status", e.target.value);
              const selectedItem = lookup.status.find(item => item.key === e.target.value) ;
            }}
        >
       { lookup.status.map((item) =>
          <MenuItem key={item.key} value={item.key} onClick={(e) => change(item.display)} > {item.display}</MenuItem>
        )}
        </Select>
        )}
</Field>
      {inActiveMsg ? <div className={classes.red}>
        Note: Changing the status of the Classification to INACTIVE will automatically inactivate all the names, synonyms and identifiers assigned.
        Click SAVE in case you wish to proceed. </div> : null}

      <InputLabel>Audit Description</InputLabel>
      <Field name="auditDescription">
        {({ field, form }: any) => (
          <TextField
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
          />
        )}
      </Field>

      <Button type="submit" color="primary">
        Save
      </Button>
  </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const EditClassificationFormik = (props: FormProps) => {
  const { data, lookup } = props;

  return (
  <div>
    <Formik
      initialValues={data}
      onSubmit={props.onSave}
      render={() => (
        <EditClassificationForm lookup={lookup} data={data} />
      )}
    />
  </div>
  );
};

export default EditClassificationFormik;
