import React from 'react';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { Formik, Field, Form, FormikActions } from 'formik';
import { ItemData } from '../store/editItem/types';
import { LookupState } from '../store/lookupData/types';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  red: {
    fontSize: "14px !important",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif !important",
    fontWeight: 500 + ' !important',
    color: "red !important",
    marginBottom: "15px !important",
    marginTop: "-15px !important"
  }
});

interface LookupProps {
  lookup: LookupState;
  data?: ItemData;
}

const EditSubstanceForm = (props: LookupProps) => {
  const classes = useStyles();
  const { lookup, data } = props;
  const getSubComponentreducer = useSelector((state: any) => state.subComponentReducer);
  lookup.molWeight = (getSubComponentreducer && getSubComponentreducer.getMolWeightData[0]) ? getSubComponentreducer.getMolWeightData[0].substanceMolWeight : null;
  const molWeightVal = (data: any) => {
    localStorage.setItem('editSubSMolWeight', data.target.value);
  }
  const auditDescVal = (data: any) => {
    localStorage.setItem('editSubSAuditDesc', data.target.value);
  }
  const initialStatus = data ? data.status : null;
  const [activeMsg, setActiveMsg] = React.useState(false);
  const [inActiveMsg, setInActiveMsg] = React.useState(false);
  const change = (e: any) => {
    if (e === 'Inactive') {
      setInActiveMsg(true);
      //setActiveMsg(false);
    } else {
      setInActiveMsg(false);
    }
    //else if (e === 'Active') {
    //  setActiveMsg(true);
    //  setInActiveMsg(false);
    //}
  }
  return (
    <Form>
      <InputLabel>Status</InputLabel>
      <Field name={"status"}>
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            value={form.values["status"]}
            onChange={(e) => {
              form.setFieldValue("status", e.target.value);
              const selectedItem =  lookup.status.find(item => item.key === e.target.value);
            }}
        >
     {lookup.status.map((item) =>
          <MenuItem key={item.key} value={item.key} onClick={(e) => change(item.display)} > {item.display}</MenuItem>
        )}
        </Select>
        )}
</Field>


      {inActiveMsg ? <div className={classes.red}>
        Note: Changing the status of the Substance to INACTIVE will automatically inactivate all the names, synonyms and identifiers assigned.
        Click SAVE in case you wish to proceed. </div> : null}
      {// activeMsg ? <div className= { classes.red }>active</div>: null
      }

      { // <InputLabel>Name</InputLabel>
        // <EnhancedTextInputField name="name" fullWidth margin="dense" className={classes.field} disabled />
      }
      <InputLabel>Mol. Weight</InputLabel>
      <Field
        type="text"
        className={classes.field}
        name="molWeight"
        //inputProps={inputProps}
        //step="0.01"
        component={TextField}
        margin="dense"
        defaultValue={lookup.molWeight}
        onKeyDown={(e: any) => /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
        fullWidth
        onBlur={molWeightVal} />
      <InputLabel>Audit Description</InputLabel>
      <Field
        className={classes.field}
        name="auditDescription"
        component={TextField}
        margin="dense"
        fullWidth
        onBlur={auditDescVal} />
      <Button type="submit" color="primary">
        Save
      </Button>
    </Form>
  )
}

interface FormProps {
  data: ItemData;
  lookup: LookupState;
  onSave: (values: ItemData, formikActions: FormikActions<ItemData>) => void;
}

const EditSubstanceFormik = (props: FormProps) => {
  const { data, lookup } = props;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={props.onSave}
        render={() => (
          <EditSubstanceForm lookup={lookup} data={data} />
        )}
      />
    </div>
  );
};

export default EditSubstanceFormik;
