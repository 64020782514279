import React from 'react';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Paper, Grid, Button } from '@mui/material';
import ItemIcon from '../CommonComponents/ItemIcon';
import ExportButton from '../ImportExport/ExportButton';
import HistoryButton from '../History/HistoryButton';
import EditButton from '../EditItems/EditButton';
import HistoryButtonStructure from '../History/HistoryButtonStructure';
import FlagButton from '../Flag/FlagButton';
import { CSVLink } from 'react-csv';
import SaveIcon from "@mui/icons-material/SaveAlt";
import SwapButton from '../SwapBackbone/SwapButton';
import DoubeArrowButton from '../SubVSClasific/DoubleArrowButton';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import HistoryIcon from '@mui/icons-material/History';

const useStyles = makeStyles({
  container: {
    padding: '5px !important',
    textAlign: 'center !important' as 'center'
  },
  icons: {
    //float:"right",
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
});

type Props = {
  name: string;
  type: string;
  subType: string;
  status: string;
  tabName?: string;
  isNameFlag?: any;
  csvData?: any;
  disableHistory?: boolean;
  isVariantRestricted?: boolean;
  isPolymerRestricted?: boolean;
  isCompositeSub?: boolean;
};

const DetailTitle = (props: Props) => {
  const classes = useStyles();
  const { name, type, subType, status, tabName, isNameFlag, csvData, disableHistory, isVariantRestricted, isPolymerRestricted, isCompositeSub } = props;

  const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);
  const variantFlag = useSelector((state: AppState) => state.variant.variantFlag);

  console.log('flags ==', polymerFlag, variantFlag)

  //const variantscreen = useSelector((state: AppState) => state.variant.variantStrcutureInfo);

  //const isVRestricted = (variantscreen && variantscreen.isVariantRestricted != null ? variantscreen.isVariantRestricted : false);
  //console.log('isVRestricted ==', isVRestricted)
  return (
    <Paper className={classes.container}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h5" >
            <ItemIcon type={type} subType={subType} isCompositeSub={isCompositeSub} />
            {console.log('TEST TO U ==', type, subType, name)}
            {name}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <span className={classes.icons}>
            {/*{tabName === 'structuretab' ?*/}
            {/*  <HistoryButtonStructure name={name} type={type} tabName={tabName} disableHistory={disableHistory} /> :*/}
            {/*  tabName === 'substancetab' || tabName === 'ContributionfactorTab' ?*/}
            {/*    <HistoryButton name={name} type={type} tabName={tabName} /> : <HistoryButton name={name} type={type} disable={true} />*/}
            {/*}*/}

            {tabName === 'structuretab' && polymerFlag && variantFlag ? // Variant struture History
              <HistoryButton name={name} type={type} tabName={tabName} isVariant={true} isVariantRestricted={isVariantRestricted} /> :
              tabName === 'structuretab' && polymerFlag && !variantFlag ? // Polymer Struture History
                <HistoryButton name={name} type={type} tabName={tabName} isPolymer={true} isPolymerRestricted={isPolymerRestricted} /> :
                tabName === 'structuretab' && !polymerFlag && !variantFlag ?// Non Polymer structure History
                  <HistoryButtonStructure name={name} type={type} tabName={tabName} disableHistory={disableHistory} /> :
                  ""}

            {tabName === 'ContributionfactorTab' ?
              <HistoryButton name={name} type={type} tabName={tabName} /> :
              tabName === 'substancetab' && polymerFlag && variantFlag ?  // variant susbtance history
                <HistoryButton name={name} type={type} tabName={tabName} isVariantRestricted={isVariantRestricted} /> :
                tabName === 'substancetab' ? <HistoryButton name={name} type={type} tabName={tabName} /> :
                  ""
            }
            {/*Variant History button */}
            {/*{polymerFlag && variantFlag && tabName === 'substancetab' ? <HistoryButton name={name} type={type} tabName={tabName} isVariant={true} /> : polymerFlag && !variantFlag && tabName === 'substancetab'*/}
            {/*  ? <HistoryButton name={name} type={type} tabName={tabName} /> :*/}
            {/*  tabName === 'structuretab' && (!polymerFlag && !variantFlag) ?*/}
            {/*    <HistoryButtonStructure name={name} type={type} tabName={tabName} disableHistory={disableHistory} /> :*/}
            {/*    tabName === 'substancetab' || tabName === 'ContributionfactorTab' && (!polymerFlag && !variantFlag) ?*/}
            {/*      <HistoryButton name={name} type={type} tabName={tabName} /> : <HistoryButton name={name} type={type} disable={true} />*/}
            {/*}*/}
            {//tabName === 'substancetab' ? <ExportButton name={name} type={type} /> : <ExportButton name={name} type={type} disableExport={true} />
            }
            {tabName === 'substancetab' ? <CSVLink
              data={csvData}
              filename={"SIR-export.csv"}
              target="_blank" >
              <Button size="small" variant="text"><SaveIcon sx={{width: 16, height:16, color:'black'}} /></Button>
            </CSVLink>
              :
              <ExportButton name={name} type={type} disableExport={true} />
            }
            <EditButton itemData={{ name, type, subType, status: status, isPrimary: false, auditDescription: "" }} />
            <FlagButton isNameFlag={isNameFlag} name={name} type={type} />
            <SwapButton name={name} type={type} status={status} />
            {type !== 'Composite' ?
              <DoubeArrowButton itemData={{ name, type, subType, status: status, isPrimary: false, auditDescription: "" }} /> : null}
          </span>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DetailTitle;
