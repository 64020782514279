import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, IconButton, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { amber, green } from '@mui/material/colors';
import { AppState } from '../store';
import { closeMessageAction } from '../store/error/actions';
import { SirMessage, MessageSeverity } from '../store/error/types';
import { makeStyles } from '@mui/styles';
import { customSpacing } from '../theme/SIRtheme';

const useStyles = makeStyles((theme: any) => ({
  close: {
    padding: customSpacing(0.5, false) + ' !important',
  },
  success: {
    backgroundColor: green[600] + ' !important',
  },
  error: {
    backgroundColor: theme.palette.error.dark + ' !important',
  },
  info: {
    backgroundColor: theme.palette.primary.main + ' !important',
  },
  warning: {
    backgroundColor: amber[700] + ' !important',
  },
  icon: {
    fontSize: '20px !important',
  },
  iconVariant: {
    opacity: 0.9 + ' !important',
    marginRight: customSpacing(1, false) + ' !important',
  },
  message: {
    display: 'flex !important',
    alignItems: 'center !important',
  }
}));

const getMessage = (messages: SirMessage[]): SirMessage => {
  if (messages.length > 0) {
    return messages[0];
  }

  return {
    severity: MessageSeverity.Info,
    message: "Yes SIR!",
    time: 0
  };
}

const getDelayForSeverity = (severity: MessageSeverity): number => {
  if (severity === MessageSeverity.Error) {
    return 30000;
  } else {
    return 5000;
  }

}
const getClassForSeverity = (severity: MessageSeverity, classes: ReturnType<typeof useStyles>): string => {
  switch (severity) {
    case MessageSeverity.Info:
      return classes.info;
    case MessageSeverity.Success:
          return classes.success;
    case MessageSeverity.Warning:
      return classes.warning;
    case MessageSeverity.Error:
      return classes.error;
    default:
      return classes.info;
    }
}

const renderIcon = (severity: MessageSeverity, className: string): JSX.Element => {
  switch (severity) {
    case MessageSeverity.Info:
      return (<InfoIcon className={className} />);
    case MessageSeverity.Success:
      return (<CheckCircleIcon className={className} />);
    case MessageSeverity.Warning:
      return (<WarningIcon className={className} />);
    case MessageSeverity.Error:
      return (<ErrorIcon className={className} />);
    default:
      return (<InfoIcon className={className} />);
    }
}

const MessageSnackbar = () => {
  const classes = useStyles();
  const messages = useSelector((state: AppState) => state.messages);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeMessageAction()),
    [dispatch]
  );

  const message = getMessage(messages.messages);
  return (
    <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={messages.display}
    autoHideDuration={getDelayForSeverity(message.severity)}
    onClose={onClose}
    >
      <SnackbarContent
        className={getClassForSeverity(message.severity, classes)}
        aria-describedby="message-snackbar"
        message={
          <span id="message-snackbar" className={classes.message}>
            {renderIcon(message.severity, classes.icon)}
            {message.message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>);
}

export default MessageSnackbar;
