import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select, Checkbox } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { AddLinkFormValues, AddLinkFormConfig } from '../store/entity/types';
import SearchField from '../SearchBox/SearchField';
import { Suggestion } from '../store/search/types';
import { getParentFilter, getChildFilter } from "../domain/FilterHelper";

const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  searchFieldSpacer: {
    marginTop: "20px !important"
  },
});

type Props = {
  config: any
};

export const SwapSynonymSubstanceFormInner = (props: Props & FormikProps<any>) => {
  const classes = useStyles();
  const {
    childNameLabel,
    parentNameLabel,
    auditDescriptionLabel,
  } = props.config;
 
  return (
    <Form>
      <div>
        <InputLabel >{childNameLabel}</InputLabel>
        <SearchField
          name="childItem"
          filter="classification name,substance name" />
        <InputLabel className={classes.searchFieldSpacer}>{'Substance/Classification'}</InputLabel>
        <SearchField
          name="parentItem"
          filter="classification,substance" />

        <InputLabel className={classes.searchFieldSpacer} htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
         <Field name="auditDescription">
          {({ field, form }: any) => (
            <TextField
              {...field}
              id= 'audit-description-input' 
              className={classes.field}
              margin="dense"
              fullWidth
            />
          )}
        </Field>
        <Button type="submit" color="primary">
          Save
        </Button>
      </div>
    </Form>
  )
};

const emptySuggestion: Suggestion = {
  name: "",
  type: "",
  subType: "",
  status: "",
  substanceId: ""
};

const SwapSynonymSubstanceForm = withFormik<Props, any>({
  mapPropsToValues: (props) => ({ childItem: emptySuggestion, parentItem: emptySuggestion, auditDescription: ""}),
  handleSubmit: (formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(SwapSynonymSubstanceFormInner);

export default SwapSynonymSubstanceForm;
