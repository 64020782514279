import React from 'react';
import { Field, FieldProps } from 'formik';
import SearchInputBox from './SearchInputBox';
import { Typography } from '@mui/material';

interface Props {
  name: string;
  filter?: string | undefined;
  type?: string | undefined;
  removeForMerge?: string | undefined;
  values?: any;
  isVariant?: string | undefined;
}

const SearchField = (props: Props) => {
  const { name, filter, type, removeForMerge, values, isVariant } = props;
  const [parentRequired, setParentRequired] = React.useState(localStorage.getItem('parentRequired'));
  React.useEffect(() => {
    if (values && values.parentEntity != null && values.parentEntity.name !== undefined) {
      setParentRequired('false');
      localStorage.removeItem('parentRequired');
    }
  })
  return (
  <Field
    name={name}
    render={(props: FieldProps) => {
      const { field, form } = props;
      field.value = type === 'Composite substance' ? {} : field.value;
      const displaySearch = type === 'Composite substance' ? false : true;
      const displayNameError = type === 'Substance name' ? true : false;
      //const [showParentError, setShowParentError] = React.useState(field.value.name !== undefined ? 'false' : parentRequired)
      if (displaySearch) {
        return (
          <React.Fragment>
            <SearchInputBox
              value={field.value || {}}              
              filter={filter}
              removeForMerge={removeForMerge}
              itemSelected={value => {
                form.setFieldValue(name, value);
                form.setFieldTouched(name);
              }}
              isVariant={isVariant}
            />
            <Typography variant="caption" >{field.value && field.value.type}</Typography>
            {displayNameError && parentRequired === 'true' ? < div style={{ color: "red" }}>Parent field cannot be empty while adding Substance name</div>: null}
          </React.Fragment>
        )
      } else {
        return (
          <div></div>
        )
      }
    }
      }
  />
  )
};

export default SearchField;
