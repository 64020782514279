import React, { useCallback } from 'react';
import { makeStyles } from "@mui/styles";
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { openFlag } from '../store/flag/actions';
import { AppState } from '../store';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
  red: {
    color: "red  !important",
    fontSize: "16px !important",
  },
}));
type Props = {
  isNameFlag: any | undefined | null;
  name: string;
  type: string;
};
export const FlagButton = (props: Props) => {
  const { isNameFlag, name, type } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  //const showFlagDialog = () => {
  //  localStorage.setItem('openDialog', 'open');
  //}
  const showFlagDialog = useCallback(() => {
    dispatch(openFlag());
  }, [dispatch]);
  return (
    <span>
      <Button
        size="small"
        variant="text"
        className={classes.button}
        //title="flag"
        disabled={user.isAdmin ? (name === 'Loading...' || !type || !name) : (isNameFlag && isNameFlag.flagStatus === "A" || name === 'Loading...' || !type || !name)}
        //disabled={((isNameFlag.flagStatus === "A" || !user.isAdmin)) || (!name || !type)}
        onClick={showFlagDialog}
      >
        {(name && isNameFlag && isNameFlag.flagStatus === "A" && name !== 'Loading...') ?
          <i className={[classes.red, "fa fa-flag"].join(" ")}  title={type=='Classification'?'Classification is flagged, Review required':'Substance is flagged, Review required'}></i> : <i className="fa fa-flag-o" title={type=='Classification'?'click to flag this classification':'click to flag this substance/composite'}></i>
        }
      </Button>
    </span>
  );
}
export default FlagButton;


