import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import '../StructureTab/TabStyle.css';
import OverviewPage from './OverviewPage';
import SearchStructureMain from '../SearchStructures/SearchStructureMain';
import FlagNotifications from './FlagNotifications';
import { AppState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { getAllFlaggedInfo } from '../store/flag/actions';
import clsx from 'clsx';
import { searchApiFromUrl, reloadSubstanceDetails } from '../store/search/utilities';
import Switch from '@mui/material/Switch';


const useStyles = makeStyles((theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1 + ' !important',
    color: '#fff !important',
  },
  padTopLeft: {
    paddingTop: "10px !important",
    paddingLeft: "10px !important"
  },
  spacebetween: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    //width: "100%",
  },
  notification: {
    border: "none !important",
    paddingTop: "12px !important",
    minWidth: "50px !important",    
  },
  badge: {
    marginRight:"40px !important",
  },
  shape: {
    backgroundColor: theme.palette.primary.main + ' !important',
    width: '40px !important',
    height: '40px !important',
    cursor:"pointer !important"
  },
  shapeCircle: {
    borderRadius: '50% !important',
  },
  bellIcon: {
    color: "white !important",
    marginTop: "7px !important",
    marginLeft:"8px !important",
    fontSize:"1.5rem !important",
    width:"1em !important",
    height:"1em !important"
  },
  none: {
    display: "none !important"
  }
}));
//});
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const MainTab = (props: any) => {
  const classes = useStyles();
  const substanceNameFromUrl = props.match.params.substanceName ? props.match.params.substanceName.replace('+', '&plus&').replace('&backslash&', '/') : '';
  const typeFromUrl = props.match.params.type;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const user = useSelector((state: AppState) => state.user);
  const [checked, setChecked] = React.useState(false);
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getAllFlaggedInfo());
    const val = user.isAdmin ? localStorage.removeItem('showInactiveSynonyms') : null;
    if (substanceNameFromUrl && typeFromUrl) {
      dispatch<any>(searchApiFromUrl(substanceNameFromUrl, typeFromUrl, open))
    }
  },
    [dispatch]);
  const flagList = useSelector((state: any) => state.flagReducer);
  const badgeLength = flagList.getallDetails.length;
  const showFlagTab = () => {
    setValue(2);
  }

  const showInactiveSynonyms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    localStorage.setItem('showInactiveSynonyms', event.target.checked.toString());
    dispatch<any>(reloadSubstanceDetails(event.target.checked, selectedItem));
  };

  const circle = <div data-testid="notificationIcon" className={clsx(classes.shape, classes.shapeCircle)} ><NotificationsIcon className={classes.bellIcon} /></div>;
  return (
    <div className={classes.padTopLeft}>
      <AppBar sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Search Substances" {...a11yProps(0)} />
          <Tab label="Search Structures" {...a11yProps(1)} />
          <Tab className={classes.none} label="" {...a11yProps(2)} />
        </Tabs>
        {!user.isAdmin ? <div title="Show inactive names" className={classes.badge}>
          <span> Show Inactive Names: </span>
          <Switch
            color="primary"
            checked={checked}
            onChange={showInactiveSynonyms}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div> : null}
        {user.isAdmin ? <div className={classes.badge} onClick={showFlagTab}><Badge color="error" overlap="circular" showZero max={99} badgeContent={badgeLength}>
          {circle}
        </Badge></div> : null}
      </AppBar>
      <TabPanel value={value} index={0}>
        <OverviewPage substanceNameFromUrl={substanceNameFromUrl} typeFromUrl={typeFromUrl} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <SearchStructureMain />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FlagNotifications />
      </TabPanel>
      </div>
  );
}
export default MainTab;
