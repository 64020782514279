import React, { useEffect } from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { Select } from '@mui/material';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import SearchField from '../SearchBox/SearchField';
import { Suggestion } from '../store/search/types';
import { getParentFilterSynonym } from "../domain/FilterHelper";
import { useDispatch, useSelector } from 'react-redux';
import { getMolWeightInfo, getSubComponentInfo } from '../store/subComponent/actions';
import AddContributionTable from './AddContributionTable';
import  EditContributionTable from './EditContributionForm';
const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  searchFieldSpacer: {
    marginTop: "20px !important"
  },
  errorMsg: {
    color: "Red !important"
  },
  disabledName: {
    pointerEvents: "none"
  }
});

type Props = {
  config: any,
  selectedItem: any
};

export const AddLinkFormInner = (props: Props & FormikProps<any>) => {
  const classes = useStyles();
  const {
    parentNameLabel,
    statusLabel,
    statusMenuItems,
    auditDescriptionLabel,
    moleculeWeightLabel,
    formType
  } = props.config;
  const { values } = props;
  let dataLoad: any = [{}];
  let showError: boolean = false;
  //let loadField: boolean = false;
  const [loadField, setLoadField] = React.useState(false);
  const getSubComponentreducer = useSelector((state: any) => state.subComponentReducer);
  if (formType === 'EditContributionFactor') {
    if (getSubComponentreducer && getSubComponentreducer.getSubComponentInfo.length !== 0) {
      for (let i = 0; i < getSubComponentreducer.getSubComponentInfo.length; i++) {
        dataLoad.push({
          name: getSubComponentreducer.getSubComponentInfo[i].subComponentName,
          molweight: getSubComponentreducer.getSubComponentInfo[i].subComponentMolWeight,
          numberof: getSubComponentreducer.getSubComponentInfo[i].numberOfExpressedAs,
          contributionFactor: getSubComponentreducer.getSubComponentInfo[i].contributionFactor
        });
      };
      dataLoad.shift();
      //  setState(dataLoad);
    } else {
      dataLoad.shift();
    }
  }
  if (formType === 'AddContributionFactor') {
    dataLoad = [{}];
    dataLoad.shift();
  }
  if (values.parentItem.name !== "" && formType === 'AddContributionFactor' && (getSubComponentreducer && getSubComponentreducer.getSubComponentInfo && getSubComponentreducer.getSubComponentInfo.length) > 0) {
    showError = true;
  } else {
    showError = false;
  }
  const parentFilter = getParentFilterSynonym(values.parentItem);
  const dispatch = useDispatch();   
  const [molWeightParent, setMolWeightParent] = React.useState('');
  useEffect(() => {
    if (values.parentItem.name !== "") {
      if (getSubComponentreducer.getMolWeightData.length > 0 && getSubComponentreducer.getMolWeightData[0].substanceMolWeight !== "") {
        values.moleculeWeight = getSubComponentreducer.getMolWeightData[0].substanceMolWeight;
        setMolWeightParent(values.moleculeWeight);
        setLoadField(true);

      } else {
        //values.moleculeWeight = "";
        setMolWeightParent(values.moleculeWeight);
        showError = false;
        setLoadField(false);
      }
    }
  }, [getSubComponentreducer.getMolWeightData.length > 0 && getSubComponentreducer.getMolWeightData[0].substanceMolWeight])
  //if (values.parentItem.name !== "") {
  //  if (getSubComponentreducer.getMolWeightData.length > 0 && getSubComponentreducer.getMolWeightData[0].substanceMolWeight !== "") {
  //    values.moleculeWeight = getSubComponentreducer.getMolWeightData[0].substanceMolWeight;
  //    loadField = true

  //  } else {
  //    //values.moleculeWeight = null;
  //    showError = false;
  //    loadField = false;
  //  }
  //}
  useEffect(() => {
    if (values.parentItem.name !== "") {
      values.moleculeWeight = "";
      setMolWeightParent(values.moleculeWeight);
      dispatch(getMolWeightInfo(values.parentItem.name));
      dispatch<any>(getSubComponentInfo(values.parentItem.name));
    }
    if (values.parentItem.name === "") {
      values.moleculeWeight = "";
      setMolWeightParent(values.moleculeWeight);
      setLoadField(false);
      showError = false;
    }
  }, [values.parentItem.name]);
  const selectedItem = (val: any) => {
    props.selectedItem(val);
  }
  const molChange = (e: any) => {
    setMolWeightParent(e.target.value);
  }
  return (
    <Form>
      <div>
        <InputLabel className={classes.searchFieldSpacer}>{parentNameLabel}</InputLabel>
        {formType === 'AddContributionFactor' ? <div><SearchField
          name="parentItem"
          filter={parentFilter} /> </div> : <div className={classes.disabledName}><SearchField
            name="parentItem"
            filter={parentFilter} /> </div>}
        {showError === true ? <div className={classes.errorMsg}>Contribution factors already exist for the selected Parent entity. Please edit for this substance.</div> : null}
          <InputLabel className={classes.searchFieldSpacer} htmlFor="mol-weight-input">{moleculeWeightLabel}</InputLabel>

        {
          formType === 'AddContributionFactor' ?
            loadField ? 
              <Field name="moleculeWeight">
              {({ field, form }: any) => (
                <TextField
                  {...field}
                  type="text"
                  id= 'mol-weight-input'
                  defaultValue={molWeightParent}
                  className={classes.field}
                  margin="dense"
                  fullWidth
                  onKeyDown={(e: any) =>
                    /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
                  onChange={(e) => {
                    field.onChange(e);
                    molChange(e);
                  }}
                  error={Boolean(form.errors.moleculeWeight && form.touched.moleculeWeight)}
                  helperText={form.touched.moleculeWeight && form.errors.moleculeWeight}
                  disabled
                />
              )}
              </Field>
              :  <Field name="moleculeWeight">
              {({ field, form }: any) => (
                <TextField
                  {...field}
                  type="text"
                  id= 'mol-weight-input'
                  defaultValue={molWeightParent}
                  className={classes.field}
                  margin="dense"
                  fullWidth
                  onKeyDown={(e: any) =>
                    /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
                  onChange={(e) => {
                    field.onChange(e);
                    molChange(e);
                  }}
                  error={Boolean(form.errors.moleculeWeight && form.touched.moleculeWeight)}
                  helperText={form.touched.moleculeWeight && form.errors.moleculeWeight}
                />
              )}
              </Field>
            :
            <Field name="moleculeWeight">
            {({ field, form }: any) => (
              <TextField
                {...field}
                type="text"
                id= 'mol-weight-input'
                defaultValue={molWeightParent}
                className={classes.field}
                margin="dense"
                fullWidth
                onKeyDown={(e: any) =>
                  /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
                onChange={(e) => {
                  field.onChange(e);
                  molChange(e);
                }}
                error={Boolean(form.errors.moleculeWeight && form.touched.moleculeWeight)}
                helperText={form.touched.moleculeWeight && form.errors.moleculeWeight}
                disabled
              />
            )}
            </Field>
        }
        <InputLabel className={classes.searchFieldSpacer} htmlFor="status-input">{statusLabel}</InputLabel>

        <Field name="linkStatus" required>
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            required
            fullWidth
            inputProps={{ id: 'status-input' }}
        >
        {statusMenuItems.map((item: any) =>
            <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
          )}
        </Select>
        )}
      </Field>
        {formType === 'AddContributionFactor' ? <AddContributionTable parentName={values.parentItem} molWeightParent={values.moleculeWeight} selectedItem={selectedItem} dataLoad={dataLoad} /> :
          <EditContributionTable molWeightParent={values.moleculeWeight} parentName={values.parentItem} selectedItem={selectedItem} dataLoad={dataLoad} />} 
            <InputLabel className={classes.searchFieldSpacer} htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
          <Field name="auditDescription">
              {({ field, form }: any) => (
                <TextField
                  {...field}
                  id= 'audit-description-input'
                  className={classes.field}
                  margin="dense"
                  fullWidth
                  error={Boolean(
                    form.errors.auditDescription && form.touched.auditDescription
                  )}
                  helperText={
                    form.touched.auditDescription && form.errors.auditDescription
                  }
                />
              )}
            </Field>

        <Button type="submit" color="primary" disabled={showError}>
          Save
    </Button>
      </div>
    </Form>
  )
};

const emptySuggestion: Suggestion = {
  name: "",
  type: "",
  subType: "",
  status: "",
  substanceId: ""
};

const AddLinkContributionForm = withFormik<Props, any>({
  mapPropsToValues: (props) => ({ parentItem: props.config.parentEntity ? props.config.parentEntity : emptySuggestion, moleculeWeight: props.config.moleculeWeight ? props.config.moleculeWeight : "", linkStatus: "A", auditDescription: "", selectedItem: [] }),
  handleSubmit: (formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(AddLinkFormInner);

export default AddLinkContributionForm;
