import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { AppState } from '../store';
import { Button } from '@mui/material';
import { showEditItem } from '../store/editItem/actions';
import { ItemData } from '../store/editItem/types';

const useStyles = makeStyles(theme => ({
  button: {
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
}));

interface Props {
  itemData: ItemData
};

const DoubeArrowButton = (props: Props) => {
  const classes = useStyles();
  const { itemData } = props;
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const onEditItem = useCallback(() => {
    localStorage.setItem('isSubVsClass', 'true');
    const newDetails = localStorage.getItem('allSubNameDetails');
    let primaryDomainForEdit: any = [];
    if (newDetails) {

      let nameData: any = [];
      nameData = JSON.parse(newDetails);
      for (var i = 0; i < nameData.length; i++) {
        if (nameData[i].name === itemData.name) {
          itemData.primaryDomains = nameData[i].isPrimaryForDomains;
        }
      }
    }
    dispatch(showEditItem(itemData));
  }, [dispatch, itemData]);

  return (
    <span>
      <Button size="small" variant="text" className={classes.button} onClick={onEditItem} title="Swap SubstanceVsClassification" disabled={!user.isAdmin} >
        <i className="fa fa-arrows-h" aria-hidden="true"></i>
      </Button>
    </span>
  )
}

export default DoubeArrowButton;



