import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import {AppState} from '../index';
import { saveAs } from 'file-saver';
import { makeError } from '../error/actions';

export const requestSearchStructure = createAsyncAction(
  "SEARCH_STRUCT_START",
  "SEARCH_STRUCT_SUCCESS",
  "SEARCH_STRUCT_FAILURE")<string, any, string>();

export const requestSearchStructureWithInitial = createAsyncAction(
  "SEARCH_STRUCT_START_INITIAL",
  "SEARCH_STRUCT_SUCCESS_INITIAL",
  "SEARCH_STRUCT_FAILURE_INITIAL")<string, any, string>();

export const loaderRequest = createAsyncAction(
  "LOADER_REQUEST_START",
  "LOADER_REQUEST_SUCCESS",
  "LOADER_REQUEST_FAILURE")<string, boolean, string>();

export const getImageData = createAsyncAction(
  "GET_IMAGE_START",
  "GET_IMAGE_SUCCESS",
  "GET_IMAGE_FAILURE")<string, any, string>();

export const downloadSearchAction = createAsyncAction(
  "DOWNLOAD_START",
  "DOWNLOAD_SUCCESS",
  "DOWNLOAD_FAILURE")<void, string, string>();
//export const closeHistoryViewActionStruct = createStandardAction("HISTORY_CLOSE_STRUCT")<void>();
export const requestStructureSearch = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: boolean, minResults: any, maxResults: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
      if (searchType === 'exact') {
        exactSearch(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue,open, dispatch);
      }
      else if (searchType === 'fullFragment') {
        fullFragmentSearch(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue, open, dispatch);
      }
      else if (searchType === 'similarity') {
        similaritytSearch(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue, open, minResults, maxResults, dispatch);
      }
      else {
        subStructureSearch(molecule, tetrahedralStereo, doubleBond, searchType, sliderValue, open, minResults, maxResults, dispatch);
      }
  }
}

export const downloadSearchResults = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, minResults: any, maxResults: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(downloadSearchAction.request());
    
    const object = {
      searchType: (searchType === 'exact' ? 'ExactMatch' : searchType === 'fullFragment' ?
        'FullFragment' : searchType === 'similarity' ? 'similarity' : 'subStructure'),
      structureFormat: 'MOL',
      molecule: molecule,
      stereoSearchIgnoreTetrahedralStereo: tetrahedralStereo,
      stereoSearchOnMarkedDoubleBondOnly: doubleBond,
      similarityThreshold: sliderValue / 100,
      minValue: minResults,
      maxValue: 100000
    }
    axios.post(`/api/v1/File/DownloadStructuresAsCsv`, object)
      .then((response) => {
        const filename = response.headers['content-disposition'].split(';')[1].split('filename=')[1];
        dispatch(downloadSearchAction.success('Done'));
        saveAs(new Blob([response.data]), filename);
      })
      .catch((error) => {
        dispatch(makeError(error));
        dispatch(downloadSearchAction.failure(error.message));
      });
  }
}

export const getImageFromSmiles = (response: any) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
  //  dispatch(requestSearchStructure.request(response));
    if (response) {
      axios.post(`/api/v1/Structure/molConverter?structure=${encodeURIComponent(response)}&inputParams=SMILES&outputParams=jpeg`)
        .then((response) => {
          dispatch(getImageData.success(response));
        })
        .catch((error: any) => {
          dispatch(makeError(error));
        });
    }

  }
}
const exactSearch = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: boolean, dispatch: Dispatch) => {
  dispatch(requestSearchStructureWithInitial.request(molecule));
  axios.post(`/api/v1/Structure/ExactMatchSearch?structureFormat=MOL&hitCount=100`, { newString: molecule })
    .then((response) => {
      dispatch(requestSearchStructureWithInitial.success(response.data));
      localStorage.removeItem('open');
    })
    .catch((error: any) => {
      //open = false;
      const newOpen = false;
      dispatch<any>(loading(newOpen));
      dispatch(makeError(error));
      //dispatch(requestHistoryStructure.success(null));
      dispatch(requestSearchStructure.failure(error.message));
    });
}

const fullFragmentSearch = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open:boolean, dispatch: Dispatch ) => {
  dispatch(requestSearchStructureWithInitial.request(molecule));
  axios.post(`/api/v1/Structure/FullFragmentMol?hitCount=100&stereoSearchIgnoreTetrahedralStereo=${encodeURIComponent(tetrahedralStereo)}&stereoSearchOnMarkedDoubleBondOnly=${encodeURIComponent(doubleBond)}`, { newMol: molecule })
    .then((response) => {
      dispatch(requestSearchStructureWithInitial.success(response.data));
      localStorage.removeItem('open');
    })
    .catch((error: any) => {
      //open = false;
      const newOpen = false;
      dispatch<any>(loading(newOpen));
      dispatch(makeError(error));
      //dispatch(requestHistoryStructure.success(null));
      dispatch(requestSearchStructure.failure(error.message));
    });
}
const similaritytSearch = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: boolean, minResults: any, maxResults: any, dispatch: Dispatch) => {
  const threshold = (sliderValue / 100);
  dispatch(requestSearchStructure.request(molecule));
  //dispatch(requestSearchStructureWithInitial.request(molecule));
 // /api/v1 / Structure / SimilarityMolSearch ? minValue = 0 & maxValue=9 & structureFormat=MRV & hitCount=100 & similarityThreshold=0.8
  axios.post(`/api/v1/Structure/SimilarityMolSearch?minValue=${encodeURIComponent(minResults)}&maxValue=${encodeURIComponent(maxResults)}&structureFormat=MOL&hitCount=10000&similarityThreshold=${encodeURIComponent(threshold)}`, { newMol: molecule } )
    .then((response) => {
      localStorage.removeItem('open');
      if (minResults === 0 && maxResults === 30) {
        dispatch(requestSearchStructureWithInitial.success(response.data));
      } else {
        dispatch(requestSearchStructure.success(response.data));
      }
    })
    .catch((error) => {
      //open = false;
      const newOpen = false;
      dispatch<any>(loading(newOpen));
      dispatch(makeError(error));
      //dispatch(requestHistoryStructure.success(null));
      if (minResults === 0 && maxResults === 30) {
        dispatch(requestSearchStructureWithInitial.failure(error.message));
      } else {
        dispatch(requestSearchStructure.failure(error.message));
      }
    });
}
const subStructureSearch = (molecule: any, tetrahedralStereo: any, doubleBond: any, searchType: any, sliderValue: any, open: boolean, minResults: any, maxResults: any, dispatch: Dispatch) => {
  dispatch(requestSearchStructure.request(molecule));
  //SubStructureMrvSearch ? minValue = 0 & maxValue=10 & hitCount=10 & stereoSearchIgnoreTetrahedralStereo=true & stereoSearchOnMarkedDoubleBondOnly=true
  axios.post(`/api/v1/Structure/SubStructureMolSearch?minValue=${encodeURIComponent(minResults)}&maxValue=${encodeURIComponent(maxResults)}&hitCount=10000&stereoSearchIgnoreTetrahedralStereo=${encodeURIComponent(tetrahedralStereo)}&stereoSearchOnMarkedDoubleBondOnly=${encodeURIComponent(doubleBond)}`, { newMol: molecule })
    .then((response) => {
      //var temp = response.data;
      //let tempArray: any = [];
      //let finalArray: any = [];
      //for (let i = 0; i < temp.length; i++) {

      //}
      localStorage.removeItem('open');
      if (minResults === 0 && maxResults === 30) {
        dispatch(requestSearchStructureWithInitial.success(response.data));
      } else {
        dispatch(requestSearchStructure.success(response.data));
      }
    })
    .catch((error) => {
      //open = false;
      const newOpen = false;
      dispatch<any>(loading(newOpen));
      dispatch(makeError(error));
      //dispatch(requestHistoryStructure.success(null));
      //dispatch(requestSearchStructure.failure(error.message));
      if (minResults === 0 && maxResults === 30) {
        dispatch(requestSearchStructureWithInitial.failure(error.message));
      } else {
        dispatch(requestSearchStructure.failure(error.message));
      }
    });
}


export const loading = (isopen: boolean) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    dispatch(loaderRequest.success(isopen));
  }
}
