import React, { useCallback, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Table, TableHead, TableRow, TableBody, TableCell, Button } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { openSubCompEdit } from '../store/subComponent/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import AddDialogContributionFactor from './../AddContributionFactor/AddDialogContributionFactor';
import { AddContributionFactor } from '../store/entity/actions';
import { LookupState } from '../store/lookupData/types';


const useStyles = makeStyles({
  tableheight: {
    paddingTop: '2px !important',
    overflow: "auto !important",
    //minHeight: "70vh",
    //height: "857px",
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
  header: {
    height: "40px !important",
    backgroundColor: "lavenderblush !important",
    color: "blue !important",
    fontSize: "16px !important",
    fontWeight: 500 + ' !important',

    border: "1px solid lightgray !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  pad: {
    paddingTop: "10px !important",
    paddingLeft: "10px !important",
  },
  iconAlign: {
    float: "right"
  },
  red:
  {
    color: "red !important",
    fontSize: "12px !important",
    paddingTop: "8px !important",
    paddingBottom: "8px !important"
  }
});
const MolarFractionsTabContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openEditCF, setOpenEditCF] = useState(false);
  const getSubComponentreducer = useSelector((state: any) => state.subComponentReducer);
  let molarCfTableData = getSubComponentreducer ? getSubComponentreducer.getSubComponentInfo : [];
  //getSubComponentreducer.getMolWeightData[0].substanceMolWeight
  const noMol = ((getSubComponentreducer.getSubComponentInfo && getSubComponentreducer.getSubComponentInfo.length === 0) && (getSubComponentreducer.getMolWeightData && getSubComponentreducer.getMolWeightData.length === 0)) ? true : false;
  const user = useSelector((state: AppState) => state.user);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const lookupData: LookupState = useSelector((state: AppState) => state.lookup);
  const onEditLinkDialogClose = useCallback(() => {
    setOpenEditCF(false); localStorage.removeItem('addTableData');
    localStorage.removeItem('searchType');
  }, [setOpenEditCF]);

  //const [item, setItem] = React.useState({});
  const showEditSubComponentDialog = useCallback(() => {
    dispatch(openSubCompEdit());
  }, [dispatch]);
  const onEditSubComponent = (item: any, i: any) => {
    //setItem(item)
    showEditSubComponentDialog();
    // dispatch(showEditSubComponent(item));
    //if (item !== null) {
    localStorage.setItem('index', i);
    localStorage.setItem('parentMolWeight', item.substanceMolWeight);
    //}
  }

  const onEditContributionFactor = () => {
    setOpenEditCF(true);
  }
  const onSubmitEditLink = useCallback(
    (formValues: any, doClose: Function, setSubmitting: Function) =>
      dispatch(AddContributionFactor(formValues, doClose, setSubmitting, true)),

    [dispatch]
  );
  let selectedItemVal = (val: any) => {
    let data = JSON.stringify(val);
    localStorage.setItem('addTableData', data);
  }
  const editLinkFormConfig: any = {
    formType: "EditContributionFactor",
    title: "Edit Contribution Factor",
    moleculeWeightLabel: "Mol. Weight",
    parentNameLabel: "Parent",
    statusLabel: "Status",
    statusMenuItems: lookupData.status,
    auditDescriptionLabel: "Audit Description",
    parentEntity: detailData.selectedNode ? detailData.selectedNode : null,
    moleculeWeight: (getSubComponentreducer && getSubComponentreducer.getMolWeightData.length > 0) ? getSubComponentreducer.getMolWeightData[0].substanceMolWeight : "",
    submitAdd: onSubmitEditLink,
    onClose: onEditLinkDialogClose,
  };
  return (
    <div>
      <div>
        {noMol ? <div className={classes.red}>NOTE: Mol. Weight should be added on parent substance before adding or editing Contribution Factors</div> : null}
        <div className={classes.header}>
          <div className={classes.pad}>MOLAR CONTRIBUTION FACTORS
             <span className={classes.iconAlign}>
              <Button size="small" variant="text" onClick={onEditContributionFactor} title="Edit Contribution Factors..." disabled={!user.isAdmin || noMol} >
                <EditIcon className={classes.icon} />
              </Button>
            </span>
          </div>

        </div>
        <div className={classes.tableheight}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Substance</TableCell>
                <TableCell>Mol. Weight</TableCell>
                <TableCell>Sub-Component Expressed As</TableCell>
                <TableCell>Mol. Weight of Expressed As</TableCell>
                <TableCell>Number of Expressed As</TableCell>
                <TableCell>Contribution Factor</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="compositetabcontentBody">
              {
                molarCfTableData.length > 0 ? molarCfTableData.map((item: any, i: any) =>
                  (
                    <TableRow key={i}>
                      <TableCell>{item.substanceName}</TableCell>
                      <TableCell>{item.substanceMolWeight}</TableCell>
                      <TableCell>{item.subComponentName}</TableCell>
                      <TableCell>{item.subComponentMolWeight}</TableCell>
                      <TableCell>{item.numberOfExpressedAs}</TableCell>
                      <TableCell>{item.contributionFactor}</TableCell>
                      <TableCell>
                        <span>
                          <Button type="button" variant="text" name="user" color="primary" onClick={() => onEditSubComponent(item, i)} >
                            <EditIcon className={classes.icon} />
                          </Button>
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                )
                  : <TableRow><TableCell>No Records</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </div>
      </div>
      <div>
        <div className={classes.header}>
          <div className={classes.pad}>OTHER UNIVERSAL CONTRIBUTION FACTORS</div>
        </div></div>
      <AddDialogContributionFactor
        open={openEditCF}
        config={editLinkFormConfig}
        selectedItem={selectedItemVal}
      />
    </div>
  )
}
export default MolarFractionsTabContent;
