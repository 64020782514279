import { Grid, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik, FormikActions } from 'formik';
import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { warningMessage, validateNewItem } from '../domain/AddItemValidation';
import { LookupState } from '../store/lookupData/types';
import NameFormPanel from './NameFormPanel';
import ValidationError from './ValidationError';
const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  paper: {
    padding: "5px !important",
    height: "100% !important"
  },
  msgColorRed: {
    color: 'red !important',
    fontSize: '14px !important'
  },
  msgColorGreen: {
    color: 'green !important',
    fontSize: '14px !important'
  },
  molweight: {
    marginTop: "0px !important",
    fontSize: "17px !important",
    color: "red !important",
    fontWeight: 400 + ' !important',
    fontFamily:"Roboto !important"
  },
  nameVal: {
    position: "relative",
    top: "-83px !important",
    marginTop: "0px !important",
    fontSize: "17px !important",
    color: "red !important",
    fontWeight: 400 + ' !important',
    fontFamily: "Roboto !important"
  }

});

const getSubTypesForType = (type: string, lookup: LookupState) => {
  if (type === 'Classification') {
    return lookup.classificationType;
  }
  return lookup.nameType;
}


interface LookupProps {
  lookup: LookupState;
 // onSaveComp: any;
  //datatoValidate : DatatoValidate;
}

const AddNewItemForm = (props: any) => {
  const classes = useStyles();
  const { lookup, values } = props;
  const message = warningMessage(values);
  const [mol, setMol] = useState(null);
  const [molVal, setMolVal] = useState(false);
  if (props.setNameVal) {

  }
  const statusSelected = (val: any) => {
    localStorage.setItem('status', val);
  }
  const typeSelected = (val: any) => {
    localStorage.setItem('typeSelected', val);
  }
  const saveClick = (val: any) => {
    if (mol === null) {
      setMolVal(true);
    } else {
      setMolVal(false);
    }

  }
  const molWeightChange = (val: any) => {
    setMol(val.target.value);
    if (val.target.value !== null) {
      setMolVal(false);
    }
  }
  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <ValidationError name="newEntity.type" />
            <NameFormPanel propertyPrefix="newEntity." parentType={values.parentEntity.type} type={values.newEntity.type} subType={values.newEntity.subTtype} availableTypes={getSubTypesForType(values.newEntity.type, lookup)} availableStatuses={lookup.status} status={statusSelected} typeSelected={typeSelected} />
          
          </Paper>

        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Mol. Weight</InputLabel>
            <Field name="molweight">
              {({ field, form }: any) => (
                <TextField
                  {...field}
                  className={classes.field}
                  margin="dense"
                  fullWidth
                  onKeyDown={(e: any) =>
                    /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?><,-\s]*$/i.test(e.key) && e.which !== 8 && e.preventDefault()}
                  onBlur={(e) => {
                    field.onBlur(e);
                    molWeightChange(e);
                  }}
                  error={Boolean(form.errors.molweight && form.touched.molweight)}
                  helperText={form.touched.molweight && form.errors.molweight}
                />
              )}
              </Field>

          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} >
            <InputLabel>Audit Description</InputLabel>
            <Field name="auditDescription">
              {({ field, form }: any) => (
                <TextField
                  {...field}
                  className={classes.field}
                  margin="dense"
                  fullWidth
                  error={Boolean(
                    form.errors.auditDescription && form.touched.auditDescription
                  )}
                  helperText={
                    form.touched.auditDescription && form.errors.auditDescription
                  }
                />
              )}
            </Field>
          </Paper>
        </Grid>
        <Button type="submit" color="primary" onClick={saveClick}>
              Save
          </Button>
          <Typography variant="caption" >{message}</Typography>
        
      </Grid>
    </Form>
  )
}

interface FormProps {
  data: any;
  lookup: LookupState;
  onSave: (values: any, formikActions: FormikActions<any>) => void;
}

const AddNewSubItemFormik = (props: FormProps) => {
  const { data, lookup } = props;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={data}
        onSubmit={props.onSave}
        //validate={validateNewItem}
        render={(fp) => (
          <AddNewItemForm lookup={lookup}  {...fp} />
        )}
      />
    </div>
  );
};

export default AddNewSubItemFormik;
