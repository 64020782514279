import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { Button } from '@mui/material';
import { showMerge } from '../store/mergeItems/actions';
import { NodeData } from '../store/hierarchy/types';
import { ItemDetailState } from '../store/itemDetail/types';

const useStyles = makeStyles(theme => ({
  button: {
    margin: "5px !important",
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
  none: {
    display:"none !important"
  }
}));

 export const filterSelectedNode = (detailData: ItemDetailState): NodeData => {
  if (detailData.selectedNode && (detailData.selectedNode.type === "Substance" ||detailData.selectedNode.type === "Classification")) {
    return detailData.selectedNode;
  }

  return {} as NodeData
}

const MergeItemsButton = () => {
  const classes = useStyles();
  const user = useSelector((state: AppState) => state.user);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const dispatch = useDispatch();
  const onMergeItems = useCallback(() => {
    const node = filterSelectedNode(detailData);
    dispatch(showMerge({
      source: { name: node.name, type: node.type, subType: node.subType, status: node.status, substanceId: "" },defaultSelected:node.type
    }));
  }, [dispatch, detailData]);

  return (
    <Button id="mergeButton" onClick={onMergeItems} variant="contained" color="primary" className={[classes.button].join(" ")} disabled={!user.isAdmin}>
      Merge
    </Button>
  )
}

export default MergeItemsButton;
