import React, { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { customSpacing } from '../theme/SIRtheme';
import { closeAddVariantDialog, addVariant } from '../store/variant/actions';
import { Paper, Input } from '@mui/material';
import Button from '@mui/material/Button';
import { Form, Formik, FormikActions } from 'formik';
import { makeStyles } from '@mui/styles';
import { makeError } from '../store/error/actions';

import InputLabel from '@mui/material/InputLabel';
import SearchField from '../SearchBox/SearchField';
import { Checkbox, Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';

import { Select, MenuItem, Grid, FormControl, Chip } from '@mui/material';

import { addVariantObj, variantRestriction  } from "../store/variant/types";
import { Suggestion } from '../store/search/types';
import { getParentPolymerCOPY } from '../store/polymer/actions';
import { Tooltip, TextField } from '@mui/material';


const useStyles = makeStyles((theme: any) => ({

  field: {
    marginBottom: "20px !important"
  },
  flex: {
    display: 'flex !important'
  },
  label: {
    color: 'grey !important',
    paddingRight: '20px !important',
    paddingTop: '8px !important',
    paddingBottom: '15px !important'
  },
  disableDD: {
    pointerEvents: "none"
  },
  inputBox: {
    marginBottom: "20px !important",
    width: "98% !important"
  },
  domainPanel: {
    padding: "8px !important"
  },
  title: {
    textAlign: "center"
  },
  labelCheckbox: {
    textAlign: "left"
  },
  paper: {
    padding: '2px !important',
    margin: '8px !important'
  },
  font: {
    fontSize: "1rem !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontWeight: 400 + ' !important',
    lineHeight: 1.5 + ' !important',
    letterSpacing: "0.00938em !important",
    color: "rgba(0, 0, 0, 0.87 !important)",
  },
  width: {
    width: "100% !important"
  },
  removeMar: {
    margin: "0px !important"
  },
  marginTop: {
    marginTop: "15px !important",
  },

  //paper: {
  //  padding: "5px",
  //  height: "100%"
  //},
  msgColorRed: {
    color: 'red !important',
    fontSize: '14px !important'
  },
  msgColorGreen: {
    color: 'green !important',
    fontSize: '14px !important'
  },
  noaction: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: 'no-drop',
    pointerEvents: 'none'
  },
  formControl: {
    margin: customSpacing(1, false) + ' !important',
    width: "550px !important",
  },
  selectEmpty: {
    marginTop: "12px !important",
    padding: "0px !important",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '2px !important',
    height: '26px !important'
  },
  selectEmpty1: {
    marginTop: "2px !important",
    width: '550px !important',
  },
  formControl1: {
    margin: customSpacing(1, false) + ' !important',
    width: "700px !important",
  },
  paperCheckbox: {
    padding: '2px !important',
    textAlign: 'center !important' as 'center',
    width: "max-content !important",
    margin:'8px !important'
  },
  table: {
    minWidth: "200px !important"
  },
  tableCell: {
    padding: "0px !important",
    borderBottom: "none !important"
  },
  errText: {
    color: "Red !important",
    padding: "5px !important"
  },
  tooltipStyle: {
    fontSize: "0.85rem !important"
  },
  mainDiv: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    borderStyle: "solid !important",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  nocursor: {
    pointerEvents: "none",
  },
  pad: {
    paddingTop: "25px !important",
    paddingLeft: "15px !important",
    paddingRight: "20px !important",
    paddingBottom: "15px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  padEmail: {
    paddingTop: "25px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important',
    paddingLeft: "15px !important",
    paddingBottom: "15px !important"
  },
  para: {
    margin: '0px !important',
    color: 'red !important',
    paddingLeft: "15px !important"
  },
  textStrike: {
    marginTop: "20px !important",
    //paddingLeft: "15px"
  },
}));

const AddVariantDialog = (props: any) => {
  const classes = useStyles();

  const addVariantState = useSelector((state: AppState) => state.variant);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeAddVariantDialog()),
    [dispatch]
  );
  const onSave = useCallback(
    (values, formik) => {
    },
    [dispatch]
  )
  const state = useSelector((state: AppState) => state);


  const open = (addVariantState && addVariantState.showAddVariant == true) ? true : false;

  const parentEntity = useSelector((state: AppState) => state.variant.parentEntity);
  const parentEntityObj = {
    "parentEntity": parentEntity
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            Add new Variant
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>

      <DialogContent>

        <AddVariantFormik onSave={onSave} values={parentEntityObj} domain={props.domain} />
      </DialogContent>
    </Dialog>
  );
}

export default AddVariantDialog;

interface FormProps {
  values: any;
  onSave: (values: Suggestion, formikActions: FormikActions<any>) => void;
  domain: any;
}

const AddVariantFormik = (props: FormProps) => {

  const { values } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={props.onSave}
        //validate={validateNewItem}
        render={(fp) => (
          <AddVariantContent  {...fp} onSave={props.onSave} domain={props.domain} />
        )}
      />
    </div>
  );
};

const AddVariantContent = (props: any) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [variantName, setvariantName] = React.useState("");
  const [sourceID, setsourceID] = React.useState("");
  const [WAMW, setWAMW] = React.useState("");
  const [NAMW, setNAMW] = React.useState("");
  const [PDI, setPID] = React.useState("");
  const [DP, setDP] = React.useState("");
  const [PRF, setPRF] = React.useState("");
  const [substituentComposition, setsubstituentComposition] = React.useState("");
  const [sourceComposition, setsourceComposition] = React.useState("");
  const [auditDescp, setauditDescp] = React.useState("");
  const [DS, setDS] = React.useState("");

  const [topology, setTopology] = React.useState([]);
  const [chemicalMicro, setchemicalMicro] = React.useState([]);
  const [verificationStatus, setVerificationStatus] = React.useState('Active');
  const [type, setType] = React.useState('Synonym');

  // const detailData = useSelector((state: AppState) => state);
  //console.log('detailData ==', detailData)

  const { values } = props;
  //const parentName = values?.parentEntity.name;
  useEffect(() => {
    //  console.log('values ==',  values, values.parentEntity)
    if (values.parentEntity && values.parentEntity.name) {
      //  console.log('parentName ==', values.parentEntity.name);
      let variantName = "";
      if (sourceID != '') {
        let v = values.parentEntity.name + ' ' + sourceID;
        v = v.replace(/  +/g, ' ');//removing extra spaces
        v = v.trim();
        setvariantName(v);
      } 
      // Commenting the old code, where two were considered from mn,mw,pdi
      // else if (PRF != '' || sourceComposition != '' || WAMW != '' || NAMW != '' || PDI != '' || DP != '' || DS != '' || topology.length || chemicalMicro.length) {
      //   let one = (PRF != '' ? PRF + ' ' : sourceComposition + ' ');
      //   // in two if mn,mw,pdi r present take mn,mw else take whichever r present from 3 
      //   let two = (PDI != '' && WAMW != '' && NAMW != '') ? (WAMW != '' ? 'Mw ' + WAMW + ' ' : ' ') + (NAMW != '' ? 'Mn ' + NAMW + ' ' : ' ') : (WAMW != '' ? 'Mw ' + WAMW + ' ' : ' ') + (NAMW != '' ? 'Mn ' + NAMW + ' ' : ' ') + (PDI != '' ? 'PDI ' + PDI : '');
      //   let three = values.parentEntity.name + ' ' + one + two + ' ' + (DS != '' ? 'DS ' + DS + ' ' : '') + ' ' + (topology.length ? topology[0] : '') + ' ' + (chemicalMicro.length ? chemicalMicro[0] : '')
      //   let four = three.replace(/  +/g, ' ');
      //   four = four.trim();
      //   setvariantName(four);
      // }

      // New code, where DP is also considered, Substituent Composition is added 
      else if (PRF != '' || sourceComposition != '' || WAMW != '' || NAMW != '' || PDI != '' || DP != '' || DS != '' || substituentComposition != '' || topology.length || chemicalMicro.length) {
        let one = (PRF != '' ? PRF + ' ' : sourceComposition + ' ');
        //two of, in order of priority, “DP”  OR ("Mw" <Mw value>) OR ("Mn" <Mn value>) OR "PDI" <PDI value>
        let priority = ['DP','Mw','Mn','PDI'];
        let val = [DP,WAMW,NAMW,PDI];
        let res = [];
        for (let i=0;i<priority.length;i++){
          if(val[i] != ''){
            res.push(priority[i] + ' ' +val[i]);
            if(res.length==2) break;
          }
        }
        let two = res.join(' ');
        let three = values.parentEntity.name + ' ' + one + two + ' ' + (DS != '' ? 'DS ' + DS + ' ' : '') + ' ' + (substituentComposition != '' ? 'Substituent Composition ' + substituentComposition + ' ' : '') + ' ' + (topology.length ? topology[0] : '') + ' ' + (chemicalMicro.length ? chemicalMicro[0] : '');
        let four = three.replace(/  +/g, ' ');
        four = four.trim();
        setvariantName(four);
      }
      else {
        setvariantName('')
        console.log('else of ALL fields')
      }
    }
  }, [props, sourceID, PRF, WAMW, NAMW, PDI, DS,DP, topology, chemicalMicro,substituentComposition, sourceComposition]);


  useEffect(() => {
    if (values.parentEntity && values.parentEntity.name != '' && values.parentEntity.name != undefined) {
      console.log('parent changeing =====')
      getParent();
    }
  }, [values.parentEntity.name])

  const getParent = () => {
    dispatch<any>(getParentPolymerCOPY(values.parentEntity.name));
  }
  const parentPolymerDetails = useSelector((state: AppState) => state.polymersData.ParentPolymerDetailsCOPY);
  const variantDetails = (parentPolymerDetails != null ? (parentPolymerDetails.variantDetails != null ? parentPolymerDetails.variantDetails : []) : []);
  const [uniqueVariantErr, setuniqueVariantErr] = React.useState(false);
  console.log('variantDetails =', variantDetails);
  useEffect(() => {
    console.log(' name changed ', variantDetails, variantName)
    if (variantDetails.length && variantDetails.some((i: any) => i.variantName == variantName)) {
      setuniqueVariantErr(true);
    } else {
      setuniqueVariantErr(false);
    }
  }, [variantName])

  const LinkDataSearch = () => (
    <Paper className={classes.paper} >
      <InputLabel >Parent</InputLabel>
      {/*<SearchField name="parentEntity" type={values.newEntity.type} values={values} />*/}
      {/*<SearchField isVariant='Yes' type={substanceType} name="parentEntity" values={p} />*/}
      <SearchField name="parentEntity" isVariant='Yes' type='variant' values={values} />

    </Paper>
  )
  const verificationStatusList = [
    'Active',
    'Inactive'
  ]

  const sourceIdChange = (event: any) => {
    setsourceID(event.target.value)
  };

  const PDIChange = (event: any) => {
    setPID(event.target.value)
  };

  const NAMWChange = (event: any) => {
    setNAMW(event.target.value)
  };

  const WAMWChange = (event: any) => {
    setWAMW(event.target.value)
  };

  const DPChange = (event: any) => {
    setDP(event.target.value)
  };

  const PRFChange = (event: any) => {
    setPRF(event.target.value)
  };

  const substituentCompositionChange = (event: any) => {
    setsubstituentComposition(event.target.value)
  };

  const DSChange = (event: any) => {
    setDS(event.target.value)
  };

  const auditChange = (event: any) => {
    setauditDescp(event.target.value)
  };



  const chemicalMicrostrsArray = useSelector((state: AppState) => state.variant.chemicalMicrostructuresList);
  const topologyArray = useSelector((state: AppState) => state.variant.topologyList);

  const sourceCompositionChange = (event: any) => {
    setsourceComposition(event.target.value)
  }



  const topologyChange = (event: any) => {
    setTopology(event.target.value)
  };

  const chemicalMicroChange = (event: any) => {
    setchemicalMicro(event.target.value)
  };

  const handleVerificationStatus = (event: any) => {
    setVerificationStatus(event.target.value);
  }



  const handleType = (event: any) => {
    setType(event.target.value);
  }

  const lookup = useSelector((state: AppState) => state.lookup);
  let nameType = lookup.nameType;

  nameType = nameType.filter((i: any) => {
    if (i.display != "Sub Component" && i.display != "Component" && i.display != "CLS ID" && i.display != 'PLM ING' && i.display != 'Trade Name') {
      return true
    }
    else {
      return false
    }
  })

  console.log('nameType ==', nameType)
  //const domainArr = useSelector((state: AppState) => state.lookup.domain);
  const domainArr = props.domain
  //console.log('domainArr ==', domainArr)

  const initialDomain = () => {
    const s = {};
    const p = domainArr.map((item: any) => {
      Object.assign(s, { [item.key]: false })
      //if ([item.key].toString() == 'HC') {
      //  Object.assign(s, { [item.key]: true })
      //} else {
      //  Object.assign(s, { [item.key]: false })
      //}
    })

    console.log('p here ==', s)
    return s;
  }



  var linkDomainTmp = {};
  linkDomainTmp = initialDomain;

  var [linkdomain, setlinkdomain] = React.useState(linkDomainTmp);

  const domainErr = React.useRef(false);
  //const hcdomainErr = React.useRef(false);
  const minOnefieldErr = React.useRef(false);
  const parentEmptyErr = React.useRef(false);
  const RestrictionErr = React.useRef(false);

  const getChecked = (key: any) => {
    var k = false;

    for (const property in linkdomain) {
      if (property == key) {
        k = linkdomain[property as keyof typeof linkdomain];
      }
    }
    return k;
  }

  const linkDomainSelected = (key: string) => {
    console.log('linkdomain ==', linkdomain)
    var k = false;
    for (const property in linkdomain) {
      console.log(property, linkdomain[property as keyof typeof linkdomain]);
      if (property == key) {
        k = linkdomain[property as keyof typeof linkdomain];
      }
    }
    return k;
  }

  const domainChange = (item: any) => {
    const totalDomains = Object.keys(linkdomain).length-1;
    let s= {};
    if (item.a == 'ALL') {
      for (const p in linkdomain) {
        Object.assign(s, { [p]: item.selected });
      }
      setlinkdomain(s);
    } else {
      let selectCount = 0;
      for (const p in linkdomain) {
        if (p !== 'ALL' && (p ===item.a && item.selected) || linkdomain[p as keyof typeof linkdomain] == true){
          selectCount++;
        }
      }
          for (const p in linkdomain) {
            if (p === 'ALL') {
              Object.assign(s, { [p]: selectCount === totalDomains });
          }else if (p === item.a) {
          Object.assign(s, { [p]: item.selected });
        } else {
          let v = linkdomain[p as keyof typeof linkdomain];
          Object.assign(s, { [p]: v });
        }
      }
      setlinkdomain(s);
      //setlinkdomain({
      //  ...linkdomain,
      //  [item.a]: item.selected,
      //});
    }

    console.log('checked ==', item, linkdomain)
  }

  const validateVariant = () => {
    let d = computeDomain();
    console.log('D here ==', d.length)
    if (d.length == 0) {
      console.log('if ==')
      //setdomainErr(true);
      domainErr.current = true;

      // sethcdomainErr(false);
      //hcdomainErr.current = false;
    }
    //else if (d.includes('HC')) {
    //  console.log('else if ==')
    //  // setdomainErr(false);
    //  domainErr.current = false;
    //  //sethcdomainErr(false);
    //  hcdomainErr.current = false;
    //}
    else {
      console.log('else ==')
      //sethcdomainErr(true);
      //hcdomainErr.current = true;
      //setdomainErr(false);
      domainErr.current = false;
    }


     if (sourceID == '' && PRF == '' && WAMW == '' && NAMW == '' && PDI == '' && DP == '' && substituentComposition == '' && sourceComposition == '' && DS == '' && topology.length == 0 && chemicalMicro.length == 0) {
        //setminOnefieldErr(true);
      minOnefieldErr.current = true;
    } else {
      //setminOnefieldErr(false);
      minOnefieldErr.current = false;
    }
    console.log('parent ==', props, props.values.parentEntity)
    if (props.values.parentEntity != null && (props.values.parentEntity.name == '' || props.values.parentEntity.name == undefined)) {
      // setparentEmptyErr(true);
      parentEmptyErr.current = true;
    } else {
      // setparentEmptyErr(false);
      parentEmptyErr.current = false;
    }
    console.log('check ==', sourceID, emailEdit, Domains)
    if (sourceID == '' && (emailEdit != '' || Domains.length > 0)) {
      RestrictionErr.current = true;
    } else {
      RestrictionErr.current = false;
    }
  }

  const onSave = async () => {
    console.log('linkDomain in save==', linkdomain, computeDomain())
    await validateVariant();
    //!hcdomainErr.current
    if (!minOnefieldErr.current && !domainErr.current && !parentEmptyErr.current && !uniqueVariantErr && !RestrictionErr.current && !restrictedToError.current) {
      let obj: addVariantObj = {
        name: variantName,
        subType: type,
        status: (verificationStatus == 'Active' ? 'A' : 'I'),
        parentName: props.values.parentEntity.name,
        parentType: props.values.parentEntity.type,
        linkDomains: computeDomain(),
        auditDescription: auditDescp,
        sourceId: sourceID,
        weightAverageMolecular: WAMW,
        numberAvergeMolecular: NAMW,
        polydispersityIndex: PDI,
        averageDegreeOfPolymerisation: DP,
        prfCompositionMoleFraction: PRF,
        substituentComposition: substituentComposition,
        ds: DS,
        sourceCompositionMoleFraction: sourceComposition,
        topology: topology,
        microStructure: chemicalMicro
      }

      var arrEmailEdit = emailEdit.split(";");
      var jsonEmailEdit = JSON.parse(JSON.stringify(arrEmailEdit));

      let restrictObj: variantRestriction = {
        domains: Domains,
        mailIds: jsonEmailEdit
      }

      console.log('save here ==', obj, restrictObj)
      dispatch(addVariant(obj, restrictObj));
    } else {
      console.log('false tets ==', minOnefieldErr, domainErr, parentEmptyErr, uniqueVariantErr)
     if(!RestrictionErr.current){
      dispatch(makeError('Please fix Errors'))
    }
    }
  }


  const computeDomain = () => {
    const o = [];
    for (const property in linkdomain) {
      console.log(property, linkdomain[property as keyof typeof linkdomain]);
      if (property != 'ALL' && linkdomain[property as keyof typeof linkdomain] == true) {
        o.push(property)
      }
    }
    return o;
  }
  const DomainList = [
    'BW',
    'HC',
    'PC',
    'PLM',
    'Regulatory',
    'Safety',
  ];
  const [Domains, setDomains] = React.useState([]);
  const [ifDropDownSelected, setIfDropDownSelected] = React.useState(Domains.length > 0 ? true : false);

  const [emailEdit, setEmailEdit] = React.useState('');
  const [ifEmailEntered, setIfEmailEntered] = React.useState(emailEdit ? true : false);
  const restrictedToError = React.useRef(false);


  useEffect(() => {
    console.log(' restriction changed ', sourceID, emailEdit, Domains)
    if (sourceID == '' && (emailEdit != '' || Domains.length > 0)) {
      RestrictionErr.current = true;
    } else {
      RestrictionErr.current = false;
    }
  }, [sourceID])


  const handleRestrictedDomainChange = (event: any) => {
    setDomains(event.target.value);
    if (event.target.value.length > 0) {
      setIfDropDownSelected(true);
    } else {
      setIfDropDownSelected(false);
    }
    // localStorage.setItem('restricteddomains', JSON.stringify(event.target.value));
  }

  const handleRestrictedEmailChange = (event: any) => {
    if (event.target.value === '') {
      setIfEmailEntered(false);
      restrictedToError.current = false;
     } else {
      setIfEmailEntered(true);
      restrictedToError.current = true;
    }
    var emails = event.target.value.split(";");
    let invalidEmails = [];
    for (let i = 0; i < emails.length; i++) {
      if (!validateMultipleEmails(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      }
    }
    if (invalidEmails.length > 0 && event.target.value !== '') {
      restrictedToError.current = true;
    } else {
      //  localStorage.setItem('restrictredemails', JSON.stringify(emails));
      restrictedToError.current = false;
    }
  }

  const validateMultipleEmails = (e: string) => {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(e);
  };

  return (

    <Form>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <div>
              <InputLabel>Variant Name</InputLabel>
              <Tooltip classes={{ tooltip: classes.tooltipStyle }}
                title={variantName}
              >
                <Input className={classes.inputBox} value={variantName} disabled />
              </Tooltip>

              {uniqueVariantErr == true ? <Typography variant="body1" className={classes.errText}>Variant Name shloud be unique</Typography>
                : null}

              <InputLabel>Type</InputLabel>
              <FormControl className={classes.formControl1}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={type}
                  onChange={handleType}
                  className={classes.selectEmpty1}
                >
                  {nameType.map((item: any) => (
                    <MenuItem key={item.key} value={item.key} >
                      {item.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel>Status</InputLabel>
              <FormControl className={classes.formControl} data-testid="verification-status-select">
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={verificationStatus}
                  onChange={handleVerificationStatus}
                  className={classes.selectEmpty1}
                >
                  {verificationStatusList && verificationStatusList.map((verificationStatus) => (
                    <MenuItem key={verificationStatus} value={verificationStatus} >
                      {verificationStatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel htmlFor="sourceId">Source ID (Tradename,Catalogue ID,etc.)</InputLabel>
              <Input id="sourceId" className={classes.inputBox} onBlur={(item: any) => sourceIdChange(item)} />
              <InputLabel htmlFor="weightAvgMolWeight">Weight Average Molecular Weight (Mw)</InputLabel>
              <Input id="weightAvgMolWeight" className={classes.inputBox} onBlur={(item: any) => WAMWChange(item)} />
              <InputLabel htmlFor="numAvgMolWeight">Number Average Molecular Weight (Mn)</InputLabel>
              <Input id="numAvgMolWeight" className={classes.inputBox} onBlur={(item: any) => NAMWChange(item)} />
              <InputLabel htmlFor="PDI">Polydispersity Index (PDI)</InputLabel>
              <Input id="PDI" className={classes.inputBox} onBlur={(item: any) => PDIChange(item)} />
              <InputLabel htmlFor="DP">Average Degree of Polymerisation (DP)</InputLabel>
              <Input id="DP" className={classes.inputBox} onBlur={(item: any) => DPChange(item)} />
              <InputLabel htmlFor="PRF">Polymer Repeat Fragment (PRF) Composition (mole fraction(s))</InputLabel>
              <Input id="PRF" className={classes.inputBox} onBlur={(item: any) => PRFChange(item)} />
              <InputLabel htmlFor="subComposition">Substituent Composition</InputLabel>
              <Input id="subComposition" className={classes.inputBox} onBlur={(item: any) => substituentCompositionChange(item)} />

              <InputLabel>Restricted Domains:</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={Domains}
                  onChange={handleRestrictedDomainChange}
                  className={[classes.selectEmpty, "set-width"].join(" ")}
                  disabled={ifEmailEntered}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {DomainList.map((domain) => (
                    <MenuItem key={domain} value={domain} >
                      {domain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel>Named list of users: </InputLabel>
              <TextField
                type="text"
                placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                disabled={ifDropDownSelected}
                className={[classes.textStrike, "set-width-email"].join(" ")}
                onChange={e => { setEmailEdit(e.target.value) }}
                onBlur={e => { handleRestrictedEmailChange(e) }}
                value={emailEdit}
              //label=" Id" variant="outlined"
              />
              {restrictedToError.current == true ? <p className={classes.para} >One or More Email Addresses are Invalid</p> :
                null}

              {RestrictionErr.current == true ? <Typography variant="body1" className={classes.errText}> SOURCE ID is required to restrict the Variant. Please Enter the SOURCE ID </Typography>
                : null}

              {minOnefieldErr.current == true ? <Typography variant="body1" className={classes.errText}>Need to fill at least one field</Typography>
                : null}

            </div>


          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <Typography variant="h6" className={classes.title}>Link to:</Typography>
            <LinkDataSearch />
            {parentEmptyErr.current == true ? <Typography variant="body1" className={classes.errText}> Need to select parent polymer</Typography> : null}


            <div>
              <Paper className={classes.paperCheckbox} >
                <Typography variant="body1" className={classes.labelCheckbox}>Link in domains:</Typography>
                <Table className={[classes.table, "table"].join(" ")}>
                  <TableBody className="domainClass">
                    {domainArr.map((item: any) =>
                      <TableRow key={item.key}>
                        <TableCell className={classes.tableCell} align="right"><Typography variant="caption" align="right">{item.key}</Typography></TableCell>
                        <TableCell className={classes.tableCell} >
                          {/*defaultChecked={item.key == 'HC' ? true : false}*/}
                          <Checkbox checked={linkDomainSelected(item.key)} onChange={(e, v) => domainChange({ a: item.key, selected: v })} /></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </div>
            {domainErr.current == true ? <Typography variant="body1" className={classes.errText}>  Need to select at least one domain</Typography>
              : null}
            {/*{hcdomainErr.current == true ? <Typography variant="body1" className={classes.errText}>  Need to select HC domain</Typography>*/}
            {/*  : null}*/}


            <div>
              <InputLabel htmlFor="SrcComposition" className={classes.marginTop}>Source Composition</InputLabel>
              <Input id="SrcComposition" className={classes.inputBox} onBlur={(item: any) => sourceCompositionChange(item)} />
              <InputLabel>Topology</InputLabel>
              <FormControl className={classes.formControl} data-testid="topology-select">
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={topology}
                  onChange={topologyChange}
                  className={classes.selectEmpty}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {topologyArray.map((item) => (
                    <MenuItem key={item} value={item} >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel>Chemical Microstructures</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={chemicalMicro}
                  onChange={chemicalMicroChange}
                  className={classes.selectEmpty}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {chemicalMicrostrsArray.map((item) => (
                    <MenuItem key={item} value={item} >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel htmlFor="DOS">Degree of Substitution (DS)</InputLabel>
              <Input id="DOS" className={classes.inputBox} onBlur={(item: any) => DSChange(item)} />
              <InputLabel htmlFor="AuditDesc">Audit Description</InputLabel>
              <Input id="AuditDesc" className={classes.inputBox} onBlur={(item: any) => auditChange(item)} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Button type="submit" color="primary" onClick={onSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
