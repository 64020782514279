import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import FoldIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tree from './tree';
import TreeContext from './tree-context';
import useStyles from './style';
const MuiTree = ({
  className = '',
  title = '',
  data = {},
  labelKey = 'label',
  valueKey = 'value',
  childrenKey = 'children',
  unfoldFirst = false,
  unfoldAll = false,
  pageSize = 20,
  foldIcon = <FoldIcon />,
  unfoldIcon = <UnfoldIcon />,
  loadMoreIcon = <MoreVertIcon />,
  actionsAlignRight = false,
  getActionsData = null,
  renderLabel = null,
  renderLoadMoreText = (page, pageSize, total) => `Loaded pages: ${(page + 1)} / Page size: ${pageSize} / Total: ${total}. Click here to load more...`,
  requestChildrenData = null,
  ...rest
}) => {
  const classes = useStyles();
  return (
<Paper className={cn(classes.paper, className)}>
      {title && (
<Typography variant="h6" className={classes.caption}>
          {title}
</Typography>
      )}
      {data && (
<TreeContext.Provider value={{
          labelKey,
          valueKey,
          childrenKey,
          unfoldFirst,
          unfoldAll,
          pageSize,
          foldIcon,
          unfoldIcon,
          loadMoreIcon,
          actionsAlignRight,
          getActionsData,
          renderLabel,
          renderLoadMoreText,
          requestChildrenData
        }}>
<List dense component="div">
<Tree data={data} depth={0} path={[]} />
</List>
</TreeContext.Provider>
      )}
</Paper>
  );
};

export default MuiTree;
 