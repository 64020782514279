import { makeStyles } from '@mui/styles';
import { customSpacing } from '../theme/SIRtheme';
export default makeStyles(theme => ({
  paper: {
    paddingTop: customSpacing(3, false) + ' !important',
    paddingBottom: customSpacing(3, false) + ' !important'
  },
  caption: {
    fontSize: '16px !important',
    textAlign: 'center !important'
  },
  item: {
    width: 'auto !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingRight: customSpacing(2, false) + ' !important'
  },
  treeIconContainer: {
      minWidth: 'initial !important'
    },
  treeIcon: {
    width: '16px !important',
    height: '16px !important',
    marginRight: '8px !important'
  },
  treeIconButton: {
    color: 'theme.palette.primary.light !important'
  },
  treeTextButton: {
    color: 'theme.palette.primary.light !important'
  },
  treeText: {
    flex: '0 0 auto !important',
    paddingLeft: customSpacing(1, false) + ' !important',
    paddingRight: customSpacing(3, false) + ' !important',
    marginTop: '2px !important',
    marginBottom: '2px !important'
  },
  treeTextFlex: {
    flex: '1 !important'
  },
  button: {
    minWidth: 'auto !important',
    minHeight: 'auto !important',
    width: 'auto !important',
    height: 'auto !important',
    fontSize: '12px !important',
    lineHeight: '1 !important',
    padding: '4px !important'
  },
  actionIcon: {
    width: '12px !important',
    height: '12px !important'
  }
}));
