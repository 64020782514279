
export const ulPurplePrimary = "#8A1B61";
export const ulPurple2 = "#AC4FC6";
export const ulPurple3 = "#3C1053";
export const ulPurple4 = "#702F8A";
export const ulPurple5 = "#B288B9";

export const ulBluePrimary = '#0E0E9A'
export const ulBlue1 = "#0085CA";
export const ulBlue2 = "#71C5EA";
export const ulBlue3 = "#005EB8";
export const ulBlue4 = "#00A9E0";
export const ulBlue5 = "#004976";

export const uRed = "#b2102f";
export const colours = {
    ulPurplePrimary,
    ulPurple2,
    ulPurple3,
    ulPurple4,
    ulPurple5,
    ulBluePrimary,
    ulBlue1,
    ulBlue2,
    ulBlue3,
    ulBlue4,
    ulBlue5,
    uRed
};
