import React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@mui/material';
import { formatDateAndTime } from '../domain/Format';
const useStyles = makeStyles({
  paper: {
    padding: '5px !important',
    textAlign: 'center !important' as 'center',
    overflow: "auto !important",
    maxHeight: "70vh !important"
  }
});
//interface Props {
//  historyStructureData: ItemHistoryState;
//}
const HistoryStructureView = (props: any) => {
  const classes = useStyles();
  const { historyStructureData } = props;
  return(
    <Paper className = { classes.paper } >
      <Typography variant="h4" >
        Structure history
      </Typography>
      {historyStructureData.backboneIdentity !== "" ?
        <Table >
          <TableHead>
            <TableRow>
              <TableCell>When</TableCell>
              <TableCell>Who</TableCell>
              <TableCell>Molecule</TableCell>
              <TableCell>NameType</TableCell>
              <TableCell>Molecular Formula</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Verification Status</TableCell>
              <TableCell>Change Type</TableCell>
              <TableCell>Change Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="namelistBody">
            {historyStructureData.history.map((item:any, i:any) => (
              <TableRow key={`item${i}`}>
                <TableCell>{formatDateAndTime(item.historyDateTime)}</TableCell>
                <TableCell>{item.userName}</TableCell>
                <TableCell>{item.molecule}</TableCell>
                <TableCell>{item.nameType}</TableCell>
                <TableCell>{item.molecularFormula}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.verificationStatus}</TableCell>
                <TableCell>{item.changeType}</TableCell>
                <TableCell>{item.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> : null}
    </Paper >
  );
}
export default HistoryStructureView;
