import React, { useState, useEffect } from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import { showAddItem, showAddItemName } from '../store/addItem/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { ItemDetailState } from '../store/itemDetail/types';
import { NodeData } from '../store/hierarchy/types';

const useStyles = makeStyles({
  label: {
    padding: "10px !important"
  }
});
const filterSelectedNode = (detailData: ItemDetailState): NodeData => {
  if (detailData.selectedNode &&
    detailData.selectedNode.name !== 'Classification' && detailData.selectedNode.name !== 'Orphans') {
    return detailData.selectedNode;
  }

  return {} as NodeData
}

const ItemTypeSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const detailData = useSelector((state: AppState) => state.itemDetail);

  const [selectedValue, setSelectedValue] = useState<string | null>(localStorage.getItem("TargetType") || "");

  useEffect(() => {
    setSelectedValue(localStorage.getItem("TargetType") || "");
  }, []);

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedValue(value);
    const node = filterSelectedNode(detailData);
    localStorage.setItem("radioItemSelected", value);

    let v =
      detailData.itemDetail["backboneIdentity"] === "No item selected"
        ? ""
        : detailData.itemDetail["backboneIdentity"];

    let t = detailData.type;

    localStorage.removeItem("nameComposite");

    if (value === "Substance name" || value === "Classification") {
      dispatch(
        showAddItemName({
          parentEntity: {
            name: v,
            type: t,
            subType: node.subType,
            status: node.status,
          },
          targetType: value,
        })
      );
    } else if (value === "Sub component") {
      dispatch(
        showAddItem({
          parentEntity: {
            name: node.name,
            type: "Sub component",
            subType: node.subType,
            status: node.status,
          },
          targetType: value,
        })
      );
    } else {
      dispatch(
        showAddItem({
          parentEntity: {
            name: v,
            type: t,
            subType: node.subType,
            status: node.status,
          },
          targetType: value,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Field name="newEntity.type" label="New Item Type" sx={{'flex-direction': 'row'}}>
        {({ field }: any) => (
          <RadioGroup
            {...field}
            value={selectedValue}
            onChange={handleSelectionChange}
            sx={{ flexDirection: "row" }}
          >
            <Typography className={classes.label} variant="body1">
        Create a new:
      </Typography>
            <FormControlLabel
              value="Classification"
              control={<Radio />}
              label="Classification"
            />
            <FormControlLabel
              value="Classification name"
              control={<Radio />}
              label="Classification name"
            />
            <FormControlLabel
              value="Substance"
              control={<Radio />}
              label="Substance"
            />
            <FormControlLabel
              value="Substance name"
              control={<Radio />}
              label="Substance name"
            />
            <FormControlLabel
              value="Composite substance"
              control={<Radio />}
              label="Composite substance"
            />
            <FormControlLabel
              value="Sub component"
              control={<Radio />}
              label="Sub component"
            />
          </RadioGroup>
        )}
      </Field>
    </React.Fragment>
  )
}

export default ItemTypeSelector;
