import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './TabStyle.css';
import DetailContainer from '../DetailPanel/DetailContainer';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import CompositeStructureComponent from './CompositeStructureComponent';
import Paper from '@mui/material/Paper';
import DetailTitle from '../DetailPanel/DetailTitle';
import CompositeTabContent from '../DetailPanel/CompositeTabContent';
import CommentComponent from './CommentComponent';
import PathDisplay from '../DetailPanel/PathDisplay';
import MolarFractionsTabContent from '../DetailPanel/MolarFractionsTabContent';
import PolymerStructureComponent from './PolymerStructureComponent';


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1 + ' !important',
    backgroundColor: theme.palette.background.paper + ' !important',
  },
  paper: {
    padding: '5px !important',
    //textAlign: 'center' as 'center',
    //overflow: "auto",
    //maxHeight: "70vh"
  },
  cell: {
    fontSize: '12px !important',
    display: "flex !important",
    alignContent: "center !important"
  },
  none: {
    display: "none !important"
  },
}));
//let selected: string = '';
const TabForSubstance = () => {
  
  const defaultTabIndex = localStorage.getItem('defaultTabIndex');
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  if (defaultTabIndex === 'zero') {
    setValue(0);
    localStorage.removeItem('defaultTabIndex');
  }
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    localStorage.removeItem('defaultTabIndex');
  };
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const node = detailData.selectedNode;
  const itemDetail = detailData.itemDetail;  
  const compositeTabDetail = detailData.compositeTabDetail;
  const sirIdentity = (compositeTabDetail === undefined) ? null : detailData.compositeTabDetail.sirIdentity;
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  localStorage.setItem('parentTypeWhileEdit', 'Substance');
  const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);  
  return (
       ((selectedItem as any).type !== "Classification") ? <div className={classes.root}>
        <Paper>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth" >
              <Tab label="SUBSTANCE INFO" {...a11yProps(0)} wrapped />
              < Tab label="STRUCTURE INFO" {...a11yProps(1)} wrapped />
              <Tab label="COMMENTS" {...a11yProps(2)} wrapped/>
              < Tab label="COMPOSITES" {...a11yProps(3)} wrapped/>
              < Tab label="Contribution FACTORS" {...a11yProps(4)} wrapped />
            </Tabs>
          </Paper>
        <TabPanel value={value} index={0}>
            {(itemDetail && itemDetail.backboneIdentity) ? <DetailContainer /> : <div>No Records...</div>}            
        </TabPanel>
        <TabPanel value={value} index={1}>        
        {itemDetail && itemDetail.classificationPaths && !polymerFlag && <CompositeStructureComponent MRVchanged={mrv => console.log("Structure in mrv:", mrv)} searchIndex="yes" />}

        {itemDetail &&
          itemDetail.classificationPaths && polymerFlag && <PolymerStructureComponent MRVchanged={mrv => console.log("Structure in mrv:", mrv)} searchIndex="yes"/>}
          </TabPanel>
        <TabPanel value={value} index={2}>
          {itemDetail &&
            itemDetail.classificationPaths &&
            <CommentComponent />
          }
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Paper className={classes.paper} >
            {node && (itemDetail && itemDetail.backboneIdentity) && <DetailTitle name={itemDetail.backboneIdentity} type={detailData.type} subType={node.subType} status={node.status} isNameFlag={isNameFlag} tabName={'compositetab'} />}
            {itemDetail &&
              itemDetail.classificationPaths &&
              <React.Fragment>
                <PathDisplay paths={itemDetail.classificationPaths} />
              </React.Fragment>
            }
            {sirIdentity !== null && sirIdentity ?
                <React.Fragment>
                <CompositeTabContent data={compositeTabDetail} type={detailData.type} highligted={selectedItem} />
                </React.Fragment> : <div>No Records...</div>
              }
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Paper className={classes.paper} >
            {node && (itemDetail && itemDetail.backboneIdentity) && <DetailTitle name={itemDetail.backboneIdentity} type={detailData.type} subType={node.subType} status={node.status} tabName={'ContributionfactorTab'} />}
            {itemDetail &&
              itemDetail.classificationPaths &&
              <React.Fragment>
                <PathDisplay paths={itemDetail.classificationPaths} />
              </React.Fragment>
            }
            <React.Fragment>
              <MolarFractionsTabContent />
            </React.Fragment>
          </Paper>
        </TabPanel>
      </div> : <div>
        <Paper>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth" >
            <Tab label="CLASSIFICATION INFO" {...a11yProps(0)} wrapped />
            <Tab label="COMMENTS" {...a11yProps(1)} wrapped />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          {(itemDetail && itemDetail.backboneIdentity) ? <DetailContainer /> : <div>No Records...</div>}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {itemDetail &&
            itemDetail.backboneIdentity &&
            <CommentComponent />
          }
        </TabPanel>
      </div>
    );
}

export default TabForSubstance;
