import React, { ReactElement } from 'react';
import NotInterested from '@mui/icons-material/NotInterested';
import Search from '@mui/icons-material/Search';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Typography, Grid, Box, CircularProgress } from '@mui/material';

type Props = {
  icon: ReactElement;
  message: string;
  children?: any;
};

const SearchMessage = (props: Props) => (
  <Grid container direction="row" alignItems="center">
    <Grid item>
      {props.icon}
    </Grid>
    <Grid item>
      <Box fontStyle="italic">
        <Typography>
          {props.message}
        </Typography>
      </Box>
    </Grid>
    <Grid item>
      <p style={{ fontStyle: "italic", margin: "0px"}}> {props.children} </p>
    </Grid>
  </Grid>
  );

export const InsufficientSearchInput = () => (
  <SearchMessage message="Please enter more characters to start search..." icon={<NotInterested />} />
);

export const SearchInProgress = () => (
  <SearchMessage message="Searching..." icon={<Search />} ><CircularProgress /></SearchMessage>
);

export const NoItemsFound = () => (  
  < SearchMessage message = "No matches..." icon = {< ErrorOutline />} />
);

export const NoItemsFoundSub = () => (
  < SearchMessage message="No matches..." icon={< ErrorOutline />} children="SubComponent or Substance name (backbone name expected) may be incorrect OR Substance may exist without Mol. Weight. Please add substance or sub-component with Mol. Weight already defined." />
);

