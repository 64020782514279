import React, { useState, useCallback, useEffect } from 'react';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { createStyles, Theme } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Tooltip, Button, Grid } from '@mui/material';
import InformationIcon from "@mui/icons-material/Info";
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Suggestion } from '../store/search/types';
import { InsufficientSearchInput, SearchInProgress, NoItemsFound, NoItemsFoundSub } from './SearchMessages';
import { translateText, CharacterTranslation, defaultTranslations } from '../CommonComponents/textTranslation';
import { makeStyles } from '@mui/styles';
import { customSpacing } from '../theme/SIRtheme';

type RenderInputProps = TextFieldProps & {
  classes: ReturnType<typeof useStyles>;
  ref?: React.Ref<HTMLDivElement>;
};

function renderInput(inputProps: RenderInputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const SearchLabel = (props: { tooltipStyle: string }) => {
  const { tooltipStyle } = props;
  return (
    <Tooltip classes={{ tooltip: tooltipStyle }}
      title="By default, we'll search for your search term anywhere within the name. You can also use your own wildcards, using 1 or more *s. For example *hydroxymethyl*pyran*"
    >
      <span>Search<InformationIcon fontSize="inherit" /></span>
    </Tooltip>
  );
};

interface RenderSuggestionProps {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<'div', { button?: never }>;
  selectedItem: Suggestion;
  suggestion: Suggestion;
  classes: ReturnType<typeof useStyles>;
}

function renderSuggestion(suggestionProps: RenderSuggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem, classes } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem ? selectedItem.name === suggestion.name : false);
  const searchType = localStorage.getItem('searchType');
  return (
    <MenuItem className={classes.menuItem}
      {...itemProps}
      key={`${suggestion.type}/${suggestion.name}/${suggestion.subType}`}
      selected={isHighlighted}
      component="div"
      title={`${suggestion.name}\n${suggestion.subType || suggestion.type}\n${suggestion.substanceId}`}
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <span className={searchType === 'subcomponent' ? classes.nameColumnSub : classes.nameColumn}>{suggestion.name}</span>
      <span className={searchType === 'subcomponent' ? classes.typeColumnSub : classes.typeColumn}>{suggestion.subType || suggestion.type}</span>
      <span className={searchType === 'subcomponent' ? classes.substanceColumnSub : classes.substanceColumn}>{suggestion.substanceId}</span>
    </MenuItem>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1 + ' !important',
    },
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    paper: {
      position: 'absolute',
      zIndex: 999,
      //marginTop: customSpacing(1),
      left: '0px',
      right: '0px',
      textAlign: "left",
      backgroundColor: "ghostwhite !important",
      overflow: "auto !important",
      maxHeight: "80vh !important"
    },
    paperSub: {
      position: 'absolute',
      zIndex: 999,
      //marginTop: customSpacing(1),
      left: '0px',
      right: '0px',
      textAlign: "left",
      backgroundColor: "ghostwhite !important",
      overflow: "auto !important",
      maxHeight: "200px !important"
    },
    chip: {
      // margin: customSpacing(0.5, 0.25),
      margin: customSpacing(0.5, false) + ' !important',
    },
    inputRoot: {
      flexWrap: 'wrap',
    },
    inputInput: {
      width: 'auto !important',
      flexGrow: 1 + ' !important',
    },
    divider: {
      height: customSpacing(2, false) + ' !important',
    },
    menuItem: {
      fontSize: "0.9rem !important",
      minHeight: "10px !important",
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
      zIndex: 2000 + ' !important'
    },
    nameColumn: {
      width: "45% !important",
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      marginRight: "15px !important",

    },
    nameColumnSub: {
      width: "55% !important",
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      marginRight: "15px !important"
    },
    typeColumn: {
      width: "20% !important",
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      paddingRight: "5px !important",
    },
    typeColumnSub: {
      width: "10% !important",
      //overflow: "hidden",
      //whiteSpace: "nowrap",
      //textOverflow: "ellipsis",
      //paddingRight: "5px",
    },
    substanceColumn: {
      width: "35% !important",
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      textAlign: "right"
    },
    substanceColumnSub: {
      width: "35% !important",
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      textAlign: "right"
    },
    tooltip: {
      fontSize: "0.85rem !important"
    }
  }),
);

interface SirSearchProps {
  loading: boolean;
  notEnoughCharacters: boolean;
  matches: Suggestion[];
  valueChanged(inputValue: string, state: ControllerStateAndHelpers<Suggestion>): void;
  fetchAll(p: any): any;
  itemSelected: (item: Suggestion) => void;
  value: string;
  hasMore: boolean;
  filter: string | undefined;
}

export const SirSearch = (props: SirSearchProps) => {
  const classes = useStyles();
  const translations: CharacterTranslation[] = defaultTranslations;
  const { value: propsValue, valueChanged, filter } = props;
  const [value, setValue] = useState(propsValue || "");
  const onValueChanged = useCallback((inputValue: string, state: ControllerStateAndHelpers<Suggestion>) => {
    const translation = translateText(inputValue, translations);
    setValue(translation);
    valueChanged(translation, state);
  }, [valueChanged]);

  return (
    <div className={classes.root}>
      <Downshift id="downshift-simple" inputValue={value} onInputValueChange={onValueChanged}
        onChange={props.itemSelected} itemToString={(i: Suggestion) =>
        {
          if (i && filter && filter === 'Substance,Substance name') {
            return String(i.substanceId)
          } else {
            return i ? i.name : ''
          }
        }} >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Type a few letters of the thing you are searching for...',
          });
          const searchType = localStorage.getItem('searchType');
          return (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                label: <SearchLabel tooltipStyle={classes.tooltip} />,
                InputLabelProps: getLabelProps({ shrink: true } as any),
                InputProps: { onBlur, onFocus },
                inputProps,
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={searchType === 'subcomponent' ? classes.paperSub : classes.paper} square>
                    {props.notEnoughCharacters && <InsufficientSearchInput />}
                    {props.loading && <SearchInProgress />}
                    {(props.matches.length === 0 && !props.loading && !props.notEnoughCharacters && searchType === 'subcomponent') && <NoItemsFoundSub />}
                    {(props.matches.length === 0 && !props.loading && !props.notEnoughCharacters && searchType === null) && <NoItemsFound />}

                    {props.matches.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                        classes
                      }),
                    )}
                    {props.hasMore && <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Button disabled={props.loading} onClick={() => props.fetchAll(inputValue)}>More...</Button>
                      </Grid>
                      <Grid item>
                        {props.loading && <SearchInProgress />}
                      </Grid>
                    </Grid>
                    }
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}

export default SirSearch;
