import { Button } from '@mui/material';
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store'; 
 
const useStyles = makeStyles(theme => ({
    button: {
    },
    icon: {
      width: '16px !important',
      height: '16px !important'
    },
  }));
   

const ViewButton = (props: any) => {
   const classes=useStyles();
   const dispatch = useDispatch();
  
    return (
      <span>
        <Button size="small" variant="text" className={classes.button} title="View">
          <RemoveRedEye className={classes.icon} />
        </Button>
      </span>
    )
  }
  
export default ViewButton;
