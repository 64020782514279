import React from 'react';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import { MenuItem as LookupItem } from '../store/lookupData/types';
import ItemTypeSelector from './ItemTypeSelector';
import ValidationError from './ValidationError';
import { useSelector } from 'react-redux';
import { AppState } from '../store';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px !important"
  },
  flex: {
    display: 'flex !important'
  },
  label: {
    color: 'grey !important',
    paddingRight: '20px !important',
    paddingTop: '8px !important',
    paddingBottom: '15px !important'
  },
  disableDD: {
    pointerEvents: "none"
  }
});

interface NameFormPanelProps {
  availableTypes: LookupItem[];
  availableStatuses: LookupItem[];
  propertyPrefix: string;
  type: string;
  subType: string;
  status: any;
  typeSelected: any;
  parentType?: any;
}

const fieldName = (prefix: string, name: string) => {
  return `${prefix}${name}`;
};

const NameFormPanel = (props: NameFormPanelProps) => {
  const classes = useStyles();
  const { availableTypes, availableStatuses, propertyPrefix, type, subType } = props;
  let compositeTypesData: LookupItem[] = [];
  localStorage.setItem('TargetType', type);
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  let enableBtn: boolean = false;

  if (selectedItem) {
    if ((selectedItem.type === 'Composite' || selectedItem.type === 'Composite name' || selectedItem.type === '') && type === 'Substance name' || type === 'Composite substance') {
      if ((props.parentType === 'Substance name' || props.parentType === 'Substance') && type === 'Substance name') {
        enableBtn = false;
      }
      else if ((props.parentType === 'Composite' || props.parentType === 'Composite name') && type === 'Substance name' || type === 'Composite substance') {
        enableBtn = true;
      }
    }
    else if ((selectedItem.type === 'Substance' || selectedItem.type === 'Substance name' || selectedItem.type === '') && type === 'Substance name') {
      if ((props.parentType === 'Composite' || props.parentType === 'Composite name') && type === 'Substance name') {
        enableBtn = true;
      }
    }
    else if ((selectedItem.type === 'Substance' || selectedItem.type === 'Substance name' || selectedItem.type === '') && type === 'Classification name') {
      if ((props.parentType === 'Composite' || props.parentType === 'Composite name') && type === 'Classification name') {
        enableBtn = true;
      }
    }
    else if ((selectedItem.type === 'Substance' || selectedItem.type === 'Substance name' || selectedItem.type === '') && type === 'Substance') {
      if ((props.parentType === 'Composite' || props.parentType === 'Composite name' || props.parentType === 'Substance' || props.parentType === 'Substance name') && type === 'Substance') {
        enableBtn = false;
      }
    }
    else if ((selectedItem.type === 'Composite' || selectedItem.type === 'Composite name' || selectedItem.type === '') && type === 'Classification name') {
      if ((props.parentType === 'Composite' || props.parentType === 'Composite name') && type === 'Classification name') {
        enableBtn = true;
      }
      else {
        enableBtn = false;
      }
    }
    else if ((selectedItem.type === 'Composite' || selectedItem.type === 'Composite name' || selectedItem.type === '') && type === 'Substance') {
      if ((props.parentType === 'Composite' || props.parentType === 'Composite name' || props.parentType === 'Substance' || props.parentType === 'Substance name') && type === 'Substance') {
        enableBtn = false;
      }
      /*else {
        enableBtn = false;
      }*/
    }
  }
  else if (type === 'Composite substance' || (type === 'Substance name' && props.parentType === 'Composite') || (type === 'Substance name' && props.parentType === 'Composite name') || (type === 'Classification name' && props.parentType === 'Composite') || (type === 'Classification name' && props.parentType === 'Composite name')) {
     enableBtn = true;
  }
  else {
     enableBtn = false;
  }
  const enableMolWeight = type === 'Sub component' ? true : false;
  console.log('selectedItem ==', selectedItem, type)
  const statusSelected = (e: any) => {
    props.status(e);
  }
  const typeSelected = (item: any) => {
    props.typeSelected(item.display);
  }
  //console.log('availableTypes ==',availableTypes)
  let availableTypesNew = JSON.parse(JSON.stringify(availableTypes));

  availableTypesNew = availableTypesNew.filter((i: any) => {
    if (i.display != "Sub Component" && i.display != "Component" && i.display != "CLS ID" && i.display != 'PLM ING' && i.display != 'Trade Name') {
      return true
    }
    else {

      return false
    }
  })

  // if(type==='Substance name'){
  //   console.log('HERE ADDING')
  //   availableTypesNew.push({
  //     key: 'PLM ING',
  //     display: 'PLM ING'
  //   });
  //   availableTypesNew.push({
  //     key: 'Trade Name',
  //     display: 'Trade Name'
  //   });
  // }


  if ((type === 'Substance name' && props.parentType === 'Composite') ||
    (type === 'Substance name' && props.parentType === 'Composite name') ||
    (type === 'Substance name' && props.parentType != 'Substance') ||
    (type === 'Substance name' && props.parentType != 'Substance name') ||
    (type === 'Classification name' && props.parentType === 'Composite') ||
    (type === 'Classification name' && props.parentType === 'Composite name') ||
    ((selectedItem && (selectedItem.type === 'Composite' ||
      selectedItem.type === 'Composite name')) ||
      (type === 'Composite substance'))) {
    compositeTypesData.push({
      key: 'PLM ING',
      display: 'PLM ING'
    });
    compositeTypesData.push({
      key: 'Synonym',
      display: 'Synonym'
    });
    compositeTypesData.push({
      key: 'Trade Name',
      display: 'Trade Name'
    });
    compositeTypesData.push({
      key: 'PLM Pure',
      display: 'PLM Pure'
    });
  }

  console.log('enableMolWeight =', enableMolWeight)
  console.log('compositeTypesData =', compositeTypesData)
  console.log('availableTypesNew =', availableTypesNew)
  //console.log('enableBtn =', enableBtn)
  console.log('selectedItem =', selectedItem)
  console.log('type =', type)
  return (
    <React.Fragment>
      <ItemTypeSelector />

      {enableBtn ? <InputLabel>Trade Name/ Unique Name</InputLabel> : <InputLabel>Name</InputLabel>}
      <EnhancedTextInputField name={fieldName(propertyPrefix, "name")} fullWidth margin="dense" className={classes.field} />
      <ValidationError name={fieldName(propertyPrefix, "name")} />
      {!enableMolWeight ? < InputLabel > Type</InputLabel> : null}

       {!enableMolWeight ? <div>
        <Field name={fieldName(propertyPrefix, "subType")}>
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            value={subType ? subType : ""}
            onChange={(e) => {
              form.setFieldValue(fieldName(propertyPrefix, "subType"), e.target.value);
              const selectedItem = enableBtn ? compositeTypesData.find(item => item.key === e.target.value) : availableTypesNew.find(item => item.key === e.target.value);
              if (selectedItem) {
                typeSelected(selectedItem); 
              }
            }}
        >
      {enableBtn ? compositeTypesData.map((item) =>
                <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
              ) :
          availableTypesNew.map((item: any) =>
            <MenuItem key={item.key} value={item.key}>{item.display}
            </MenuItem>)}
        </Select>
        )}
</Field>
<ValidationError name={fieldName(propertyPrefix, "subType")} /></div> : null}

      <InputLabel>Status</InputLabel>
      <Field name={fieldName(propertyPrefix, "status")}>
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
            fullWidth
            defaultValue={localStorage.getItem('status')}
            value={form.values[fieldName(propertyPrefix, "status")]}
            onChange={(e) => {
              form.setFieldValue(fieldName(propertyPrefix, "status"), e.target.value);
              const selectedItem = availableStatuses.find(item => item.key === e.target.value) ;
              if (selectedItem) {
                statusSelected(selectedItem); 
              }
            }}
        >
      {availableStatuses.map((item) =>
          <MenuItem key={item.key} value={item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
</Field>
      <ValidationError name={fieldName(propertyPrefix, "status")} />
    </React.Fragment>
  )
}

export default NameFormPanel;
