import * as React from "react";
import { Paper, Button, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import PathDisplay from '../DetailPanel/PathDisplay';
import DetailTitle from '../DetailPanel/DetailTitle';
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../store";
import { deleteStructureData, saveStructureData, editStructureData, addStructureData, editStructureRestrictionData } from "../store/hierarchy/actions";
import { Select, MenuItem, TextField, Grid, FormControl, Chip } from '@mui/material';
import { makeError, makeSuccessMessage } from '../store/error/actions';
import { customSpacing } from "../theme/SIRtheme";

//import { showDetail } from "../store/itemDetail/actions";
//import { getImageFromSmiles } from "../store/searchStructure/actions";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  MRVchanged?: (mrv: string) => void;
  mrv?: string | null;
  smilesfromsearch?: string;
  searchIndex?: string;
  getImage?: any | null;
}
const useStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: customSpacing(1, false) + ' !important',
    minWidth: '120px !important',
  },
  selectEmpty: {
    marginTop: "12px !important",
    width: '250px !important',
    padding: "0px !important"
  },
  selectEmpty1: {
    marginTop: "2px !important",
    width:'175px !important',
  },
  mainDiv: {
    flexDirection: "column",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    display: "flex !important",
    borderStyle: "solid !important",
    height: '150px !important',
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  mainDiv2: {
    //flexDirection: "column",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    display: "flex !important",
    borderStyle: "solid !important",
    //height: 150,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  pad: {
    paddingTop: "25px !important",
    paddingLeft: "15px !important",
    //paddingRight: "20px",
    //paddingBottom: "15px",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  padForMol: {
    paddingTop: "15px !important",
    paddingLeft: "15px !important",
    paddingRight: "20px !important",
    paddingBottom: "15px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  padTop: {
    paddingTop: "10px !important"
  },
  padEmail: {
    paddingLeft: "15px !important",
    //paddingTop: "25px",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  flex: {
    display: "flex !important"
  },
  textStrike: {
    // marginTop: "10px",
    width: '600px !important',
    paddingLeft: "15px !important"
  },
  para: {
    margin: '0px !important',
    color: 'red !important',
    paddingLeft: "15px !important"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '2px !important',
    height: '26px !important'
  },
  marLeft: {
    marginBottom: "10px !important",
  },
  paper: {
    padding: "5px !important",
    height: "100% !important"
  },
  textarea: {
    width: "81% !important",
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    borderStyle: "solid !important",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  error: {
    color: "red !important",
    marginTop: "0px !important",
    marginBottom: "0px !important",
  },
  parent: {
    display: "flex !important",
  },
  child1: {
    position: "relative",
    top: "29px !important",
    marginLeft: "12px !important"
  },
  audit: {
    paddingBottom: "5px !important",
  },
  parentheight: {
    height: "1040px !important",
    overflow: "auto",
    overflowX: "hidden"
  },
  hideSection: {
    display: "none !important",
  },
  showSection: {
    display: "block !important",
  },
  iframe: {
      minHeight: '454px !important'
  },
    tooltipText: {
        fontSize: '14px !important',
        padding: '5px !important',
        margin: '5px !important',
        backgroundColor: "white !important",
        border: "1px solid black !important",
        color: "black !important",
    },
    domainLock: {
        fontSize: '24 !important',
        color: "red !important",
        fontWeight: "bold !important",
        marginTop: '5px !important',
        marginRight: "5% !important",
    }
}));
interface MarvinInstance {
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: (() => void) | null) => void;
  exportStructure: (format: string, settings?: any) => Promise<string>;
  importStructure: (format: string, data: any) => void;
}
export default function CompositeStructureComponent(props: Props) {
  const [marvin, setMarvin] = React.useState<MarvinInstance>();
  const [dataSmile, setDataSmile] = React.useState<any>();

  const [enableValidateBtn, setEnableValidateBtn] = React.useState(true);
  const [enableSaveBtn, setEnableSaveBtn] = React.useState(true);
  const [showErrorMsg, setShowErrorMsg] = React.useState<any>(false);
  const [ifDropDownSelected, setIfDropDownSelected] = React.useState(false);
  const [ifEmailEntered, setIfEmailEntered] = React.useState(false);
  const [restrictedToError, setRestrictedToError] = React.useState(false);
  const [auditDescription, setAuditDescription] = React.useState("");
  const [DisableRestrictPanel, setDisableRestrictPanel] = React.useState(false);
  const [deleteStructureVal, setDeleteStructureVal] = React.useState(false);

  const classes = useStyles();
  const detailData = useSelector((state: AppState) => state.itemDetail);
  const node = detailData.selectedNode;
  const itemDetail = detailData.itemDetail;
  const licenseData = useSelector((state: AppState) => state.lookup.license);
  const checkerFixerData = useSelector((state: AppState) => state.hierarchy.structrueData);
  const structureDataList = useSelector((state: AppState) => state.hierarchy.getStructureList);
  const getRestrictionData = useSelector((state: AppState) => state.editItem.structurerestrictionData);
  const nameFlag = useSelector((state: any) => state.flagReducer);
  const isNameFlag = nameFlag.flagDetails;
  // const [Domains, setDomains] = React.useState([]);
  const [Domains, setDomains] = React.useState(getRestrictionData && getRestrictionData.data.groupName.length > 0 ? getRestrictionData.data.groupName : []);
  const [verificationStatus, setVerificationStatus] = React.useState(structureDataList && structureDataList.data.verification !== null ? structureDataList.data.verification.toUpperCase() : '');
  //const [Domains, setDomains] = React.useState(getRestrictionData.data.groupName.length > 0? getRestrictionData.data.groupName : []);
  const [molFormula, setMolFormula] = React.useState(structureDataList ? structureDataList.data.molecularFormula : '');
  const [emailEdit, setEmailEdit] = React.useState((getRestrictionData && getRestrictionData.data.email) ? getRestrictionData.data.email.join(';') : null);
  const user = useSelector((state: AppState) => state.user);
  console.log('user.userDomain =', user, user.userDomain);
  
  const isLockIconRestriction = (structureDataList &&  structureDataList.data.isLockIconRestricted) ? structureDataList.data.isLockIconRestricted : null;
  let u;
  if (user && user.userDomain.length) {
    u = [...user.userDomain];
    //u.shift(); // removing 1st value // commenting this line, since the order from diagnotics api has changed
  }

  //const [userDomain, setUserDomain] = React.useState(user && user.userDomain.length > 0 ? user.userDomain[1] : []);

  console.log('u ==', u)

  const [userDomain, setUserDomain] = React.useState(u && u.length > 0 ? u : []);
  console.log('userDomain ==', userDomain)
  const [userName, setUserName] = React.useState(user && user.userName ? user.userName : '');
  const [disableHistory, setDisableHistory] = React.useState(true);
  React.useEffect(() => {
    if (getRestrictionData && getRestrictionData.data.groupName.length > 0) {
      for (let i = 0; i < getRestrictionData.data.groupName.length; i++) {
        if (userDomain.includes(getRestrictionData.data.groupName[i])) {
          //if (getRestrictionData.data.groupName[i] === userDomain) {
          setDisableHistory(false);
          break;
        }
        else {
          setDisableHistory(true);
        }
      }
    }
    else if (getRestrictionData && getRestrictionData.data.email.length > 0) {
      for (let i = 0; i < getRestrictionData.data.email.length; i++) {
        if (getRestrictionData.data.email[i].toLowerCase() === userName.toLowerCase()) {
          setDisableHistory(false);
          break;
        }
        else {
          setDisableHistory(true);
        }
      }
    }
    else {
      setDisableHistory(false);
    }
  });
  const DomainList = [
    'BW',
    'HC',
    'PC',
    'PLM',
    'Regulatory',
    'Safety',
  ];
  const verificationStatusList = [
    'VERIFIED',
    'UNVERIFIED'
  ]
  const handleVerificationStatus = (event: any) => {
    setVerificationStatus(event.target.value);
  }
  const handleChange = (event: any) => {
    setDomains(event.target.value);
    //setDomains('Regulatory');
    if (event.target.value.length > 0) {
      setIfDropDownSelected(true);
    } else {
      setIfDropDownSelected(false);
    }
    localStorage.setItem('structurerestricteddomains', JSON.stringify(event.target.value));

  };
  const handleRestrictedToChange = (event: any) => {
    if (event.target.value === '') {
      setIfEmailEntered(false);
      setRestrictedToError(false);
    } else {
      setIfEmailEntered(true);
      setRestrictedToError(true);
    }
    var emails = event.target.value.split(";");
    let invalidEmails = [];
    for (let i = 0; i < emails.length; i++) {
      if (!validateMultipleEmails(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      }
    }
    if (invalidEmails.length > 0 && event.target.value !== '') {
      setRestrictedToError(true);
    } else {
      localStorage.setItem('structurerestrictedemails', JSON.stringify(emails));
      setRestrictedToError(false);
    }
  }
  const validateMultipleEmails = (e: string) => {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(e);
  };
  //NOSONAR
  async function setupSketcher() {
    try {
      //@ts-ignore
      const marvinNameSpace = await MarvinJSUtil.getPackage("#sketch");
      const m = marvinNameSpace.sketcherInstance as MarvinInstance;
      marvinNameSpace.Sketch.license("data:text/xml," + licenseData);
      setMarvin(m);
      // initControl();
    } catch (e) {
      console.warn("Couldn't load", e);
    }
  }

  React.useEffect(() => {
    setupSketcher();
  }, []);
  React.useEffect(() => {
    if (!marvin) {
      return;
    }
    if (props.searchIndex === "true") {
      // marvin.importStructure('smiles', (props.smilesfromsearch as any));
    } else {
      if (structureDataList !== undefined) {
        localStorage.setItem('marvinSmiles', structureDataList.data.molecule);
        localStorage.setItem('molcontent', structureDataList.data.molContent);
        setDataSmile(structureDataList.data.molecule);
        /*if (structureDataList.data.molContent === null || structureDataList.data.verification.toUpperCase() === 'UNVERIFIED') {
          //marvin.importStructure('smiles', structureDataList.data.molecule);
          marvin.importStructure('mol', structureDataList.data.molContent);
        }
        else {
          marvin.importStructure('mol', structureDataList.data.molContent);
        }*/
        //As per SonarQube For below both condition import the same structure
        marvin.importStructure('mol', structureDataList.data.molContent);
        setEnableSaveBtn(false);
        const restrictData = getRestrictionData ? getRestrictionData.data : '';
        if (restrictData.email.length > 0 || restrictData.groupName.length > 0) {
          setEnableValidateBtn(true);
        } else {
          setEnableValidateBtn(false);
        }
        if (restrictData.email.length > 0 || restrictData.groupName.length > 0) {
          if (structureDataList.data.molecule === null) {
            setDisableRestrictPanel(true);
          }
          else {
            setDisableRestrictPanel(false);
          }
        } else {
          setDisableRestrictPanel(false);
        }
      } else {
        setupSketcher();
        localStorage.removeItem('marvinSmiles');
        marvin.importStructure('smiles', "");
      }
    }
    // marvin.off("molchange", null);

    marvin.off("molchange", null);
    if (props.searchIndex === "true") {
      marvin.on("molchange", () => {
        marvin.exportStructure("mol").then(
          (mrv) => {
            props.getImage(mrv);
            // dispatch(getImageFromSmiles(null))
          },
          (error) => console.error("Mol export failed:" + error)
        );
      }
      );
    }
    if (props.searchIndex === "yes") {
      marvin.on("molchange", () => {
        marvin.exportStructure("smiles").then(
          (smile) => {
            setDataSmile(smile);
            const checkSmiles = localStorage.getItem('marvinSmiles');
            if (structureDataList && structureDataList.data.molecule != null && checkSmiles != null && smile == '') {
              //setEnableValidateBtn(true);
              setEnableSaveBtn(false);
              localStorage.setItem('marvinSmiles', smile);
              setDeleteStructureVal(true);
              setEnableValidateBtn(true);
            } else {
              setEnableValidateBtn(false);
              setDeleteStructureVal(false)
              localStorage.setItem('marvinSmiles', smile);
              //localStorage.setItem('molcontent', smile)
              submitMarvin();
            }
          },
          (error) => console.error("MRV export failed:" + error)
        );
        marvin.exportStructure("mol").then(
          (smile) => {
            //setDataSmile(smile);
            //setEnableValidateBtn(false);
            //localStorage.setItem('marvinSmiles', smile);
            //TODO-Code is for checking for retreving DP values from SDF format 
            //const locdpvalue = smile;
            //const dpFirstindexvalue = locdpvalue.lastIndexOf("SMT");
            //const dpLastindexvalue = locdpvalue.lastIndexOf("END");
            //const startvalue = dpFirstindexvalue + 8;
            //const endvalue = dpFirstindexvalue + 10;
            //const dpvalue = locdpvalue.substring(startvalue, endvalue);
            //const dpvalue1 = locdpvalue.substring(dpFirstindexvalue,619);
            localStorage.setItem('molcontent', smile)
            submitMarvin();
          },
          (error) => console.error("MRV export failed:" + error)
        );
      });
    }
    if (props.smilesfromsearch === 'true' || props.smilesfromsearch === 'false') {
      marvin.importStructure('smiles', null);
    }

  }, [props.MRVchanged, marvin, props.smilesfromsearch]);

  const dispatch = useDispatch();
  const submitMarvin = () => {
    setEnableValidateBtn(true);
    // setEnableSaveBtn(true);
    const data = localStorage.getItem('marvinSmiles');
    if (data !== null && data !== "") {
      dispatch(saveStructureData());
      setEnableSaveBtn(false);
      setEnableValidateBtn(false);
    } else {
      setEnableValidateBtn(true);
      setEnableSaveBtn(true);
      setShowErrorMsg(false);
    }

  }
  const [errorData, setErrorData] = React.useState([]);
  let v1: any = [];
  const validateResponse = () => {
    setTimeout(() => {
      if (checkerFixerData !== null) {
        localStorage.setItem('marvinSmiles', checkerFixerData.data.result);
        if (checkerFixerData.data.steps !== null) {
          for (let i = 0; i < checkerFixerData.data.steps.length; i++) {
            if (checkerFixerData.data.steps[i].description !== null) {
              let d: any = checkerFixerData.data.steps[i].description;
              setShowErrorMsg(true);
              v1.push(d);
              dispatch(makeError("Invalid structure. Please review the sketch drawn."));
            }
          }
        }
        setErrorData(v1);
        if (v1.length === 0) {
          setShowErrorMsg(false);
          dispatch(makeSuccessMessage("Structure is valid as per Checker Fixer Validation."));
        }
        setEnableValidateBtn(true);
        setEnableSaveBtn(false);
        if (marvin) {
          setEnableValidateBtn(true);
          //Issue fixed for export mol Code change from Smiles to SDF format 
          marvin.importStructure("sdf", checkerFixerData.data.result);
        }
      }
    }, 1000);
  }
  //END-NOSONAR
  const saveStructure = () => {
    if (deleteStructureVal === true) {
      deleteStructure();
    } else {
      const value = localStorage.getItem('marvinSmiles');
      if (structureDataList && (structureDataList.data.molecule !== undefined)) {
        var obj: any = {
          name: structureDataList.data.backboneIdentity,
          molecule: localStorage.getItem('marvinSmiles'),
          status: structureDataList.data.status,
          auditDescription: auditDescription,
          molecularFormula: molFormula,
          molContent: localStorage.getItem('molcontent'),
          verification: verificationStatus,
        }
        //if (structureDataList.data.molecule === value) {
        //localStorage.setItem('restrictionOnly', 'true');
        //setTimeout(function () {
        //  dispatch(editStructureRestrictionData(obj));
        //}, 1000);
        //dispatch(editStructureData(obj));
        //dispatch<any>(showDetail({ name: obj.name, type: 'Substance', subType: 'Substance', status: obj.status }));
        //} else {
        //dispatch(editStructureData(obj));
        //dispatch(editStructureRestrictionData(obj));
        //}
        dispatch(editStructureData(obj));
        //dispatch(editStructureRestrictionData(obj));
      } else {
        var addObj: any = {
          name: itemDetail.backboneIdentity,
          molecule: localStorage.getItem('marvinSmiles'),
          status: "A",
          auditDescription: auditDescription,
          molecularFormula: molFormula,
          molContent: localStorage.getItem('molcontent'),
          verification: verificationStatus,
        }
        dispatch(addStructureData(addObj));
      }
    }
    // setEnableValidateBtn(true);
    // setEnableSaveBtn(true);
  }
  const deleteStructure = () => {
    var delObj: any = {
      name: structureDataList.data.backboneIdentity,
      molecule: localStorage.getItem('marvinSmiles'),
      status: structureDataList.data.status,
      auditDescription: auditDescription,
      molecularFormula: molFormula,
      molContent: localStorage.getItem('molcontent'),
      verification: verificationStatus,
    }

    dispatch(deleteStructureData(delObj));
    setTimeout(() => {
      setEnableValidateBtn(true);
      setEnableSaveBtn(true);
      // setEnableSaveBtn(false);
    }, 1000)
  }
  const inputSmiles = (event: any) => {
    setEnableValidateBtn(true);
    localStorage.setItem('marvinSmiles', event.target.value);
    if (marvin) {
      marvin.importStructure('smiles', event.target.value);
    }
  }
  const handleAudit = (val: any) => {
    setAuditDescription(val.target.value);
  }
  return (
    <Paper className={props.searchIndex !== "true" ? classes.parentheight : ""}>
      {node && props.searchIndex !== "true" && <DetailTitle name={itemDetail.backboneIdentity} type={detailData.type} subType={node.subType} status={node.status} isNameFlag={isNameFlag} disableHistory={disableHistory} tabName={'structuretab'} />}
      {itemDetail &&
        itemDetail.classificationPaths && props.searchIndex !== "true" &&
        <React.Fragment>
          <PathDisplay paths={itemDetail.classificationPaths} />
        </React.Fragment>
      }
      {props.searchIndex !== "true" ? <div className={!user.isAdmin ? classes.hideSection : classes.showSection}>
        <Grid className={classes.mainDiv} sx={{ flexDirection: "column" }}>
          <Grid item lg={12} className={classes.flex} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classes.pad} >
                Restricted Domains:
              </div>
              <span data-testid="domain-select">
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={Domains}
                    onChange={handleChange}
                    className={classes.selectEmpty}
                    disabled={(emailEdit == null || emailEdit == "" ? ifEmailEntered : true) || DisableRestrictPanel} //#DisableRestrictPanel
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((value: any) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {DomainList.map((domain) => (
                      <MenuItem key={domain} value={domain} >
                        {domain}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </span>
            </div>
            {/* Lock Icon With Toltip */}
            {isLockIconRestriction ? (
              <Tooltip title="Restricted Substance" classes={{tooltip :classes.tooltipText }}>
              <LockIcon className={classes.domainLock} />
            </Tooltip>
            ) : null} 
          </Grid>
          <Grid item lg={12} >
            <div className={classes.padEmail} >
              Named list of users:
            </div>
            <div>{console.log("ifDropDownSelected -- " + ifDropDownSelected + " -- DisableRestrictPanel -- " + DisableRestrictPanel + " -- ifEmailEntered -- " + ifEmailEntered)}
              <TextField
                type="text"
                placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                disabled={(Domains.length != 0 ? true : ifDropDownSelected) || DisableRestrictPanel}
                className={classes.textStrike}
                onBlur={e => handleRestrictedToChange(e)}
                onChange={e => { setEmailEdit(e.target.value) }}
                value={emailEdit}
              //label="Email Id" variant="outlined"
              />
              {restrictedToError ? <p className={classes.para} >One or More Email Addresses are Invalid</p> :
                null}
            </div>
          </Grid>
        </Grid>
      </div>
        : null}
      {props.searchIndex !== "true" ? <div>
        <Grid className={classes.mainDiv2} >
          <Grid item lg={6} className={classes.parent} >
            <div className={classes.padForMol}>Molecular Formula:</div>
            <div className={classes.padTop} data-testid="molFormula-select">
              <TextField
                type="text"
                //placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                //disabled={ifDropDownSelected || DisableRestrictPanel}
                //className={classes.textStrike}
                //onBlur={e => handleRestrictedToChange(e)}
                onChange={e => { setMolFormula(e.target.value) }}
                value={molFormula}
              //label="Email Id" variant="outlined"
              />
            </div>
          </Grid>
          <Grid item lg={6} className={classes.flex}>
            <div className={classes.padForMol} >
              Verification Status:
            </div>
            <div data-testid="verification-status-select">
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  //multiple
                  value={verificationStatus}
                  onChange={handleVerificationStatus}
                  className={classes.selectEmpty1}
                //disabled={ifEmailEntered || DisableRestrictPanel}
                //renderValue={(selected: any) => (
                //  <div className={classes.chips}>
                //    {selected.map((value: any) => (
                //      <Chip key={value} label={value} className={classes.chip} />
                //    ))}
                //  </div>
                //)}
                >
                  {verificationStatusList && verificationStatusList.map((verificationStatus) => (
                    <MenuItem key={verificationStatus} value={verificationStatus}>
                      {verificationStatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </div> : null}
      {props.searchIndex !== "true" ? <Grid item lg={12} className={!user.isAdmin ? classes.hideSection : classes.showSection}>
        <div className={classes.padEmail} >
          Audit description:
        </div>
        <div className={classes.audit}>
          <TextField
            type="text"
            placeholder="Enter the audit description"
            className={classes.textStrike}
            onBlur={e => handleAudit(e)}
          />
        </div>
      </Grid> : null}
      <div id={"placeHolderDiv"}>
      <iframe className={props.searchIndex === "true" ? [classes.iframe, "iframe"].join(" ") : "iframe"} title={`MarvinJS`} id="sketch" data-toolbars="reporting" sandbox="allow-same-origin allow-scripts allow-forms" src="/marvinjs/editorws.html" />
      </div>
      {props.searchIndex !== "true" ?
        user.isAdmin ? <div className={classes.parent}>
          <textarea name="message" id="structure" value={dataSmile} className={classes.textarea} rows={7} cols={89.5} placeholder="Type here..." onChange={e => { setDataSmile(e.target.value); inputSmiles(e) }} />
          <div className={classes.child1}>
            <Button type="button" variant="contained" name="Validate" color="primary" onClick={validateResponse} className={classes.marLeft} disabled={enableValidateBtn}>
              Validate
            </Button> <br />
            <Button type="button" variant="contained" name="Save" color="primary" onClick={saveStructure} disabled={enableSaveBtn || DisableRestrictPanel}>
              Save
            </Button>
          </div>

        </div> : <div className={classes.parent}>
          <textarea name="message" id="structure" value={dataSmile} className={classes.textarea} rows={7} cols={89.5} placeholder="Type here..." onChange={e => { setDataSmile(e.target.value); inputSmiles(e) }} disabled={!user.isAdmin} />
          <div className={classes.child1}>
            <Button type="button" variant="contained" name="Validate" color="primary" onClick={validateResponse} className={classes.marLeft} disabled={!user.isAdmin}>
              Validate
            </Button> <br />
            <Button type="button" variant="contained" name="Save" color="primary" onClick={saveStructure} disabled={!user.isAdmin}>
              Save
            </Button>
          </div>

        </div>
        : null}
      {(showErrorMsg && props.searchIndex !== "true") ? <div>Error Description: {errorData.map((error: any, index: any) => (
        <p className={classes.error} key={index}>
          {error}
        </p>
      ))} </div> : null}
    </Paper>
  );
}
