import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useButtonStyles } from '../MainPage/Styles';
import { AppState } from '../store';
import { LookupState } from '../store/lookupData/types';
import { UserState } from '../store/user/types';
import { AddLinkFormValues, AddLinkFormConfig } from '../store/entity/types';
import { lookupStatus, lookupClassificationType, lookupNameType, lookupDomain } from '../store/lookupData/actions';
import { AddLink, RemoveLink, SwapSynonym } from '../store/entity/actions';
import AddDialog from '../AddDialog/AddDialog';
import AddNewItemButton from './AddNewItemButton';
import AddNewItemDialog from './AddNewItemDialog';
import MergeItemsButton from '../MergeItems/MergeItemsButton';
import MergeItemsDialog from '../MergeItems/MergeDialog';
import SwapSynonymSubstanceForm from './swapsynonymSubstance';
import AddNewBtnCFactor from '../AddContributionFactor/AddNewBtnCFactor';
import AddEditVariantDialog from '../AddDialog/AddEditVariantDialog';
import ViewVariantDialog from '../AddDialog/ViewVariantDialog';
import AddVariantDialog from '../AddDialog/AddVariantDialog';
import { getChemicalMicrostructures, getTopology } from '../store/variant/actions';

const AllDialogs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lookupStatus());
    dispatch(lookupClassificationType());
    dispatch(lookupNameType());
    dispatch(lookupDomain());
    dispatch(getChemicalMicrostructures());
    dispatch(getTopology());
  }, [dispatch]);

  const lookupData: LookupState = useSelector((state: AppState) => state.lookup);
  const variantRerestrictionData = useSelector((state: AppState) => state.variant.variantRerestrictionData);

  //const user: UserState = useSelector((state: AppState) => state.user);
  const domainArr = [...lookupData.domain, {
    key: 'ALL',
    display: 'ALL'
  }]
  //Iterate over each element in domainArr and Set the selected property of each element to false.
  //domainArr.map((i: any) => { i.selected = false });
  //Sonarqube suggested using forEach instead map
  domainArr.forEach((i: any) => { i.selected = false });
  const [isAddLinkDialogOpen, setAddLinkDialogOpen] = useState(false);
  const [isSwapLinkDialogOpen, setSwapLinkDialogOpen] = useState(false);
  const onAddLinkButtonClick = useCallback(() => { setAddLinkDialogOpen(true); }, [setAddLinkDialogOpen]);
  const onAddLinkDialogClose = useCallback(() => { setAddLinkDialogOpen(false); }, [setAddLinkDialogOpen]);
  const onSwapItemsButtonClick = useCallback(() => { setSwapLinkDialogOpen(true); }, [setSwapLinkDialogOpen]);

  const onSwapLinkDialogClose = useCallback(() => { setSwapLinkDialogOpen(false); }, [setSwapLinkDialogOpen]);
  const onSubmitAddLink = useCallback(
    (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => dispatch(AddLink(formValues, doClose, setSubmitting)),
    [dispatch]
  );
  const user = useSelector((state: AppState) => state.user);
  const [isUnlinkItemsDialogOpen, setUnlinkItemsDialogOpen] = useState(false);
  const onUnlinkItemsButtonClick = useCallback(() => { setUnlinkItemsDialogOpen(true); }, [setUnlinkItemsDialogOpen]);
  const onUnlinkItemsDialogClose = useCallback(() => { setUnlinkItemsDialogOpen(false); }, [setUnlinkItemsDialogOpen]);
  const onSubmitUnlinkItems = useCallback(
    (formValues: AddLinkFormValues, doClose: Function, setSubmitting: Function) => dispatch(RemoveLink(formValues, doClose, setSubmitting)),
    [dispatch]
  );

  const onSubmitSwapItems = useCallback(
    (formValues: any, doClose: Function, setSubmitting: Function) => {
      dispatch(SwapSynonym(formValues, doClose, setSubmitting))
    },
    [dispatch]
  );
  const addLinkFormConfig: AddLinkFormConfig = {
    formType: "AddLink",
    title: "Add Link",
    childNameLabel: "Child",
    parentNameLabel: "Parent",
    statusLabel: "Status",
    statusMenuItems: lookupData.status,
    domainNameLabel: "Requestor's Domain",
    domainNameMenuItems: lookupData.domain,
    isPrimaryForDomainLabel: "Is Substance Name Primary in Domain?",
    auditDescriptionLabel: "Audit Description",
    submitAdd: onSubmitAddLink,
    onClose: onAddLinkDialogClose,
  };

  const addUnlinkItemsFormConfig: AddLinkFormConfig = {
    formType: "Unlink",
    title: "Remove Link",
    childNameLabel: "Child",
    parentNameLabel: "Parent",
    statusLabel: "Status",
    statusMenuItems: lookupData.status,
    domainNameLabel: "Requestor's Domain",
    domainNameMenuItems: lookupData.domain,
    isPrimaryForDomainLabel: "Is Substance Name Primary in Domain?",
    auditDescriptionLabel: "Audit Description",
    submitAdd: onSubmitUnlinkItems,
    onClose: onUnlinkItemsDialogClose,
  };
  const swapSynonymFormConfig: any = {
    formType: "Swap",
    title: "Relocate Names",
    childNameLabel: "Synonyms",
    parentNameLabel: "Substance",
    statusLabel: "Status",
    auditDescriptionLabel: "Audit Description",
    submitAdd: onSubmitSwapItems,
    onClose: onSwapLinkDialogClose,
  };

  const classes = useButtonStyles();

  const onSaveAddVaraint = useCallback(
    (values, formik) => {
      // dispatch(saveAdd(values, formik))
      console.log('add variant values == ', values, formik)
    },
    [dispatch]
  )
  const variantFlag = useSelector((state: AppState) => state.variant.variantFlag);

  const polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);
  const detailData = useSelector((state: AppState) => state.itemDetail);

  return (
    <div>
      <AddNewItemButton />
      <Button onClick={onAddLinkButtonClick} variant="contained" color="primary" className={classes.button} disabled={!user.isAdmin}>
        Add Link
      </Button>
      <Button onClick={onUnlinkItemsButtonClick} variant="contained" color="primary" className={classes.button} disabled={!user.isAdmin}>
        Remove Link
      </Button>
      <Button onClick={onSwapItemsButtonClick} variant="contained" color="primary" className={classes.button} disabled={!user.isAdmin}>
        Relocate Names
      </Button>
      {polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ? <Button variant="contained" color="primary" className={classes.button} disabled>MERGE</Button> : <MergeItemsButton />}
      <AddDialog
        open={isAddLinkDialogOpen}
        config={addLinkFormConfig}
      />
      <AddDialog
        open={isUnlinkItemsDialogOpen}
        config={addUnlinkItemsFormConfig}
      />
      <AddDialog
        open={isSwapLinkDialogOpen}
        config={swapSynonymFormConfig}
      />
      <AddNewBtnCFactor />
      <AddNewItemDialog />
      <MergeItemsDialog />
      <AddVariantDialog domain={domainArr} />
      <AddEditVariantDialog domain={domainArr} restriction={variantRerestrictionData} />
      <ViewVariantDialog domain={domainArr} restriction={variantRerestrictionData}/>
    </div>
  );
}

export default AllDialogs;
