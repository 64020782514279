import React, { useCallback, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { Button, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { showAddItem, showAddItemName } from '../store/addItem/actions';
import { NodeData } from '../store/hierarchy/types';
import { ItemDetailState } from '../store/itemDetail/types';
import { addVariantDialog } from '../store/variant/actions';

const useStyles = makeStyles(theme => ({
  button: {
    margin: "5px !important",
  },
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
  arrowDropdownIcon:{
    color: '#fff !important'
  }
}));

const defaultLookup: { [id: string] : string; } = {
  "Classification": "Classification name",
  "Classification name": "Classification name",
  "Substance": "Substance name",
  "Substance name": "Substance name",
  "Composite substance": "Composite substance",
  "Sub component": "Sub component"
};

const defaultChildTypeForNode = (node: NodeData | null) => {
  if (node) {
    return defaultLookup[node.type];
  }

  return "Classification";
}

const filterSelectedNode = (detailData: ItemDetailState) : NodeData => {
  if (detailData.selectedNode &&
    detailData.selectedNode.name !== 'Classification' && detailData.selectedNode.name !== 'Orphans') {
      return detailData.selectedNode;
    }

  return {} as NodeData
}

const AddNewItemButton = () => {
  const classes=useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: AppState) => state.user);
  const detailData = useSelector((state: AppState) => state.itemDetail);
  //const defaultType = defaultChildTypeForNode(detailData.selectedNode);
  const dType = defaultChildTypeForNode(detailData.selectedNode);
  const defaultType = dType != undefined ? dType : 'Classification';
  const addState = useSelector((state: AppState) => state.addItem);
  const dispatch = useDispatch();
  localStorage.removeItem('editComposite');
  localStorage.removeItem('editSubstance');
  localStorage.removeItem('selectedDomainsForEdit');

  console.log("detailData ==", detailData)
  const onAddNewItem = useCallback( (targetType: string) => {
    const node = filterSelectedNode(detailData);
    let v = (detailData.itemDetail['backboneIdentity'] == 'No item selected') ? "" : detailData.itemDetail['backboneIdentity'];
    let t = detailData.type;
    if (targetType === 'Substance name' || targetType === 'Classification') {
      dispatch(showAddItemName({ parentEntity: { name: v, type: t, subType: node.subType, status: node.status }, targetType: targetType }));
    }
    else if (targetType === "Sub component") {
      dispatch(showAddItemName({ parentEntity: { name: node.name, type: "Sub component", subType: node.subType, status: node.status }, targetType: targetType }));
    } else if (targetType == 'Variant') {
        //console.log('variant here =======')
        dispatch(addVariantDialog({ parentEntity: { name: node.name, type: node.type, subType: node.subType, status: node.status }, targetType: targetType }));
    }
    else {
        dispatch(showAddItem({ parentEntity: { name: v, type: t, subType: node.subType, status: node.status }, targetType: targetType }));
    }
  }, [dispatch,detailData]);

  const onDropdown = useCallback( () => {
   setOpen(s => !s);
  }, [setOpen]);

  const onClose = (event: MouseEvent | TouchEvent) => {
   if(anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)){
      return;
    }
    setOpen(false);
  }

  const variantFlag = useSelector((state: AppState) => state.variant.variantFlag);
  let polymerFlag = useSelector((state: AppState) => state.polymersData.polymerFlag);
console.log('in add btn flags :',polymerFlag,variantFlag,detailData.itemDetail['backboneIdentity'])
if(detailData.itemDetail['backboneIdentity'] =='POLYMERS'){ // Exception since POLYMERS also a polymer but we need only parent polymers 
  polymerFlag=false;
  }

  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  let typeflag = false;
  if (selectedItem) {
    typeflag = (selectedItem.type == "Classification") ? true : false;
  console.log('typeflag =', typeflag, selectedItem.type == "Classification", selectedItem.type)
  }
  return (
    <span>
      <ButtonGroup variant="contained" color="primary" disabled={!user.isAdmin} className={classes.button} ref={anchorRef} >
        <Button onClick={() => onAddNewItem(defaultType)} variant="contained" color="primary">
          Add New
        </Button>
        <Button
            color="primary"
            size="small"
            onClick={onDropdown}
            className="arrowDropdownIcon"
          >
        <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
     <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={onClose}>

                  <MenuList id="split-button-menu">
                  {polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ?  <MenuItem key="Classification" disabled >
                    Add Classification
                  </MenuItem>:<MenuItem key="Classification" onClick={() => onAddNewItem("Classification")} >
                    Add Classification
                  </MenuItem> }
                  {polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ? <MenuItem key="Classification name" disabled >
                    Add Classification name
                  </MenuItem> : <MenuItem key="Classification name" onClick={() => onAddNewItem("Classification name")} >
                    Add Classification name
                  </MenuItem>}
                  {polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ? <MenuItem key="Substance" disabled >
                        Add Substance
                    </MenuItem>: <MenuItem key="Substance" onClick={() => onAddNewItem("Substance")} >
                        Add Substance
                    </MenuItem>}
                    <MenuItem key="Substance name" onClick={() => onAddNewItem("Substance name")} >
                        Add Substance Name
                    </MenuItem>
                  {/*{polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ? <MenuItem key="Composite substance" disabled >*/}
                  {/*  Add Composite Substance*/}
                  {/*  </MenuItem>:<MenuItem key="Composite substance" onClick={() => onAddNewItem("Composite substance")} >*/}
                  {/*  Add Composite Substance*/}
                  {/*  </MenuItem>}*/}
                   <MenuItem key="Composite substance" onClick={() => onAddNewItem("Composite substance")} >
                    Add Composite Substance
                  </MenuItem>
                  {polymerFlag && variantFlag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...') ?<MenuItem key="Sub component" disabled >
                    Add Sub Component
                  </MenuItem> : <MenuItem key="Sub component" onClick={() => onAddNewItem("Sub component")} >
                    Add Sub Component
                  </MenuItem> }
                  {polymerFlag && !variantFlag && !typeflag && (detailData.itemDetail['backboneIdentity'] != undefined && detailData.itemDetail['backboneIdentity'] != 'No item selected' && detailData.itemDetail['backboneIdentity'] != 'Loading...' ) ?   <MenuItem key="Variant" onClick={() => onAddNewItem("Variant")} >
                    Add Variant
                  </MenuItem> : null}
                
                  </MenuList>
                </ClickAwayListener>
              </Paper>
                         </Grow>
          )}
        </Popper>    </span>
  )
}

export default AddNewItemButton;
