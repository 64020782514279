import React from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import { TextField, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { AddFormValues, AddFormConfig } from '../store/entity/types';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';

const useStyles = makeStyles({
  field: {
    marginBottom: "20px  !important"
  }
});

type Props = {
  config: AddFormConfig
};

export const AddFormInner = (props: Props & FormikProps<AddFormValues>) => {
  const classes = useStyles();
  const { nameLabel, typeLabel, typeMenuItems, statusLabel, statusMenuItems, auditDescriptionLabel } = props.config;
  return (
  <Form>
    <div>
      <InputLabel >{nameLabel}</InputLabel>
      <EnhancedTextInputField name="name" fullWidth margin="dense" className={classes.field} />

      <InputLabel htmlFor="classification-type-input">{ typeLabel }</InputLabel>
      <Field name="type">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
           fullWidth
            inputProps={{ id: 'classification-type-input' }}
        >
        { typeMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
      </Field>
      <InputLabel htmlFor="status-input">{ statusLabel }</InputLabel>
      <Field name="status">
        {({ field, form }: any) => (
        <Select
            {...field}
            className={classes.field}
            margin="dense"
           fullWidth
            inputProps={{ id: 'status-input' }}
        >
         { statusMenuItems.map((item) =>
          <MenuItem key = {item.key} value = {item.key}>{item.display}</MenuItem>
        )}
        </Select>
        )}
      </Field>
      <InputLabel htmlFor="audit-description-input">{auditDescriptionLabel}</InputLabel>
      <Field name="auditDescription">
            {({ field, form }: any) => (
              <TextField
                {...field}
                id= 'audit-description-input' 
                className={classes.field}
                margin="dense"
                fullWidth
                error={Boolean(
                  form.errors.auditDescription && form.touched.auditDescription
                )}
                helperText={
                  form.touched.auditDescription && form.errors.auditDescription
                }
              />
            )}
      </Field>
      <Button type="submit" color="primary">
        Add
      </Button>
    </div>
  </Form>
)};

const AddForm = withFormik<Props, AddFormValues>({
  mapPropsToValues: (props) => ({name: "", type:"", status: "A", auditDescription:""}),
  handleSubmit: ( formValues, formikBag) => {
    const { submitAdd, onClose } = formikBag.props.config;
    const { setSubmitting } = formikBag;
    submitAdd(formValues, onClose, setSubmitting);
  },
  enableReinitialize: true,
})(AddFormInner);

export default AddForm;
