import React from 'react';
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Box } from '@mui/material';
import InformationIcon from "@mui/icons-material/Info";

const useStyles = makeStyles({
  pathItem: {
      overflow: "hidden !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      maxWidth: "200px !important",
      display: "inline-block !important",
      margin: "2px !important"
  },
  icon: {
    marginBottom: "5px !important"
}

});

type Props = {
  path: string[];
  domain: string;
};

type ItemProps = {
  name: string;
  className: string;
};

type SeparatorProps = {
  className: string;
}

const PathItem = (props: ItemProps) => {
  const { name, className } = props;
  return (
    <div className={className} title={name}>{name}</div>
  );
}

const PathSeparator = ( props: SeparatorProps ) => {
  const { className } = props;
  return (
    <div className={className} >{'>'}</div>
  );
}

const HierarchyPath = (props: Props) => {
  const classes = useStyles();
  return (
    <Box>
    <Typography variant="caption" title={props.domain}>
      <InformationIcon fontSize="inherit" className={classes.icon} />
      {props.path.map((item, index) => (
        <React.Fragment key={item} >
          { index > 0 && <PathSeparator className={classes.pathItem} /> }
          <PathItem name={item} className={classes.pathItem} />
        </React.Fragment>
        )
      )}
    </Typography>
    </Box>
    );
};

export default HierarchyPath;
