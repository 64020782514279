import { Button } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store'; 
 
const useStyles = makeStyles(theme => ({
    button: {
    },
    icon: {
      width: '16px !important',
      height: '16px !important'
    },
  }));
   

const EditButton = (props: any) => {
    const classes=useStyles();
     const dispatch = useDispatch();
    
  
    return (
      <span>
        <Button size="small" variant="text" className={classes.button} title="Edit">
          <EditIcon className={classes.icon} />
        </Button>
      </span>
    )
  }
  
  export default EditButton;
