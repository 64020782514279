import React, {useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { AppState } from '../store';
import TreeView from './TreeView';
import { queryNode } from '../store/hierarchy/actions';
import { showDetail } from '../store/itemDetail/actions';
import { NodeData } from '../store/hierarchy/types';

const useStyles = makeStyles({
  paper: {
      padding: '5px !important',
    textAlign: 'center !important' as 'center',
    overflow: "auto !important",
    height: "1027px !important"
  },
  paperdefault: {
    padding: 5,
    textAlign: 'center !important' as 'center',
    overflow: "auto !important",
    height: "75vh !important"
  }
  });

const TreeContainer = () => {
  const classes = useStyles();
  const treeData = useSelector((state: AppState) => state.hierarchy);
  const selectedItem = useSelector((state: AppState) => state.search.selectedItem);
  const dispatch = useDispatch();
  const onSelectNode = useCallback(
    (name: string, type: string) => {
      dispatch(queryNode(name, type))
    },
    [dispatch]
  );
  const onShowDetail = useCallback(
    (nodeData: NodeData) => dispatch(showDetail(nodeData)),
    [dispatch]
  );

  return (
    <Paper className={selectedItem && selectedItem.type !== 'Composite' ? classes.paper : classes.paperdefault} >
      <TreeView
        data={treeData.tree}
        loading={treeData.loadingNode}
        onSelectNode={onSelectNode}
        onShowDetail={onShowDetail} />
    </Paper>
  );
}

export default TreeContainer;
