import { createTheme } from '@mui/material/styles';
import { colours } from './colours';

export function customSpacing(factor:number, asNumber = true):number|string
{   
  const result = factor * 8;   
  return asNumber ? result :`${result}px`;
 }
// A custom theme for this app
const theme = createTheme({
  spacing:(factor: number) =>customSpacing(factor),
  palette: {
    primary: {
      main: colours.ulBluePrimary,
    },
    secondary: {
      main: colours.ulPurplePrimary,
    },
    error: {
      main: colours.uRed,
    },
    background: {
      default: '#fff',
    },
    
  },
  components:{
    MuiTextField:{
      defaultProps:{
        variant: 'standard'
      }
    },
    MuiFormControl:{
      defaultProps:{
        variant:'standard'
      }
    },
    MuiSelect:{
      defaultProps:{
        variant:'standard'
      }
    },
  },
});

export default theme;