import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
 import { closePolymerHistoryView } from '../store/history/actions';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Table, TableHead, TableRow, TableBody, TableCell, Typography } from '@mui/material';
 import { formatDateAndTime } from '../domain/Format';

const useStyles = makeStyles({
  paper: {
    padding: '5px !important',
    textAlign: 'center !important' as 'center',
    overflow: "auto !important",
    maxHeight: "70vh !important"
  }
});
const PolymerStructureHistoryDialog = () => {
  const dispatch = useDispatch();
  const itemHistory = useSelector((state: AppState) => {
    return state.itemHistory;
  });
  let polymerHistoryArr: any[] = [];
  let polymerName = '';

  console.log('itemHistory ==', itemHistory);


  if (itemHistory && itemHistory.polymerHistory && itemHistory.polymerHistory.backboneIdentity !='') {
  //  //if (itemHistory.polymerHistory.itemHistory) {
    polymerHistoryArr = itemHistory.polymerHistory.history.length ? itemHistory.polymerHistory.history :[];
    polymerName = itemHistory.polymerHistory.backboneIdentity;
  //    console.log('if ==')

  }
   const onClose = useCallback(
    () => { dispatch(closePolymerHistoryView()); },
    [dispatch]);

  const open = polymerHistoryArr.length>0?true :false ;
  const classes = useStyles();
console.log('polymerHistoryArr ==',polymerHistoryArr)
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg" >
      <MuiDialogTitle >
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            {`History for ${polymerName}`}
           </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent>
        <Paper className={classes.paper} >
          <Typography variant="h4" >
            Structure History
          </Typography>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>When</TableCell>
                <TableCell>Who</TableCell>
                <TableCell>Source SMILES</TableCell>
                <TableCell>Name Type</TableCell>
                <TableCell>Molecular Formula</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Verification Status</TableCell>
                <TableCell>PRF SMILES</TableCell>
                <TableCell>Substituent SMILES</TableCell>
                 <TableCell>SRU SMILES</TableCell>
                <TableCell>Change Type</TableCell>
                <TableCell>Change Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="namelistBody">
              {polymerHistoryArr.map((item, i) => (
                <TableRow key={`item${i}`}>                
                  <TableCell>{formatDateAndTime(item.historyDateTime)}</TableCell>
                <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.molecule}</TableCell>
                  <TableCell>{item.nameType}</TableCell>
                  <TableCell>{item.molecularFormula}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{item.verificationStatus}</TableCell>
                  <TableCell>{item.prfSmiles}</TableCell>
                  <TableCell>{item.substituentSmiles}</TableCell>
                  <TableCell>{item.sruSmiles}</TableCell>
                  <TableCell>{item.changeType}</TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> 
        </Paper>      </DialogContent>
    </Dialog>
  );
}

export default PolymerStructureHistoryDialog;
