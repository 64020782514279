import { createReducer } from 'typesafe-actions';
import { NodeData, NodeResult, StructureState } from "./types";
import * as actions from './actions';
const initialState: any = {
  tree: [],
  loadingNode: null
};

const combine = (state:any, action:any) => {
  return { ...state, tree: action.payload.data }
}
export const parentChildApiReducer = createReducer(initialState)
  .handleAction(actions.nodeRequest.success, (state, action) => combine(state,action))
  .handleAction(actions.nodeRequest.failure, (state, action) => ({ ...state, loadingNode: null }))
