import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { useDropzone } from 'react-dropzone';
import { Paper, Grid, Button, Accordion, AccordionSummary, Typography, AccordionDetails, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import SaveIcon from "@mui/icons-material/SaveAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fileDrop, importFile, exportReport } from '../store/importExport/actions';

const useStyles = makeStyles((theme: any) => ({
  dropZone: {
    width: "300px !important",
    borderRadius: '5px !important',
    borderWidth: '2px !important',
    padding: "10px !important",
    minHeight: "0px !important",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15) + ' !important',
    fontWeight: theme.typography.fontWeightRegular + ' !important' as number | "normal" | "bold",
  },
  table: {
    minWidth: "500px !important"
  },
  button: {},
  icon: {
    width: '16px !important',
    height: '16px !important'
  },
}));

const ImportPanel = () => {
  const classes=useStyles();
  const importExportData = useSelector((state: AppState) => state.importExport);
  const user = useSelector((state: AppState) => state.user);
  const reportUrl = importExportData.importResult ? importExportData.importResult.reportUrl : null;
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles: File[]) => {
      dispatch(fileDrop(acceptedFiles));
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:{ "text/csv": [".csv"] },
    maxFiles: 1,
  });

  const onImport = useCallback( () => {
    dispatch(importFile());
  }, [dispatch]);

  const onReportExport = useCallback( () => {
    if (reportUrl) {
      dispatch(exportReport(reportUrl));
    }
  }, [dispatch, reportUrl]);

  return (
    <Paper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>File Import</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {user.isAdmin ? 
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <div {...getRootProps({ className: classes.dropZone })} data-testid="mock-dropzone-area">
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <p>Drag 'n' drop a file here, or click to select a file</p>
                  )}
                </div>
              </Grid>
              <Grid item>
                <div>{importExportData.importFileName || "No file"}</div>
                <Button onClick={onImport} variant="contained" disabled={!importExportData.importFileName} >Import</Button>
              </Grid>
              <Grid item>
              {importExportData.inProgress && importExportData.importFileName &&
              <div>Loading...
                <CircularProgress />
              </div>
            }
              </Grid>
              <Grid item>
                {importExportData.importResult &&
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="right">Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key="name">
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">{importExportData.importResult.name}</TableCell>
                      </TableRow>
                      <TableRow key="errorCount">
                        <TableCell align="right">Errors</TableCell>
                        <TableCell align="right">{importExportData.importResult.errorCount}</TableCell>
                      </TableRow>
                      <TableRow key="substanceCount">
                        <TableCell align="right">Substances</TableCell>
                        <TableCell align="right">{importExportData.importResult.substanceCount}</TableCell>
                      </TableRow>
                      <TableRow key="nameCount">
                        <TableCell align="right">Names</TableCell>
                        <TableCell align="right">{importExportData.importResult.nameCount}</TableCell>
                      </TableRow>
                      <TableRow key="classificationCount">
                        <TableCell align="right">Classifications</TableCell>
                        <TableCell align="right">{importExportData.importResult.classificationCount}</TableCell>
                      </TableRow>
                      <TableRow key="linkCount">
                        <TableCell align="right">Links</TableCell>
                        <TableCell align="right">{importExportData.importResult.linkCount}</TableCell>
                      </TableRow>
                      <TableRow key="reportDownload">
                        <TableCell align="right">Report Download</TableCell>
                        <TableCell align="right">
                        <Button size="small" variant="text" className={classes.button} onClick={onReportExport} title="Download a row-by-row report" >
                            CSV Report
                            <SaveIcon className={classes.icon} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                }
              </Grid>
              </Grid> : "Import is only available to Admin users" }
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}

export default ImportPanel;
