import React from 'react';
import { makeStyles } from "@mui/styles";
import { Paper } from '@mui/material';
import HierarchyPath from './HierarchyPath';
import groupBy from 'lodash/groupBy';

const useStyles = makeStyles({
  container: {
    padding: '5px !important',
    textAlign: 'left' as const
  },
  pathItem: {
      overflow: "hidden  !important",
      whiteSpace: "nowrap !important",
      textOverflow: "ellipsis !important",
      maxWidth: "200px !important",
      display: "inline-block !important"
  }
});

type PathDetail = {
  domain: string;
  path: string[];
}
type Props = {
  paths: PathDetail[];
};

const mergePathsByDomain = (paths: PathDetail[]): PathDetail[] => {
  const merged = groupBy(paths, (p) => {
    return p.path.join('|');
  });

  return Object.values(merged).map(i => ({ domain: i.map(n => n.domain).join(','), path: i[0].path}));
}

const PathDisplay = (props: Props) => {
  const classes = useStyles();
  const {paths} = props;
  return (
    paths &&
      <Paper className={classes.container}>
        {mergePathsByDomain(paths).map((p, i) => (
          <HierarchyPath key={`path${i}`} path={p.path.slice(0, -1)} domain={p.domain} />
        ))}
      </Paper>
    );
};

export default PathDisplay;
