import { Dispatch } from 'redux';
import axios from 'axios';
import { createAsyncAction } from 'typesafe-actions';
import { NodeResult } from './types';
import { makeError } from '../error/actions';
import { AppState } from '../index';

export const nodeRequest = createAsyncAction(
  "NODE_REQUEST_START",
  "NODE_REQUEST_SUCCESS",
  "NODE_REQUEST_fAILURE")<string, NodeResult, string>();

export const checkClassification = (nodeName: string, nodeType: string, dispatch: Dispatch) => {
  return (dispatch: Dispatch, getState: () => AppState) => {
    if (nodeName === 'Orphans') {
      callOrphans(dispatch);
    } else if (nodeName === 'Classification') {
      callParentChildApi("", nodeType, dispatch);
    } else {
      callParentChildApi(nodeName, nodeType, dispatch);
    }
  }
}

const callOrphans = (dispatch: Dispatch) => {
  dispatch(nodeRequest.request("Orphans"));

  axios.get('/api/v1/entities/orphans')
    .then((response) => {
      dispatch(nodeRequest.success({ parentName: 'Orphans', nodeType: 'Classification', data: response.data }));
    })
    .catch((error) => {
      dispatch(makeError(error));
      dispatch(nodeRequest.failure(error.message));
    });
}

export const callParentChildApi = (nodeName: string, nodeType: string, dispatch: Dispatch) => {
  const queryName = nodeName || "";
  const queryType = nodeType || "Classification";
  dispatch(nodeRequest.request(nodeName));
  axios.get(`/api/v1/entities/parentageandchildren?nodeName=${encodeURIComponent(queryName)}&nodeType=${queryType}`)
    .then((response) => {
      dispatch(nodeRequest.success({ parentName: nodeName, nodeType: queryType, data: response.data }))
    })
    .catch((error) => {
      dispatch(makeError(error));
      dispatch(nodeRequest.failure(error.message));
    });
}
