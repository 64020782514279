import * as React from "react";
import { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { SearchInProgress } from '../SearchBox/SearchMessages';
import { closeVariantDialog, addVariant, editVariantInfo } from '../store/variant/actions';
import { Paper, Input } from '@mui/material';
import Button from '@mui/material/Button';
import { Form, Formik, FormikActions } from 'formik';
import { makeStyles } from '@mui/styles';
import { makeError } from '../store/error/actions';
import InputLabel from '@mui/material/InputLabel';
import SearchField from '../SearchBox/SearchField';
import Typography from '@mui/material/Typography';
import  { Checkbox, Table , TableBody, TableRow, TableCell } from '@mui/material';
import { Select, MenuItem, Grid, FormControl, Chip } from '@mui/material';
import { editVariantObj, variantRestriction } from "../store/variant/types";
import { Suggestion } from '../store/search/types';
import { getParentPolymer } from '../store/polymer/actions';
import { Tooltip, TextField } from '@mui/material';
import { customSpacing } from '../theme/SIRtheme';

const useStyles = makeStyles((theme: any) => ({

  field: {
    marginBottom: "20px !important"
  },
  flex: {
    display: 'flex'
  },
  label: {
    color: 'grey !important',
    paddingRight: '20px !important',
    paddingTop: '8px !important',
    paddingBottom: '15px !important'
  },
  disableDD: {
    pointerEvents: "none"
  },
  inputBox: {
    marginBottom: "20px !important",
    width: "98% !important"
  },
  domainPanel: {
    padding: "8px !important"
  },
  title: {
    textAlign: "center"
  },
  labelCheckbox: {
    textAlign: "left"
  },
  paper: {
    padding: '2px !important',
    margin: '8px !important'
  },
  font: {
    fontSize: "1rem !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontWeight: '400 !important',
    lineHeight: '1.5 !important',
    letterSpacing: "0.00938em !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  width: {
    width: "100% !important"
  },
  removeMar: {
    margin: "0px !important"
  },
  marginTop: {
    marginTop: "15px !important",
  },

  //paper: {
  //  padding: "5px",
  //  height: "100%"
  //},
  msgColorRed: {
    color: 'red !important',
    fontSize: '14px !important'
  },
  msgColorGreen: {
    color: 'green !important',
    fontSize: '14px !important'
  },
  noaction: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: 'no-drop',
    pointerEvents: 'none'
  },
  formControl: {
    margin: customSpacing(1, false) + ' !important',
    width: "550px !important",
  },
  selectEmpty: {
    marginTop: "12px !important",
    padding: "0px !important",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '2px !important',
    height: '26px !important'
  },
  selectEmpty1: {
    marginTop: "2px !important",
    width: '550px !important',
  },
  formControl1: {
    margin: customSpacing(1, false) + ' !important',
    width: "700px !important",
  },
  paperCheckbox: {
    padding: '2px !important',
    textAlign: 'center !important' as 'center',
    width: "max-content !important",
    margin: '8px !important'
  },
  table: {
    minWidth: "200px !important"
  },
  tableCell: {
    padding: "0px !important",
    borderBottom: "none !important"
  },
  errText: {
    color: "Red !important",
    padding: "5px !important"
  },
  menuItemMsg: {
    //position: "absolute",
    zIndex: 999 + ' !important',
    //width: 535,
    fontSize: "0.9rem !important",
    minHeight: "10px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    backgroundColor: "ghostwhite !important",
    paddingLeft: "15px !important",
    border: "1px solid lightgray !important",
    borderRadius: "4px !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  tooltipStyle: {
    fontSize: "0.85rem !important"
  }, mainDiv: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
    borderWidth: "1px !important",
    borderColor: "lightgray !important",
    borderStyle: "solid !important",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important"
  },
  nocursor: {
    pointerEvents: "none",
  },
  pad: {
    paddingTop: "25px !important",
    paddingLeft: "15px !important",
    paddingRight: "20px !important",
    paddingBottom: "15px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important'
  },
  padEmail: {
    paddingTop: "25px !important",
    fontSize: '16px !important',
    fontWeight: 500 + ' !important',
    color: 'gray !important',
    paddingLeft: "15px !important",
    paddingBottom: "15px !important"
  },
  para: {
    margin: '0px !important',
    color: 'red !important',
    paddingLeft: "15px !important"
  },
  textStrike: {
    marginTop: "20px !important",
    //paddingLeft: "15px"
  }
}));

const AddEditVariantDialog = (props: any) => {
  const classes = useStyles();
  //const variantDetails = useSelector((state: AppState) => (state.polymersData.ParentPolymerDetails != null ? state.polymersData.ParentPolymerDetails.variantDetails : null));
  const addVariantState = useSelector((state: AppState) => state.variant);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeVariantDialog()),
    [dispatch]
  );
  const onSave = useCallback(
    (values, formik) => {
    },
    [dispatch]
  )

  const state = useSelector((state: AppState) => state);
  const open = (addVariantState && addVariantState.show == true) ? true : false;
  const variantNameT = useSelector((state: AppState) => state.variant.indexOfVariant);

  const parentEntity = useSelector((state: AppState) => state.variant.parentEntity);
  const parentEntityObj = {
    "parentEntity": parentEntity
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            {(variantNameT != null ? "Edit Variant" : "Add New Variant")}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>

      <DialogContent>

        <AddEditVariantFormik onSave={onSave} values={parentEntityObj} domain={props.domain} restriction={props.restriction} />
      </DialogContent>
    </Dialog>
  );
}

export default AddEditVariantDialog;

interface FormProps {
  values: any;
  onSave: (values: Suggestion, formikActions: FormikActions<any>) => void;
  domain: any;
  restriction: any;
}

const AddEditVariantFormik = (props: FormProps) => {

  const { values } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={props.onSave}
        //validate={validateNewItem}
        render={(fp) => (
          <AddEditVariantContent  {...fp} onSave={props.onSave} domain={props.domain} restriction={props.restriction } />
        )}
      />
    </div>
  );
};


const AddEditVariantContent = (props: any) => {

  var [showErrMsg, setshowErrMsg] = useState(0);
  const variantNameT = useSelector((state: AppState) => state.variant.indexOfVariant);
  var variantDetails = useSelector((state: AppState) => (state.polymersData.ParentPolymerDetails != null ? state.polymersData.ParentPolymerDetails.variantDetails : null));
  const variantscreen = useSelector((state: AppState) => state.variant.variantStrcutureInfo);

  var pr = false;
  if (variantDetails == null && variantscreen != null) {
    pr = true;
    variantDetails = variantscreen;
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const addVariantState = useSelector((state: AppState) => state.variant);
  const isEdit = (addVariantState && addVariantState.isEdit == true) ? true : false;
  const domainArr = props.domain;
  console.log('props ==', props.restriction)
  const initialDomain = () => {
    const s = {};
    const p = domainArr.map((item: any) => {
      Object.assign(s, { [item.key]: false })
      //if ([item.key].toString() == 'HC') {
      //  Object.assign(s, { [item.key]: true })
      //} else {
      //  Object.assign(s, { [item.key]: false })
      //}
    })

    return s;
  }


  const getArr = (s: any) => {
    // if everydomain selected then make ALL as true 
    let cnt = 0;
    const k = {};

    const totalProperties = Object.keys(s).length-1;
    for (const property in s) {
      if (s[property as keyof typeof s] == true) {
        Object.assign(k, { [property]: true })
        cnt++;
      } else if (property != 'ALL') {
        Object.assign(k, { [property]: false })
      }
    }

    if (cnt === totalProperties) {
      Object.assign(k, { ['ALL']: true })
    } else {
      Object.assign(k, { ['ALL']: false })
    }

    return k;
  }

  var index = 0;
  var editedVariantArr = [];

  if (pr == false) {
    editedVariantArr = (variantDetails != null ? variantDetails.map((item: any, index1: any) => {

      if (variantNameT != null && item.variantName == variantNameT) {
        index = index1
        return item;
      }
    }) : null);

  } else {
    editedVariantArr = (variantDetails != null ? variantDetails : null);

  }

  //Initializing variables
  var variantIdTmp = "";
  var variantNameTmp = "";
  var sourceIDTmp = "";
  var weightAverageMolecularWeight = "";
  var numberAvergeMolecularWeight = "";
  var polydispersityIndex = "";
  var averageDegreeOfPolymerisation = "";
  var prfCompositionMoleFraction = "";
  var substituentCompositionTmp = "";
  var sourceCompositionMoleFraction = "";
  var auditDescription = "";
  var ds = "";
  var topologyTmp = [];
  var chemicalMicroStructure = [];
  var variantStatus = 'Active';
  var subType = 'Synonym';
  var parentNameT = "";
  var linkDomainTmp = {};
  linkDomainTmp = initialDomain;

  var arrLinkDomain: any;

  //The below will be executed only during edit state of variant ..  
  if (editedVariantArr != null && editedVariantArr[index] != "" && isEdit) {
    //if (variantDetails != null && variantNameT != null) {

    //isEdit = true;
    var editedVariant;

    if (pr == false) {
      editedVariant = editedVariantArr[index];

    } else {
      editedVariant = editedVariantArr;

    }

    variantIdTmp = editedVariant.variantId;
    variantNameTmp = editedVariant.variantName;
    sourceIDTmp = editedVariant.sourceID;
    weightAverageMolecularWeight = editedVariant.weightAverageMolecularWeight;
    numberAvergeMolecularWeight = editedVariant.numberAvergeMolecularWeight;
    polydispersityIndex = editedVariant.polydispersityIndex;
    averageDegreeOfPolymerisation = editedVariant.averageDegreeOfPolymerisation;
    prfCompositionMoleFraction = editedVariant.prfCompositionMoleFraction;
    substituentCompositionTmp = editedVariant.substituentComposition;
    sourceCompositionMoleFraction = editedVariant.sourceCompositionMoleFraction;
    auditDescription = editedVariant.auditDescription;
    ds = editedVariant.ds;
    topologyTmp = editedVariant.topology;
    chemicalMicroStructure = editedVariant.chemicalMicroStructure;
    variantStatus = (editedVariant.variantStatus == 'A' ? "Active" : "Inactive");
    subType = editedVariant.nameType;
    parentNameT = editedVariant.parentName;
    var linkDomainTmp2 = editedVariant.linkDoimain;

    var linkDomainArr = (linkDomainTmp2!=null)?linkDomainTmp2.toString().split(","):[];
    //linkDomainTmp = finalDomain(linkDomainArr);

    const s = {};
    const p = domainArr.map((item: any) => {
      if (linkDomainArr.includes([item.key].toString())) {
        Object.assign(s, { [item.key]: true })
      } else {
        Object.assign(s, { [item.key]: false })
      }
    })
   // console.log('s==', s)
    const m = getArr(s);
   // console.log('m ==', m)
    linkDomainTmp = m;
    arrLinkDomain = Object.entries(linkDomainTmp).map(([key, value]) => ({ key, value }));
    //console.log('arrLinkDomain ==', arrLinkDomain)
    //arrlinkDomain.map(item => alert(JSON.stringify(item.key) + " -- in linkDomainTmp -- value -- " + JSON.stringify(item.value)));

  }
  //Till here

  var [variantId, setvariantId] = React.useState(variantIdTmp);
  var [currentNameTmp, setcurrentNameTmp] = React.useState(variantNameTmp);
  var [variantName, setvariantName] = React.useState(variantNameTmp);
  var [sourceID, setsourceID] = React.useState(sourceIDTmp);
  var [WAMW, setWAMW] = React.useState(weightAverageMolecularWeight);
  var [NAMW, setNAMW] = React.useState(numberAvergeMolecularWeight);
  var [PDI, setPID] = React.useState(polydispersityIndex);
  var [DP, setDP] = React.useState(averageDegreeOfPolymerisation);
  var [PRF, setPRF] = React.useState(prfCompositionMoleFraction);
  var [substituentComposition, setsubstituentComposition] = React.useState(substituentCompositionTmp);
  var [sourceComposition, setsourceComposition] = React.useState(sourceCompositionMoleFraction);
  var [auditDescp, setauditDescp] = React.useState(auditDescription);
  var [DS, setDS] = React.useState(ds);
  var [topology, setTopology] = React.useState((topologyTmp != null ? topologyTmp:[]));
  var [chemicalMicro, setchemicalMicro] = React.useState((chemicalMicroStructure != null ? chemicalMicroStructure:[]));
  var [verificationStatus, setVerificationStatus] = React.useState(variantStatus);
  var [type, setType] = React.useState(subType);
  var [parentName, setParentName] = React.useState(parentNameT);
  var [linkdomain, setlinkdomain] = React.useState(linkDomainTmp);

  const detailData = useSelector((state: AppState) => state);
  //console.log('detailData ==', detailData) 

  var { values } = props;

  if (values.parentEntity.name != parentName) {
    values.parentEntity.name = parentName;
  }

  useEffect(() => {
    console.log('values ==', values, values.parentEntity)

    if (values.parentEntity && values.parentEntity.name) {
      console.log('parentName ==', values.parentEntity.name);
      let variantName = "";
      if (sourceID != '') {
        let v = values.parentEntity.name + ' ' + sourceID;
        v = v.replace(/  +/g, ' ');//removing extra spaces
        v = v.trim();
        setvariantName(v);
      } 

      //Commenting the old code, where two were considered from mn,mw,pdi
      // else if (PRF != '' || sourceComposition != '' || WAMW != '' || NAMW != '' || PDI != '' || DP != '' || DS != '' || topology.length || chemicalMicro.length) {
      //   let one = (PRF != '' ? PRF + ' ' : sourceComposition + ' ');
      //   // in two if mn,mw,pdi r present take mn,mw else take whichever r present from 3 
      //   let two = (PDI != '' && WAMW != '' && NAMW != '') ? (WAMW != '' ? 'Mw ' + WAMW + ' ' : ' ') + (NAMW != '' ? 'Mn ' + NAMW + ' ' : ' ') : (WAMW != '' ? 'Mw ' + WAMW + ' ' : ' ') + (NAMW != '' ? 'Mn ' + NAMW + ' ' : ' ') + (PDI != '' ? 'PDI ' + PDI : '');
      //   let three = values.parentEntity.name + ' ' + one + two + ' ' + (DS != '' ? 'DS ' + DS + ' ' : '') + ' ' + (topology.length ? topology[0] : '') + ' ' + (chemicalMicro.length ? chemicalMicro[0] : '')
      //   let four = three.replace(/  +/g, ' ');
      //   four = four.trim();
      //   setvariantName(four);
      // }

     // New code, where DP is also considered, Substituent Composition is added  
      else if (PRF != '' || sourceComposition != '' || WAMW != '' || NAMW != '' || PDI != '' || DP != '' || DS != '' || substituentComposition != '' || topology.length || chemicalMicro.length) {
        let one = (PRF != '' ? PRF + ' ' : sourceComposition + ' ');
        // in two if mn,mw,pdi r present take mn,mw else take whichever r present from 3 
        let priority = ['DP','Mw','Mn','PDI'];
        let val = [DP,WAMW,NAMW,PDI];
        let res = [];
        for (let i=0;i<priority.length;i++){
          if(val[i] != ''){
            res.push(priority[i] + ' ' +val[i]);
            if(res.length==2) break;
          }
        }
        let two = res.join(' ');
        let three = values.parentEntity.name + ' ' + one + two + ' ' + (DS != '' ? 'DS ' + DS + ' ' : '') + ' ' + (substituentComposition != '' ? 'Substituent Composition ' + substituentComposition + ' ' : '') + ' ' + (topology.length ? topology[0] : '') + ' ' + (chemicalMicro.length ? chemicalMicro[0] : '');
        let four = three.replace(/  +/g, ' ');
        four = four.trim();
        setvariantName(four);
      }
      else {
        setvariantName('')
        console.log('else of ALL fields')
      }
    }
  }, [props, sourceID, PRF, WAMW, NAMW, PDI, DS,DP,substituentComposition, topology, chemicalMicro, sourceComposition]);

  var Loading = false;

  //not in use
  /*const getParent = async () => {
    await dispatch<any>(getParentPolymer(values.parentEntity.name));
    Loading = false;
  }*/


  const [uniqueVariantErr, setuniqueVariantErr] = React.useState(false);
  console.log('variantDetails =', variantDetails);
  useEffect(() => {

    if (variantDetails != null && !Array.isArray(variantDetails)) {
      if ((variantDetails['variantName'] == variantName) && (variantDetails['variantName'] != variantNameT)) {
        setuniqueVariantErr(true);

      } else {
        setuniqueVariantErr(false);

      }

    } else {
      if (variantDetails != null && variantDetails.some((i: any) => (i.variantName == variantName && i.variantName != variantNameT))) {
        setuniqueVariantErr(true);

      } else {
        setuniqueVariantErr(false);

      }
    }

  }, [variantName])

  const LinkDataSearch = () => (
    <Paper className={classes.paper} >
      <InputLabel>Parent</InputLabel>
      {/*<SearchField name="parentEntity" type={values.newEntity.type} values={values} />*/}
      {/*<SearchField isVariant='Yes' type={substanceType} name="parentEntity" values={p} />*/}
      {/*<SearchField name="parentEntity" isVariant='Yes' type='variant' values={values} />*/}

      {(values != null && values.parentEntity != null ? <Input name="parentEntity" className={classes.inputBox} defaultValue={values.parentEntity.name} disabled />
        : <SearchField name="parentEntity" isVariant='Yes' type='variant' values={values} />)}

    </Paper>
  )

  const verificationStatusList = [
    'Active',
    'Inactive'
  ]

  //not in use
  /*const variantNameChange = (event: any) => {
    setvariantName(event.target.value)
  };*/

  const sourceIdChange = (event: any) => {
    setshowErrMsg(1);
    setsourceID(event.target.value)
  };

  const PDIChange = (event: any) => {
    setshowErrMsg(1);
    setPID(event.target.value)
  };

  const NAMWChange = (event: any) => {
    setshowErrMsg(1);
    setNAMW(event.target.value)
  };

  const WAMWChange = (event: any) => {
    setshowErrMsg(1);
    setWAMW(event.target.value)
  };

  const DPChange = (event: any) => {
    setshowErrMsg(1);
    setDP(event.target.value)
  };

  const PRFChange = (event: any) => {
    setshowErrMsg(1);
    setPRF(event.target.value)
  };

  const substituentCompositionChange = (event: any) => {
    setshowErrMsg(1);
    setsubstituentComposition(event.target.value)
  };

  const DSChange = (event: any) => {
    setshowErrMsg(1);
    setDS(event.target.value)
  };

  const auditChange = (event: any) => {
    setshowErrMsg(1);
    setauditDescp(event.target.value)
  };


  const chemicalMicrostrsArray = useSelector((state: AppState) => state.variant.chemicalMicrostructuresList);
  const topologyArray = useSelector((state: AppState) => state.variant.topologyList);

  //const r = useSelector((state: AppState) => state.variant.variantRerestrictionData);
  const restrictionData = props.restriction;
  console.log('restrictionData ==> hello', restrictionData)

  const sourceCompositionChange = (event: any) => {
    setshowErrMsg(1);
    setsourceComposition(event.target.value)
  }


  const topologyChange = (event: any) => {
    setshowErrMsg(1);
    setTopology(event.target.value)
  };

  const chemicalMicroChange = (event: any) => {
    setshowErrMsg(1);
    setchemicalMicro(event.target.value)
  };

  const handleVerificationStatus = (event: any) => {
    setshowErrMsg(1);
    setVerificationStatus(event.target.value);
  }

  const handleType = (event: any) => {
    setType(event.target.value);
  }

  const lookup = useSelector((state: AppState) => state.lookup);
  let nameType = lookup.nameType;

  nameType = nameType.filter((i: any) => {
    if (i.display != "Sub Component" && i.display != "Component" && i.display != "CLS ID" && i.display != 'PLM ING' && i.display != 'Trade Name') {
      return true
    }
    else {
      return false
    }
  })

  //const [linkdomain, setlinkdomain] = React.useState(initialDomain);
  const domainErr = React.useRef(false);
  //const hcdomainErr = React.useRef(false);
  const minOnefieldErr = React.useRef(false);
  const parentEmptyErr = React.useRef(false);
  const RestrictionErr = React.useRef(false);

  const domainChange = (item: any) => {
    const totalDomains = Object.keys(linkdomain).length-1;
    let s= {};
    if (item.a == 'ALL') {
      for (const p in linkdomain) {
        Object.assign(s, { [p]: item.selected });
      }
      setlinkdomain(s);
    } else {
      let selectCount = 0;
      for (const p in linkdomain) {
        if (p !== 'ALL' && (p ===item.a && item.selected) || linkdomain[p as keyof typeof linkdomain] == true){
          selectCount++;
        }
      }
          for (const p in linkdomain) {
            if (p === 'ALL') {
              Object.assign(s, { [p]: selectCount === totalDomains });
          }else if (p === item.a) {
          Object.assign(s, { [p]: item.selected });
        } else {
          let v = linkdomain[p as keyof typeof linkdomain];
          Object.assign(s, { [p]: v });
        }
      }
      setlinkdomain(s);
      //setlinkdomain({
      //  ...linkdomain,
      //  [item.a]: item.selected,
      //});
    }

    console.log('checked ==', item, linkdomain)
  }

  const validateVariant = () => {
    let d = computeDomain();
    console.log('D here ==', d.length)
    if (d.length == 0) {
      console.log('if ==')
      //setdomainErr(true);
      domainErr.current = true;

      // sethcdomainErr(false);
      //hcdomainErr.current = false;
    }
    //else if (d.includes('HC')) {
    //  console.log('else if ==')
    //  // setdomainErr(false);
    //  domainErr.current = false;
    //  //sethcdomainErr(false);
    //  // hcdomainErr.current = false;
    //}
    else {
      console.log('else ==')
      //sethcdomainErr(true);
      //hcdomainErr.current = true;
      //setdomainErr(false);
      domainErr.current = false;
    }


    if (sourceID == '' && PRF == '' && WAMW == '' && NAMW == '' && PDI == '' && sourceComposition == '' && DS == '' && DP == '' && substituentComposition == '' && topology.length == 0 && chemicalMicro.length == 0) {
        //setminOnefieldErr(true);
      minOnefieldErr.current = true;
    } else {
      //setminOnefieldErr(false);
      minOnefieldErr.current = false;
    }

    console.log('parent ==', props, props.values.parentEntity)
    if (props.values.parentEntity != null && (props.values.parentEntity.name == '' || props.values.parentEntity.name == undefined)) {
      // setparentEmptyErr(true);
      parentEmptyErr.current = true;
    } else {
      // setparentEmptyErr(false);
      parentEmptyErr.current = false;
    }

    if (sourceID == '' && (emailEdit != '' || Domains.length > 0)) {
      RestrictionErr.current = true;
    } else {
      RestrictionErr.current = false;
    }
  }

  const onEdit = async () => {
    console.log('linkDomain in edit ==', linkdomain, computeDomain())

    await validateVariant();
    //!hcdomainErr.current &&
    if (!minOnefieldErr.current && !domainErr.current && !parentEmptyErr.current && !uniqueVariantErr && !RestrictionErr.current && !restrictedToError.current) {
      let obj: editVariantObj = {
        name: variantName,
        currentName: (currentNameTmp != undefined ? currentNameTmp : ""),
        subType: type,
        status: (verificationStatus == 'Active' ? 'A' : 'I'),
        parentName: (props.values.parentEntity != null ? props.values.parentEntity.name : parentName),
        parentType: (props.values.parentEntity != null ? props.values.parentEntity.type : type),
        linkDomains: computeDomain(),
        auditDescription: auditDescp,
        sourceId: sourceID,
        weightAverageMolecular: WAMW,
        numberAvergeMolecular: NAMW,
        polydispersityIndex: PDI,
        averageDegreeOfPolymerisation: DP,
        prfCompositionMoleFraction: PRF,
        substituentComposition: substituentComposition,
        ds: DS,
        sourceCompositionMoleFraction: sourceComposition,
        topology: topology,
        microStructure: chemicalMicro
      }
      var arrEmailEdit = emailEdit.split(";");
      var jsonEmailEdit = JSON.parse(JSON.stringify(arrEmailEdit));

      let restrictObj: variantRestriction = {
        domains: Domains,
        mailIds: jsonEmailEdit
      }
      console.log('edit here ==', obj, restrictObj);
      dispatch(editVariantInfo(obj, restrictObj));

    } else {
      console.log('false tets ==', minOnefieldErr, domainErr, parentEmptyErr, uniqueVariantErr)

      setshowErrMsg(1);
      if(!RestrictionErr.current){
        dispatch(makeError('Please Enter Mandatory Fields.'))
      }
    }
  }

  const computeDomain = () => {
    const o = [];
    for (const property in linkdomain) {
      console.log(property, linkdomain[property as keyof typeof linkdomain]);
      if (property != 'ALL' && linkdomain[property as keyof typeof linkdomain] == true) {
        o.push(property)
      }
    }
    return o;
  }

  const linkDomainSelected = (linkDomainEdit: [], key: string) => {
    //console.log('linkdomain ==', linkdomain)
    //console.log('linkDomainEdit ==', linkDomainEdit)
    var k = false;
    //if (linkDomainEdit.length > 0) {
    //linkDomainEdit.forEach(function (key2: any, value2: any) {
    //  if (key2.key == key) {
    //    k = key2.value;
    //  }
    //});
    //}

    for (const property in linkdomain) {
     // console.log(property, linkdomain[property as keyof typeof linkdomain]);
      if (property == key) {
        k = linkdomain[property as keyof typeof linkdomain];
      }
    }

    return k;
  }

  const DomainList = [
    'BW',
    'HC',
    'PC',
    'PLM',
    'Regulatory',
    'Safety',
  ];

  const [Domains, setDomains] = React.useState(props.restriction.groupName != null ? props.restriction.groupName : []);
  const [ifDropDownSelected, setIfDropDownSelected] = React.useState(Domains.length > 0 ? true : false);
   
  const [emailEdit, setEmailEdit] = React.useState(props.restriction.email && props.restriction.email.length ? props.restriction.email.join(';') : '');
 
  const [ifEmailEntered, setIfEmailEntered] = React.useState(emailEdit ? true : false);
  const restrictedToError = React.useRef(false);

  console.log('Domains == here h', Domains, 'emailEdit ==',emailEdit)
  

  console.log('restriction data j ==', props.restriction)

  useEffect(() => {
    console.log('props changed test==', props.restriction);
    setDomains(props.restriction.groupName != null ? props.restriction.groupName : []);
    setEmailEdit(props.restriction.email && props.restriction.email.length ? props.restriction.email.join(';') : '')

    console.log('flags ==', ifDropDownSelected, ifEmailEntered)
  }, [props.restriction])

  useEffect(() => {

    setIfDropDownSelected(Domains.length > 0 ? true : false);
    setIfEmailEntered(emailEdit ? true : false);

  }, [Domains, emailEdit]);


  useEffect(() => {
    console.log(' restriction changed edited ', sourceID, emailEdit, Domains)
    if (sourceID == '' && (emailEdit != '' || Domains.length > 0)) {
      RestrictionErr.current = true;
    } else {
      RestrictionErr.current = false;
    }
  }, [sourceID])


  const handleRestrictedDomainChange = (event: any) => {
    setDomains(event.target.value);
    if (event.target.value.length > 0) {
      setIfDropDownSelected(true);
    } else {
      setIfDropDownSelected(false);
    }
    console.log('updated == Domains ==', Domains)

    // localStorage.setItem('restricteddomains', JSON.stringify(event.target.value));
  }

  const handleRestrictedEmailChange = (event: any) => {
    if (event.target.value === '') {
      setIfEmailEntered(false);
      restrictedToError.current = false;
    } else {
      setIfEmailEntered(true);
      restrictedToError.current = true;
    }
    var emails = event.target.value.split(";");
    let invalidEmails = [];
    for (let i = 0; i < emails.length; i++) {
      if (!validateMultipleEmails(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      }
    }
    if (invalidEmails.length > 0 && event.target.value !== '') {
      restrictedToError.current = true;
    } else {
      restrictedToError.current = false;
    }
  }

  const validateMultipleEmails = (e: string) => {
    var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(e);
  };

  return (

    <Form>{(Loading ? <div className={classes.menuItemMsg}><SearchInProgress /></div> : null)}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <div>
              <InputLabel>Variant Name</InputLabel>
              <Tooltip classes={{ tooltip: classes.tooltipStyle }}
                title={variantName}
              >
                <Input className={classes.inputBox} value={variantName} disabled={true} />
              </Tooltip>

              {uniqueVariantErr == true && showErrMsg == 1 ? <Typography variant="body1" className={classes.errText}>Variant Name should be unique</Typography>
                : null}
              <InputLabel>Type</InputLabel>
              <FormControl className={classes.formControl1}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={type}
                  onChange={handleType}
                  className={classes.selectEmpty1}
                >
                  {nameType.map((item: any) => (
                    <MenuItem key={item.key} value={item.key} >
                      {item.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel>Status</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  value={verificationStatus}
                  onChange={handleVerificationStatus}
                  className={classes.selectEmpty1}
                >
                  {verificationStatusList && verificationStatusList.map((verificationStatus) => (
                    <MenuItem key={verificationStatus} value={verificationStatus} >
                      {verificationStatus}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel>Source ID (Tradename,Catalogue ID,etc.)</InputLabel>
              <Input
                type="text"
                className={classes.inputBox}
                name="sourceID"
                margin="dense"
                onBlur={(item: any) => sourceIdChange(item)}
                defaultValue={sourceID}
              />

              <InputLabel>Weight Average Molecular Weight (Mw)</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => WAMWChange(item)} defaultValue={WAMW} name="WAMW" />

              <InputLabel>Number Average Molecular Weight (Mn)</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => NAMWChange(item)} defaultValue={NAMW} name="NAMW" />

              <InputLabel>Polydispersity Index (PDI)</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => PDIChange(item)} defaultValue={PDI} name="PDI" />

              <InputLabel>Average Degree of Polymerisation (DP)</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => DPChange(item)} defaultValue={DP} name="DP" />

              <InputLabel>Polymer Repeat Fragment (PRF) Composition (mole fraction(s))</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => PRFChange(item)} defaultValue={PRF} name="PRF" />

              <InputLabel>Substituent Composition</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => substituentCompositionChange(item)} defaultValue={substituentComposition} name="substituentComposition" />
              {/*<Input className={classes.inputBox} onBlur={(item: any) => substituentCompositionChange(item)} />*/}

              {/*Restriction part started*/}
              <InputLabel>Restricted Domains:</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={Domains}
                  onChange={handleRestrictedDomainChange}
                  className={[classes.selectEmpty, "set-width"].join(" ")}
                  disabled={ifEmailEntered}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {DomainList.map((domain) => (
                    <MenuItem key={domain} value={domain} >
                      {domain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel>Named list of users: </InputLabel>
              <TextField
                type="text"
                placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                disabled={ifDropDownSelected}
                className={[classes.textStrike, "set-width-email"].join(" ")}
                onChange={e => { setEmailEdit(e.target.value) }}
                onBlur={e => { handleRestrictedEmailChange(e) }}
                value={emailEdit}
              //label=" Id" variant="outlined"
              />
              {restrictedToError.current == true ? <p className={classes.para} >One or More Email Addresses are Invalid</p> :
                null}

              {RestrictionErr.current == true ? <Typography variant="body1" className={classes.errText}> SOURCE ID is required to restrict the Variant. Please Enter the SOURCE ID </Typography>
                : null}

              {minOnefieldErr.current == true ? <Typography variant="body1" className={classes.errText}>Need to fill at least one field</Typography>
                : null}

            </div>


          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} >
            <Typography variant="h6" className={classes.title}>Link to:</Typography>
            <LinkDataSearch />
            {parentEmptyErr.current == true ? <Typography variant="body1" className={classes.errText}> Need to select parent polymer</Typography> : null}


            <div>
              <Paper className={classes.paperCheckbox} >
                <Typography variant="body1" className={classes.labelCheckbox}>Link in domains:</Typography>
                <Table className={[classes.table, "table"].join(" ")}>
                  <TableBody className="domainClass">
                    {domainArr.map((item: any, k: any) =>
                      <TableRow key={item.key}>
                        <TableCell className={classes.tableCell} align="right"><Typography variant="caption" align="right">{item.key}</Typography></TableCell>
                        <TableCell className={classes.tableCell} ><Checkbox checked={(isEdit == true ? linkDomainSelected(arrLinkDomain, item.key) : item.key == 'HC' ? true : false)} onChange={(e, v) => domainChange({ a: item.key, selected: v })} /></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </div>
            {domainErr.current == true ? <Typography variant="body1" className={classes.errText}>  Need to select at least one domain</Typography>
              : null}
            {/*{hcdomainErr.current == true ? <Typography variant="body1" className={classes.errText}>  Need to select HC domain</Typography>*/}
            {/*  : null}*/}


            <div>
              <InputLabel className={classes.marginTop}>Source Composition</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => sourceCompositionChange(item)} defaultValue={sourceComposition} name="sourceComposition" />

              <InputLabel>Topology</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={topology}
                  onChange={topologyChange}
                  className={classes.selectEmpty}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {topologyArray.map((item) => (
                    <MenuItem key={item} value={item} >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel>Chemical Microstructures</InputLabel>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={chemicalMicro}
                  onChange={chemicalMicroChange}
                  className={classes.selectEmpty}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                >
                  {chemicalMicrostrsArray.map((item) => (
                    <MenuItem key={item} value={item} >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <InputLabel>Degree of Substitution (DS)</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => DSChange(item)} defaultValue={DS} name="DS" />

              <InputLabel>Audit Description</InputLabel>
              <Input className={classes.inputBox} onBlur={(item: any) => auditChange(item)} defaultValue={auditDescp} name="auditDescp" />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>{(isEdit == true ? <Button type="submit" color="primary" onClick={onEdit}>
          Save
        </Button> :
          "")}
        </Grid>
      </Grid>
    </Form>
  );
}

