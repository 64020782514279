import * as React from "react";
import { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store';
import EnhancedTextInputField from '../CommonComponents/EnhancedTextInputField';
import DomainEditField from '../DomainSelector/DomainEditField';
import { SearchInProgress } from '../SearchBox/SearchMessages';
import CircularProgress from '@mui/material/CircularProgress';
import { requestStructureSearch, loading, requestSearchStructure } from '../store/searchStructure/actions';

import { closeViewVariantDialog, addVariant, editVariantInfo } from '../store/variant/actions';
import { Paper, Input, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { Form, Formik, FormikActions } from 'formik';
import { makeStyles } from '@mui/styles';
import { makeError } from '../store/error/actions';

import InputLabel from '@mui/material/InputLabel';
import SearchField from '../SearchBox/SearchField';
import { Checkbox, Typography, Table, TableBody, TableRow, TableCell } from '@mui/material';

import { Select, MenuItem, Grid, FormControl, Chip } from '@mui/material';

import { addVariantObj, editVariantObj } from "../store/variant/types";
import { Suggestion } from '../store/search/types';
import { getParentPolymer } from '../store/polymer/actions';

import { TypeOfExpression } from 'typescript';
import { customSpacing } from "../theme/SIRtheme";


const useStyles = makeStyles((theme: any) => ({

  field: {
    marginBottom: "20px !important"
  },
  flex: {
    display: 'flex !important'
  },
  label: {
    color: 'grey !important',
    paddingRight: '20px !important',
    paddingTop: '8px !important',
    paddingBottom: '15px !important'
  },
  disableDD: {
    pointerEvents: "none"
  },
  inputBox: {
    marginBottom: "20px !important",
    width: "98% !important"
  },
  domainPanel: {
    padding: "8px !important"
  },
  title: {
    textAlign: "center"
  },
  labelCheckbox: {
    textAlign: "left"
  },
  paper: {
    padding: '2px !important',
    margin: '8px !important'
  },
  font: {
    fontSize: "1rem !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontWeight: 400 + ' !important',
    lineHeight: 1.5 +  '!important',
    letterSpacing: "0.00938em !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  width: {
    width: "100% !important"
  },
  removeMar: {
    margin: "0px !important"
  },
  marginTop: {
    marginTop: "15px !important",
  },

  //paper: {
  //  padding: "5px",
  //  height: "100%"
  //},
  msgColorRed: {
    color: 'red !important',
    fontSize: '14px !important'
  },
  msgColorGreen: {
    color: 'green !important',
    fontSize: '14px !important'
  },
  noaction: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: 'no-drop',
    pointerEvents: 'none'
  },
  formControl: {
    margin: customSpacing(1, false) + ' !important',
    width: "550px !important",
  },
  selectEmpty: {
    marginTop: "12px !important",
    padding: "0px !important",
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  chip: {
    margin: '2px !important',
    height: '26px !important'
  },
  selectEmpty1: {
    marginTop: "2px !important",
    width: '550px !important',
  },
  formControl1: {
    margin: customSpacing(1, false) + ' !important',
    width: "700px !important",
  },
  paperCheckbox: {
    padding: '2px !important',
    textAlign: 'center' as 'center',
    width: "max-content !important",
    margin: '8px !important'
  },
  table: {
    minWidth: "200px !important"
  },
  tableCell: {
    padding: "0px !important",
    borderBottom: "none !important"
  },
  errText: {
    color: "Red !important",
    padding: "5px !important"
  },
  menuItemMsg: {
    //position: "absolute",
    zIndex: 999 + ' !important',
    //width: 535,
    fontSize: "0.9rem !important",
    minHeight: "10px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    backgroundColor: "ghostwhite !important",
    paddingLeft: "15px !important",
    border: "1px solid lightgray !important",
    borderRadius: "4px !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  textStrike: {
    marginTop: "20px !important",
    //paddingLeft: "15px"
  }
}));

const ViewVariantDialog = (props: any) => {
  const classes = useStyles();
  //const variantDetails = useSelector((state: AppState) => (state.polymersData.ParentPolymerDetails != null ? state.polymersData.ParentPolymerDetails.variantDetails : null));
  const addVariantState = useSelector((state: AppState) => state.variant);
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(closeViewVariantDialog()),
    [dispatch]
  );
  const onSave = useCallback(
    (values, formik) => {
    },
    [dispatch]
  )    
  
  const open = (addVariantState && addVariantState.viewVariantShow == true) ? true : false; 
  const parentEntity = useSelector((state: AppState) => state.variant.parentEntity);
  const parentEntityObj = {
    "parentEntity": parentEntity
  }
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <MuiDialogTitle>
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item>
            View Variant
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MuiDialogTitle>

      <DialogContent>

        <ViewVariantFormik
          onSave={onSave}
          values={parentEntityObj}
          domain={props.domain}
          restriction={props.restriction} />
      </DialogContent>
    </Dialog>
  );
}

export default ViewVariantDialog;

interface FormProps {
  values: any;
  onSave: (values: Suggestion, formikActions: FormikActions<any>) => void;
  domain: any;
  restriction: any;
}

const ViewVariantFormik = (props: FormProps) => {
  
  const { values } = props;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={values}
        onSubmit={props.onSave}        
        render={(fp) => (
          <ViewVariantContent  {...fp} domain={props.domain} restriction={props.restriction } />
        )}
      />
    </div>
  );
};


const ViewVariantContent = (props: any) => {
  
  const variantNameT = useSelector((state: AppState) => state.variant.indexOfVariant);
  var variantDetails = useSelector((state: AppState) => (state.polymersData.ParentPolymerDetails != null ? state.polymersData.ParentPolymerDetails.variantDetails : null));
  const variantscreen = useSelector((state: AppState) => state.variant.variantStrcutureInfo);
  
  var pr = false;
  if (variantDetails == null && variantscreen != null) {
    pr = true;
    variantDetails = variantscreen;
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  var isEdit = false;

  const domainArr = props.domain;

  const initialDomain = () => {
    const s = {};
    const p = domainArr.map((item: any) => {
      Object.assign(s, { [item.key]: false }) 
    })
    return s;
  }

  var index = 0;
  var editedVariantArr = [];

  if (pr == false) {
    editedVariantArr = (variantDetails != null ? variantDetails.map((item: any, index1: any) => {

      if (variantNameT!=null && item.variantName == variantNameT) {
        index = index1
        return item;
      }
    }) : null);

  } else {
    editedVariantArr = (variantDetails != null ? variantDetails : null);

  }

  //Initializing variables
  var variantIdTmp = "";
  var variantNameTmp = "";
  var sourceIDTmp = "";
  var weightAverageMolecularWeight = "";
  var numberAvergeMolecularWeight = "";
  var polydispersityIndex = "";
  var averageDegreeOfPolymerisation = "";
  var prfCompositionMoleFraction = "";
  var substituentCompositionTmp = "";
  var sourceCompositionMoleFraction = "";
  var auditDescription = "";
  var ds = "";
  var topologyTmp = [];
  var chemicalMicroStructure = [];
  var variantStatus = 'Active';
  var subType = 'Synonym';
  var parentNameT = "";
  var linkDomainTmp = {};
  linkDomainTmp = initialDomain;

  var arrLinkDomain: any;

  //The below will be executed only during edit state of variant ..  
  if (editedVariantArr != null && variantNameT != null && (variantNameT != undefined) && editedVariantArr[index] != "") {
    //if (variantDetails != null && variantNameT != null) {
    
    isEdit = true;
    var editedVariant;

    if (pr == false) {
      editedVariant = editedVariantArr[index];

    } else {
      editedVariant = editedVariantArr;

    }

    variantIdTmp = editedVariant.variantId;
    variantNameTmp = editedVariant.variantName;
    sourceIDTmp = editedVariant.sourceID;
    weightAverageMolecularWeight = editedVariant.weightAverageMolecularWeight;
    numberAvergeMolecularWeight = editedVariant.numberAvergeMolecularWeight;
    polydispersityIndex = editedVariant.polydispersityIndex;
    averageDegreeOfPolymerisation = editedVariant.averageDegreeOfPolymerisation;
    prfCompositionMoleFraction = editedVariant.prfCompositionMoleFraction;
    substituentCompositionTmp = editedVariant.substituentComposition;
    sourceCompositionMoleFraction = editedVariant.sourceCompositionMoleFraction;
    auditDescription = editedVariant.auditDescription;
    ds = editedVariant.ds;
    topologyTmp = editedVariant.topology;
    chemicalMicroStructure = editedVariant.chemicalMicroStructure;
    variantStatus = (editedVariant.variantStatus == 'A' ? "Active" : "Inactive");
    subType = editedVariant.nameType;
    parentNameT = editedVariant.parentName;
    var linkDomainTmp2 = editedVariant.linkDoimain;

    var linkDomainArr = linkDomainTmp2.toString().split(",");
    //linkDomainTmp = finalDomain(linkDomainArr);

    const s = {};
    const p = domainArr.map((item: any) => {
      if (linkDomainArr.includes([item.key].toString())) {
        Object.assign(s, { [item.key]: true })
      } else {
        Object.assign(s, { [item.key]: false })
      }
    })

    linkDomainTmp = s;
    arrLinkDomain = Object.entries(linkDomainTmp).map(([key, value]) => ({ key, value }));
    //arrlinkDomain.map(item => alert(JSON.stringify(item.key) + " -- in linkDomainTmp -- value -- " + JSON.stringify(item.value)));

  }
  //Till here

  var [variantId, setvariantId] = React.useState(variantIdTmp);
  var [currentNameTmp, setcurrentNameTmp] = React.useState(variantNameTmp);
  var [variantName, setvariantName] = React.useState(variantNameTmp);
  var [sourceID, setsourceID] = React.useState(sourceIDTmp);
  var [WAMW, setWAMW] = React.useState(weightAverageMolecularWeight);
  var [NAMW, setNAMW] = React.useState(numberAvergeMolecularWeight);
  var [PDI, setPID] = React.useState(polydispersityIndex);
  var [DP, setDP] = React.useState(averageDegreeOfPolymerisation);
  var [PRF, setPRF] = React.useState(prfCompositionMoleFraction);
  var [substituentComposition, setsubstituentComposition] = React.useState(substituentCompositionTmp);
  var [sourceComposition, setsourceComposition] = React.useState(sourceCompositionMoleFraction);
  var [auditDescp, setauditDescp] = React.useState(auditDescription);
  var [DS, setDS] = React.useState(ds);
  var [topology, setTopology] = React.useState(topologyTmp);
  var [chemicalMicro, setchemicalMicro] = React.useState(chemicalMicroStructure);
  var [verificationStatus, setVerificationStatus] = React.useState(variantStatus);
  var [type, setType] = React.useState(subType);
  var [parentName, setParentName] = React.useState(parentNameT);
  var [linkdomain, setlinkdomain] = React.useState(linkDomainTmp);
  const [Domains, setDomains] = React.useState(props.restriction.groupName != null ? props.restriction.groupName : []);
  const [emailEdit, setEmailEdit] = React.useState(props.restriction.email && props.restriction.email.length ? props.restriction.email.join(';') : '');


  useEffect(() => {
    console.log('props changed test==', props.restriction);
    setDomains(props.restriction.groupName != null ? props.restriction.groupName : []);
    setEmailEdit(props.restriction.email && props.restriction.email.length ? props.restriction.email.join(';') : '')
  }, [props.restriction])

  const detailData = useSelector((state: AppState) => state); 
  var { values } = props;

  if (values.parentEntity.name != parentName) {
    values.parentEntity.name = parentName;
  }
  
  useEffect(() => {
    console.log('values in view ==', values, values.parentEntity);
    
    if (values.parentEntity && values.parentEntity.name) {
      console.log('parentName ==', values.parentEntity.name);
      let variantName = "";
      if (sourceID != '') {
        let v = values.parentEntity.name + ' ' + sourceID;
        v = v.replace(/  +/g, ' ');//removing extra spaces
        v = v.trim();
        setvariantName(v);
      } else if (PRF != '' || WAMW != '' || NAMW != '' || PDI != '' || DP != '' || topology.length || chemicalMicro.length) {
        let subName = (PDI != '' && WAMW != '' && NAMW != '') ? WAMW + ' ' + NAMW : WAMW + ' ' + NAMW + ' ' + PDI;
        let vname = values.parentEntity.name + ' ' + (PRF != '' ? PRF + ' ' : '') + subName + ' ' + (DP != '' ? DP + ' ' : '') + ' ' + (topology.length ? topology[0] : '') + ' ' + (chemicalMicro.length ? chemicalMicro[0] : '')
        let v = vname.replace(/  +/g, ' ');
        v=v.trim();
        setvariantName(v)
      }
      else {
        setvariantName('')
        console.log('else of ALL fields')
      }
    }
  }, [props, sourceID, PRF, WAMW, NAMW, PDI, DP, topology, chemicalMicro]);


  var Loading = false;
  const [uniqueVariantErr, setuniqueVariantErr] = React.useState(false);
  console.log('variantDetails =', variantDetails);
  
  const LinkDataSearch = () => (
    <Paper className={classes.paper} >
      <InputLabel>Parent</InputLabel>
      {/*<SearchField name="parentEntity" type={values.newEntity.type} values={values} />*/}
      {/*<SearchField isVariant='Yes' type={substanceType} name="parentEntity" values={p} />*/}
      {/*<SearchField name="parentEntity" isVariant='Yes' type='variant' values={values} />*/}

      <Input name="parentEntity" className={classes.inputBox} defaultValue={values.parentEntity.name} disabled />
    </Paper>
  )

  const verificationStatusList = [
    'Active',
    'Inactive'
  ]

  //commenting below unused event fucntions
  /*const variantNameChange = (event: any) => {

    setvariantName(event.target.value)
  };

  const sourceIdChange = (event: any) => {
    setsourceID(event.target.value)
  };

  const PDIChange = (event: any) => {
    setPID(event.target.value)
  };

  const NAMWChange = (event: any) => {
    setNAMW(event.target.value)
  };

  const WAMWChange = (event: any) => {
    setWAMW(event.target.value)
  };

  const DPChange = (event: any) => {
    setDP(event.target.value)
  };

  const PRFChange = (event: any) => {
    setPRF(event.target.value)
  };

  const substituentCompositionChange = (event: any) => {
    setsubstituentComposition(event.target.value)
  };

  const DSChange = (event: any) => {
    setDS(event.target.value)
  };

  const auditChange = (event: any) => {
    setauditDescp(event.target.value)
  };*/

  const chemicalMicrostrsArray = useSelector((state: AppState) => state.variant.chemicalMicrostructuresList);
  const topologyArray = useSelector((state: AppState) => state.variant.topologyList);

  /*const sourceCompositionChange = (event: any) => {
    setsourceComposition(event.target.value)
  }

  const topologyChange = (event: any) => {
    setTopology(event.target.value)
  };

  const chemicalMicroChange = (event: any) => {
    setchemicalMicro(event.target.value)
  };

  const handleVerificationStatus = (event: any) => {
    setVerificationStatus(event.target.value);
  }

  const handleType = (event: any) => {
    setType(event.target.value);
  }*/

  const lookup = useSelector((state: AppState) => state.lookup);
  const nameType = lookup.nameType;
  
    //const [linkdomain, setlinkdomain] = React.useState(initialDomain);
    const [domainErr, setdomainErr] = React.useState(false);
    const [hcdomainErr, sethcdomainErr] = React.useState(false);
    const [minOnefieldErr, setminOnefieldErr] = React.useState(false);
    const [parentEmptyErr, setparentEmptyErr] = React.useState(false);

  //unused function hence commenting
    /*const domainChange = (item: any) => {
      //  console.log('checked ==', item)
      setlinkdomain({
        ...linkdomain,
        [item.a]: item.selected,
      });
    }
    const validateVariant = () => {
      let d = computeDomain();
      
      if (d.length == 0) {
        setdomainErr(true);
        sethcdomainErr(false);
      } else if (d.includes('HC')) {
        setdomainErr(false);
        sethcdomainErr(false);
      } else {
        sethcdomainErr(true);
        setdomainErr(false);
      }


      if (sourceID == '' && PRF == '' && WAMW == '' && NAMW == '' && PDI == '' && DP == '' && DS == '' && topology.length == 0 && chemicalMicro.length == 0) {
        setminOnefieldErr(true);
      } else {
        setminOnefieldErr(false);
      }
      console.log('parent ==', props, props.values.parentEntity)
      if (props.values.parentEntity!=null && (props.values.parentEntity.name == '' || props.values.parentEntity.name == undefined)) {
        setparentEmptyErr(true);
      } else {
        setparentEmptyErr(false);
      }

    }

    const computeDomain = () => {
      const o = [];
      for (const property in linkdomain) {
        console.log(property, linkdomain[property as keyof typeof linkdomain]);
        if (linkdomain[property as keyof typeof linkdomain] == true) {
          o.push(property)
        }
      }
      return o;
    }*/

    const linkDomainSelected = (linkDomainEdit: [], key: string) => {

      let k = false;
      for (const property in linkdomain) {
        console.log(property, linkdomain[property as keyof typeof linkdomain]);
        if (property === key) {
          k = linkdomain[property as keyof typeof linkdomain];
        }
      }

      if (key != 'ALL') {
        return k;
      } else {
        let cnt = 0;
        const totalProperties = Object.keys(linkdomain).length-1;
        for (const property in linkdomain) {
          if (property != 'ALL' && linkdomain[property as keyof typeof linkdomain] == true) { 
              cnt++;
          }
        }
        return cnt === totalProperties;
      }
    }    

    return (
      
      <Form>{(Loading ? <div className={classes.menuItemMsg}><SearchInProgress /></div> : null)}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper} >
              <div>
                <InputLabel>Variant Name</InputLabel>
                <Input className={classes.inputBox} value={variantName} disabled/>
                
                <InputLabel>Type</InputLabel>
                <FormControl className={classes.formControl1}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    value={type}
                    //onChange={handleType}
                    className={classes.selectEmpty1}
                    disabled={true}
                  >
                    {nameType.map((item: any) => (
                      <MenuItem key={item.key} value={item.key}>
                        {item.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputLabel>Status</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    value={verificationStatus}
                    //onChange={handleVerificationStatus}
                    className={classes.selectEmpty1}
                    disabled={true}
                  >
                    {verificationStatusList && verificationStatusList.map((verificationStatus) => (
                      <MenuItem key={verificationStatus} value={verificationStatus} >
                        {verificationStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <InputLabel>Source ID (Tradename,Catalogue ID,etc.)</InputLabel>
                <Input
                  type="text"
                  className={classes.inputBox}
                  name="sourceID"
                  margin="dense"
                  //onBlur={(item: any) => sourceIdChange(item)}
                  defaultValue={sourceID}
                  disabled
                />

                <InputLabel>Weight Average Molecular Weight (Mw)</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => WAMWChange(item)}
                  defaultValue={WAMW}
                  disabled />

                <InputLabel>Number Average Molecular Weight (Mn)</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => NAMWChange(item)}
                  defaultValue={NAMW}
                  disabled />

                <InputLabel>Polydispersity Index (PDI)</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => PDIChange(item)}
                  defaultValue={PDI}
                  disabled />

                <InputLabel>Average Degree of Polymerisation (DP)</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => DPChange(item)}
                  defaultValue={DP}
                  disabled />

                <InputLabel>Polymer Repeat Fragment (PRF) Composition (mole fraction(s))</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => PRFChange(item)}
                  defaultValue={PRF}
                  disabled />

                <InputLabel>Substituent Composition</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => substituentCompositionChange(item)}
                  defaultValue={substituentComposition}
                  disabled />
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => substituentCompositionChange(item)}
                  disabled />    

                {/*Restriction part started*/}
                <InputLabel>Restricted Domains:</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={Domains}
                    className={[classes.selectEmpty, "set-width"].join(" ")}
                    disabled={true}
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((value: any) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                     
                  </Select>
                </FormControl>
                <InputLabel>Named list of users: </InputLabel>
                <TextField
                  type="text"
                  placeholder="Enter the email address seperated by semicolon ';' e.g: username@unilever.com"
                  disabled={true}
                  className={[classes.textStrike, "set-width-email"].join(" ")}
                  value={emailEdit}
                //label=" Id" variant="outlined"
                />


              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} >
              <Typography variant="h6" className={classes.title}>Link to:</Typography>
              <LinkDataSearch />
              
              <div>
                <Paper className={classes.paperCheckbox} >
                  <Typography variant="body1" className={classes.labelCheckbox}>Link in domains:</Typography>
                  <Table className={[classes.table, "table"].join(" ")}>
                    <TableBody className="domainClass">
                      {domainArr.map((item:any, k:any) =>
                        <TableRow key={item.key}>
                          <TableCell className={classes.tableCell} align="right"><Typography variant="caption" align="right">{item.key}</Typography></TableCell>
                          <TableCell className={classes.tableCell} ><Checkbox defaultChecked={(isEdit == true ? linkDomainSelected(arrLinkDomain, item.key) : item.key == 'HC' ? true : false)}
                            //onChange={(e, v) => domainChange({ a: item.key, selected: v })}
                            disabled /></TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
              
              <div>
                <InputLabel className={classes.marginTop}>Source Composition</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => sourceCompositionChange(item)}
                  defaultValue={sourceComposition}
                  disabled />

                <InputLabel>Topology</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={topology}
                    //onChange={topologyChange}
                    className={classes.selectEmpty}
                    disabled={true}
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((value: any) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {topologyArray.map((item) => (
                      <MenuItem key={item} value={item} >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <InputLabel>Chemical Microstructures</InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={chemicalMicro}
                    //onChange={chemicalMicroChange}
                    className={classes.selectEmpty}
                    disabled={true}
                    renderValue={(selected: any) => (
                      <div className={classes.chips}>
                        {selected.map((value: any) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {chemicalMicrostrsArray.map((item) => (
                      <MenuItem key={item} value={item} >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <InputLabel>Degree of Substitution (DS)</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => DSChange(item)}
                  defaultValue={DS}
                  disabled />

                <InputLabel>Audit Description</InputLabel>
                <Input
                  className={classes.inputBox}
                  //onBlur={(item: any) => auditChange(item)}
                  defaultValue={auditDescp}
                  disabled />
              </div>
            </Paper>
          </Grid>          
        </Grid>
      </Form>
    );
  }

